import { createSlice } from '@reduxjs/toolkit';

const chatSlice = createSlice({
  name: 'chat',
  initialState: {
    unreadCount: 0,
    client: null,
    chatToken:null,
  },
  reducers: {
    setUnreadCount: (state, action) => {
      state.unreadCount = action.payload;
    },
    setClient: (state, action) => {
      state.client = action.payload;
    },
    setChatToken: (state, action) => {
      state.chatToken = action.payload;
    },
  },
});

export const { setUnreadCount } = chatSlice.actions;
export const { setClient } = chatSlice.actions;
export const {setChatToken} = chatSlice.actions;

export const selectUnreadCount = (state) => state.chat.unreadCount;
export const selectClient = (state) => state.chat.client;
export const selectChatToken = (state) => state.chat.chatToken;

export default chatSlice.reducer;