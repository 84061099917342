import React, { useState, useContext, useEffect } from 'react';
import { Tooltip } from 'antd';
import { UserAddOutlined, UsergroupAddOutlined, MessageOutlined, CommentOutlined } from '@ant-design/icons';
import axios from 'axios';
import CustomCreateChannel from './CustomCreateChannel';
import { Button, colors } from '../components';
import { SceytContext } from '../sceytContext';
import { AdminSceytContext } from '../AdminSceytContext';

export default function CreateChannelButton({ userRole, userId, partnerAdminId,superAdminView=false }) {
  const [showCreateChannel, setShowCreateChannel] = useState(false);
  const [channelType, setChannelType] = useState('');
  const [theme, setTheme] = useState('');
   const ChatContext = superAdminView ? AdminSceytContext : SceytContext;
  const sceytContext = useContext(ChatContext);

  useEffect(() => {
    if (sceytContext && sceytContext.theme) {
      setTheme(sceytContext.theme);
    }
  }, [sceytContext]);

  const handleCreateChannel = (type) => {
    setChannelType(type);
    setShowCreateChannel(true);
  };

  const startChatWithPsm = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/scyetChat/startChatForPsm`,
        { id: userId },
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      if (response.data) {
        console.log('Chat with PSM started:', response.data);
      }
    } catch (error) {
      console.error('Error starting chat with PSM:', error);
    }
  };

  const commonButtonStyle = {
    marginLeft: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "transparent",
    boxShadow: "none",
    border: "none",
    borderRadius: "50%"
  };

  const commonIconStyle = {
    fontSize: "23px",
    color: colors.primary
  };

  if (userRole === 'partner' || userRole === "partnerAdmin") {
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <div>
          <Tooltip title="Chat with PSM" placement="bottomLeft">
            <Button
              onClick={startChatWithPsm}
              style={commonButtonStyle}
            >
              <UserAddOutlined style={commonIconStyle} />
            </Button>
          </Tooltip>
        </div>
        <div>
          <Tooltip title="New Group" placement="bottom">
            <Button 
              onClick={() => handleCreateChannel('group')} 
              style={commonButtonStyle}
              className={`messenger_create_channel_item ${theme}`}
            >
              <UsergroupAddOutlined style={commonIconStyle} />
            </Button>
          </Tooltip>
        </div>
        <div>
          <Tooltip title="New Chat" placement="bottom">
            <Button 
              onClick={() => handleCreateChannel('direct')} 
              style={commonButtonStyle}
              className={`messenger_create_channel_item ${theme}`}
            >
              <CommentOutlined style={commonIconStyle} />
            </Button>
          </Tooltip>
        </div>
        {showCreateChannel && (
          <CustomCreateChannel
            type={channelType}
            role={userRole}
            userId={userId}
            partnerAdminId={partnerAdminId}
            onClose={() => setShowCreateChannel(false)}
          />
        )}
      </div>
    );
  }

  // For zyvkaUser role
  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <div>
        <Tooltip title="Send Bulk Message" placement="bottomLeft">
          <Button
            onClick={() => handleCreateChannel('bulkMessage')}
            style={commonButtonStyle}
          >
            <MessageOutlined style={commonIconStyle} />
          </Button>
        </Tooltip>
      </div>
      <div>
        <Tooltip title="New Group" placement="bottom">
          <Button 
            onClick={() => handleCreateChannel('group')} 
            style={commonButtonStyle}
            className={`messenger_create_channel_item ${theme}`}
          >
            <UsergroupAddOutlined style={commonIconStyle} />
          </Button>
        </Tooltip>
      </div>
      <div>
        <Tooltip title="New Chat" placement="bottom">
          <Button 
            onClick={() => handleCreateChannel('direct')} 
            style={commonButtonStyle}
            className={`messenger_create_channel_item ${theme}`}
          >
            <CommentOutlined style={commonIconStyle} />
          </Button>
        </Tooltip>
      </div>
      {showCreateChannel && (
        <CustomCreateChannel
          type={channelType}
          role={userRole}
          userId={userId}
          partnerAdminId={partnerAdminId}
          onClose={() => setShowCreateChannel(false)}
        />
      )}
    </div>
  );
}