import React from 'react';
import ChatComponent from './ChatComponent';

const ChatPages = React.memo(({ token, client }) => {
  return (
    <div
      style={{
        marginLeft: '-25px',
        marginTop: '0px',
        marginBottom: '-20px',
        height: 'calc(100vh - 65px)',
        width: 'calc(100% + 63px)',
        overflow: 'hidden'
      }}
    >
      <ChatComponent token={token} client={client} />
    </div>
  );
});

export default ChatPages;
