import React from 'react';
import { Table, Tag, Typography, Card, Space, Modal, Tooltip } from 'antd';
import { CheckCircleFilled, CloseCircleFilled, ExclamationCircleFilled } from '@ant-design/icons';
import RichTextEditor from '../Small-Components/RichTextEditor';

const { Title, Text } = Typography;

const EvaluationDisplay = ({ 
  obj = {}, 
  isReportModalVisible = false, 
  onReportModalClose 
}) => {
  const styles = {
    container: {
      maxWidth: 1200,
      margin: '0 auto',
      padding: '24px'
    },
    section: {
      marginBottom: 24,
      borderRadius: 8,
      boxShadow: '0 2px 8px rgba(0,0,0,0.09)'
    },
    title: {
      marginBottom: 16,
      color: '#1890ff',
      fontSize: '18px'
    },
    skillsContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: 8,
      marginBottom: 16
    }
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case 'Yes': return <CheckCircleFilled style={{ color: '#52c41a' }} />;
      case 'No': return <CloseCircleFilled style={{ color: '#ff4d4f' }} />;
      default: return <ExclamationCircleFilled style={{ color: '#faad14' }} />;
    }
  };

  const getColumns = (idx) => [
    {
      title: idx === 2 ? 'Requirement' : 'Skill',
      dataIndex: idx === 2 ? 'requirement' : 'skill',
      key: 'skill',
      width: '40%',
      render: (text) => <Text strong>{text}</Text>
    },
    {
      title: 'Status',
      dataIndex: idx === 2 ? 'meets_requirement' : 'has_skill',
      key: 'status',
      width: '20%',
      align: 'center',
      render: (status) => (
        <Space>
          <Text strong>{status}</Text>
        </Space>
      )
    },
    {
      title: 'Justification',
      dataIndex: 'justification',
      key: 'justification',
      width: '40%',
      render: (text) => <Text type="secondary">{text}</Text>
    }
  ];

  const getScoreForSection = (section) => {
    const scoreMap = {
      'Must-Have Skills': obj?.zyvkaAiEvaluation?.must_have_skills_score,
      'Good-to-Have Skills': obj?.zyvkaAiEvaluation?.good_to_have_skill_score,
      'Other Requirements': obj?.zyvkaAiEvaluation?.other_requirements_score
    };
    return scoreMap[section] || 'N/A';
  };

  const generateReportContent = () => {
    if (!obj?.zyvkaAiEvaluation && !obj?.reject_reason) return '<div>No data available</div>';

    const {
      decision,
      reasoning,
      match_score,
      total_match_score,
      candidate_skills = {},
      probability_of_selection,
      must_have_skills = [],
      good_to_have_skills = [],
      other_requirements = []
    } = obj.zyvkaAiEvaluation;

    return `
      <div style="max-width: 800px; margin: 0 auto; padding: 24px;">
        <h2 style="color: #1890ff;">Candidate Evaluation Report</h2>
        
        <h3>📊 Evaluation Summary</h3>
        <p><strong>Decision:</strong> ${decision || 'N/A'}</p>
        <p><strong>Reasoning:</strong> ${reasoning || 'N/A'}</p>
        <p><strong>AI Score:</strong> ${total_match_score || match_score || 'N/A'}</p>
        <p><strong>Selection Probability:</strong> ${probability_of_selection || 'N/A'}</p>

        <h3>💻 Skills Assessment</h3>
        <h4>Technical Skills</h4>
        <div>
          ${(candidate_skills?.technical_skills || []).map(skill => `
            <span style="
              display: inline-block;
              background: #f0f0f0;
              padding: 4px 12px;
              margin: 4px;
              border-radius: 20px;
            ">${skill}</span>
          `).join('')}
        </div>

        <h4>Soft Skills</h4>
        <div>
          ${(candidate_skills?.soft_skills || []).map(skill => `
            <span style="
              display: inline-block;
              background: #f0f0f0;
              padding: 4px 12px;
              margin: 4px;
              border-radius: 20px;
            ">${skill}</span>
          `).join('')}
        </div>

        <h3>🔑 Must-Have Skills</h3>
        <table border="1" style="width: 100%; border-collapse: collapse; margin-bottom: 24px;">
          <thead>
            <tr>
              <th style="padding: 8px; background: #f0f0f0;">Skill</th>
              <th style="padding: 8px; background: #f0f0f0;">Status</th>
              <th style="padding: 8px; background: #f0f0f0;">Justification</th>
            </tr>
          </thead>
          <tbody>
            ${must_have_skills.map(item => `
              <tr>
                <td style="padding: 8px;">${item.skill || 'N/A'}</td>
                <td style="padding: 8px; text-align: center;">
                  <span style="color: ${item.has_skill === 'Yes' ? '#52c41a' : item.has_skill === 'No' ? '#ff4d4f' : '#faad14'}">
                    ${item.has_skill || 'N/A'}
                  </span>
                </td>
                <td style="padding: 8px;">${item.justification || 'N/A'}</td>
              </tr>
            `).join('')}
          </tbody>
        </table>

        <h3>🎯 Good-to-Have Skills</h3>
        <table border="1" style="width: 100%; border-collapse: collapse; margin-bottom: 24px;">
          <thead>
            <tr>
              <th style="padding: 8px; background: #f0f0f0;">Skill</th>
              <th style="padding: 8px; background: #f0f0f0;">Status</th>
              <th style="padding: 8px; background: #f0f0f0;">Justification</th>
            </tr>
          </thead>
          <tbody>
            ${good_to_have_skills.map(item => `
              <tr>
                <td style="padding: 8px;">${item.skill || 'N/A'}</td>
                <td style="padding: 8px; text-align: center;">
                  <span style="color: ${item.has_skill === 'Yes' ? '#52c41a' : item.has_skill === 'No' ? '#ff4d4f' : '#faad14'}">
                    ${item.has_skill || 'N/A'}
                  </span>
                </td>
                <td style="padding: 8px;">${item.justification || 'N/A'}</td>
              </tr>
            `).join('')}
          </tbody>
        </table>

        <h3>📋 Other Requirements</h3>
        <table border="1" style="width: 100%; border-collapse: collapse; margin-bottom: 24px;">
          <thead>
            <tr>
              <th style="padding: 8px; background: #f0f0f0;">Requirement</th>
              <th style="padding: 8px; background: #f0f0f0;">Status</th>
              <th style="padding: 8px; background: #f0f0f0;">Justification</th>
            </tr>
          </thead>
          <tbody>
            ${other_requirements.map(item => `
              <tr>
                <td style="padding: 8px;">${item.requirement || 'N/A'}</td>
                <td style="padding: 8px; text-align: center;">
                  <span style="color: ${item.meets_requirement === 'Yes' ? '#52c41a' : item.meets_requirement === 'No' ? '#ff4d4f' : '#faad14'}">
                    ${item.meets_requirement || 'N/A'}
                  </span>
                </td>
                <td style="padding: 8px;">${item.justification || 'N/A'}</td>
              </tr>
            `).join('')}
          </tbody>
        </table>
      </div>
    `;
  };

  if (!obj?.zyvkaAiEvaluation && !obj.reject_reason ) {
    return (
      <Card style={styles.section}>
        <Title level={4} style={{ color: '#8c8c8c' }}>
          No evaluation data available
        </Title>
      </Card>
    );
  }
  if (obj.reject_reason && !obj.zyvkaAiEvaluation && !obj.current_status.includes("Rejected") ) {
    return (
      <Card style={styles.section}>
        <Title level={4} style={{ color: '#8c8c8c' }}>
          No evaluation data available
        </Title>
      </Card>
    );
  }
  if (obj.reject_reason && !obj.zyvkaAiEvaluation && obj.current_status.includes("Rejected") ) {
    return (
      <Card 
        style={{ 
          ...styles.section,
          border: '1px solid #ff4d4f',
          background: '#fff1f0'
        }}
      >
        <Title level={4} style={{ color: '#ff4d4f' }}>
          <CloseCircleFilled /> Rejection Details
        </Title>
        <div style={{ marginBottom: 8, fontSize: '15px' }}>
        <div>
        <Text strong>Rejection Round: </Text>
        {obj.current_status}
        </div>
          <Text strong>Primary Reason: </Text>
          {obj.reject_reason === "Others" ? obj.rejectReasonComment : obj.reject_reason}
        </div>
        {obj.reject_reason !== "Others" && obj.rejectReasonComment && (
          <div style={{ fontSize: '15px' }}>
            <Text strong>Additional Feedback: </Text>
            {obj.rejectReasonComment}
          </div>
        )}
      </Card>
    );
  }
  if (obj.reject_reason && obj.zyvkaAiEvaluation && obj.zyvkaAiEvaluation.decision === 'Shortlisted') {
    return (
      <>
      <div style={styles.container}>
      <Card style={styles.section}>
        <Title level={4} style={styles.title}>
          📊 Candidate's Evaluation
        </Title>
        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
          <div>
            <Tag 
              color={obj?.zyvkaAiEvaluation.decision === 'Rejected' ? 'red' : 'green'} 
              style={{
                fontSize: '14px',
                padding: '6px 12px',
                borderRadius: '20px'
              }}
            >
              {obj?.zyvkaAiEvaluation.decision}
            </Tag>
            <Text style={{ marginLeft: 12, fontSize: '15px' }}>
              {obj?.zyvkaAiEvaluation.reasoning}
            </Text>
          </div>
          <Space size="large">
            <div>
              <Text strong>AI Score: </Text>
              <Text style={{ fontSize: '16px' }}>
                {obj?.zyvkaAiEvaluation.total_match_score || obj?.zyvkaAiEvaluation.match_score}
              </Text>
            </div>
            <div>
              <Text strong>Selection Probability: </Text>
              <Text style={{ fontSize: '16px' }}>
                {obj?.zyvkaAiEvaluation.probability_of_selection}
              </Text>
            </div>
          </Space>
        </Space>
      </Card>

      <Card style={styles.section}>
        <Title level={4} style={styles.title}>📈 Match Score Breakdown</Title>
        <Space size="large" wrap>
          {[
            ['Must-Have Skills', obj?.zyvkaAiEvaluation.must_have_skills_score],
            ['Good-to-Have Skills', obj?.zyvkaAiEvaluation.good_to_have_skill_score],
            ['Other Requirements', obj?.zyvkaAiEvaluation.other_requirements_score]
          ].map(([key, value]) => (
            <div key={key} style={{ textAlign: 'center' }}>
              <Text strong style={{ display: 'block', marginBottom: 4 }}>{key}</Text>
              <Text style={{ 
                fontSize: '18px', 
                fontWeight: 600,
                color: value > 50 ? '#52c41a' : '#faad14' 
              }}>
                {value || 'N/A'}
              </Text>
            </div>
          ))}
        </Space>
      </Card>

      <Card style={styles.section}>
        <Title level={4} style={styles.title}>💻 Technical Skills</Title>
        <div style={styles.skillsContainer}>
          {(obj?.zyvkaAiEvaluation.candidate_skills?.technical_skills || []).map((skill, index) => (
            <Tag 
              key={index} 
              color="geekblue"
              style={{ padding: '6px 12px', borderRadius: '20px' }}
            >
              {skill}
            </Tag>
          ))}
        </div>

        <Title level={4} style={{ ...styles.title, marginTop: 24 }}>🤝 Soft Skills</Title>
        <div style={styles.skillsContainer}>
          {(obj?.zyvkaAiEvaluation.candidate_skills?.soft_skills || []).map((skill, index) => (
            <Tag 
              key={index} 
              color="green"
              style={{ padding: '6px 12px', borderRadius: '20px' }}
            >
              {skill}
            </Tag>
          ))}
        </div>
      </Card>

      <Card style={styles.section}>
        <Title level={4} style={styles.title}>
          🔑 Must-Have Skills (Score: {getScoreForSection('Must-Have Skills')})
        </Title>
        <Table
          columns={getColumns(0)}
          dataSource={obj?.zyvkaAiEvaluation.must_have_skills || []}
          pagination={false}
          rowKey={(record, index) => `must-have-${index}`}
          scroll={{ x: true }}
          bordered
          size="middle"
        />
      </Card>

      <Card style={styles.section}>
        <Title level={4} style={styles.title}>
          🎯 Good-to-Have Skills (Score: {getScoreForSection('Good-to-Have Skills')})
        </Title>
        <Table
          columns={getColumns(1)}
          dataSource={obj?.zyvkaAiEvaluation.good_to_have_skills || []}
          pagination={false}
          rowKey={(record, index) => `good-to-have-${index}`}
          scroll={{ x: true }}
          bordered
          size="middle"
        />
      </Card>

      <Card style={styles.section}>
        <Title level={4} style={styles.title}>
          📋 Other Requirements (Score: {getScoreForSection('Other Requirements')})
        </Title>
        <Table
          columns={getColumns(2)}
          dataSource={obj?.zyvkaAiEvaluation.other_requirements || []}
          pagination={false}
          rowKey={(record, index) => `other-req-${index}`}
          scroll={{ x: true }}
          bordered
          size="middle"
        />
      </Card>
      <Modal
        title="Exportable Evaluation Report"
        visible={isReportModalVisible}
        onCancel={onReportModalClose}
        width={800}
        footer={null}
      >
        <RichTextEditor
          initialValue={generateReportContent()}
          getValue={() => {}}
          config={{ 
            readonly: true,
            toolbar: false,
            statusbar: false
          }}
        />
      </Modal>
    </div>
      <Card 
        style={{ 
          ...styles.section,
          border: '1px solid #ff4d4f',
          background: '#fff1f0'
        }}
      >
        <Title level={4} style={{ color: '#ff4d4f' }}>
          <CloseCircleFilled /> Rejection Details
        </Title>
        <div style={{ marginBottom: 8, fontSize: '15px' }}>
        <div>
        <Text strong>Rejection Round: </Text>
        {obj.current_status}
        </div>
          <Text strong>Primary Reason: </Text>
          {obj.reject_reason === "Others" ? obj.rejectReasonComment : obj.reject_reason}
        </div>
        {obj.reject_reason !== "Others" && obj.rejectReasonComment && (
          <div style={{ fontSize: '15px' }}>
            <Text strong>Additional Feedback: </Text>
            {obj.rejectReasonComment}
          </div>
        )}
      </Card>
      </>
    );
  }
  if (obj.reject_reason && obj.zyvkaAiEvaluation && obj.current_status.includes("Rejected") && obj.current_status !== "Zyvka Rejected") {
    return (
      <>
      <div style={styles.container}>
      <Card style={styles.section}>
        <Title level={4} style={styles.title}>
          📊 Candidate's Evaluation
        </Title>
        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
          <div>
            <Tag 
              color={obj?.zyvkaAiEvaluation.decision === 'Rejected' ? 'red' : 'green'} 
              style={{
                fontSize: '14px',
                padding: '6px 12px',
                borderRadius: '20px'
              }}
            >
              {obj?.zyvkaAiEvaluation.decision}
            </Tag>
            <Text style={{ marginLeft: 12, fontSize: '15px' }}>
              {obj?.zyvkaAiEvaluation.reasoning}
            </Text>
          </div>
          <Space size="large">
            <div>
              <Text strong>AI Score: </Text>
              <Text style={{ fontSize: '16px' }}>
                {obj?.zyvkaAiEvaluation.total_match_score || obj?.zyvkaAiEvaluation.match_score}
              </Text>
            </div>
            <div>
              <Text strong>Selection Probability: </Text>
              <Text style={{ fontSize: '16px' }}>
                {obj?.zyvkaAiEvaluation.probability_of_selection}
              </Text>
            </div>
          </Space>
        </Space>
      </Card>

      <Card style={styles.section}>
        <Title level={4} style={styles.title}>📈 Match Score Breakdown</Title>
        <Space size="large" wrap>
          {[
            ['Must-Have Skills', obj?.zyvkaAiEvaluation.must_have_skills_score],
            ['Good-to-Have Skills', obj?.zyvkaAiEvaluation.good_to_have_skill_score],
            ['Other Requirements', obj?.zyvkaAiEvaluation.other_requirements_score]
          ].map(([key, value]) => (
            <div key={key} style={{ textAlign: 'center' }}>
              <Text strong style={{ display: 'block', marginBottom: 4 }}>{key}</Text>
              <Text style={{ 
                fontSize: '18px', 
                fontWeight: 600,
                color: value > 50 ? '#52c41a' : '#faad14' 
              }}>
                {value || 'N/A'}
              </Text>
            </div>
          ))}
        </Space>
      </Card>

      <Card style={styles.section}>
        <Title level={4} style={styles.title}>💻 Technical Skills</Title>
        <div style={styles.skillsContainer}>
          {(obj?.zyvkaAiEvaluation.candidate_skills?.technical_skills || []).map((skill, index) => (
            <Tag 
              key={index} 
              color="geekblue"
              style={{ padding: '6px 12px', borderRadius: '20px' }}
            >
              {skill}
            </Tag>
          ))}
        </div>

        <Title level={4} style={{ ...styles.title, marginTop: 24 }}>🤝 Soft Skills</Title>
        <div style={styles.skillsContainer}>
          {(obj?.zyvkaAiEvaluation.candidate_skills?.soft_skills || []).map((skill, index) => (
            <Tag 
              key={index} 
              color="green"
              style={{ padding: '6px 12px', borderRadius: '20px' }}
            >
              {skill}
            </Tag>
          ))}
        </div>
      </Card>

      <Card style={styles.section}>
        <Title level={4} style={styles.title}>
          🔑 Must-Have Skills (Score: {getScoreForSection('Must-Have Skills')})
        </Title>
        <Table
          columns={getColumns(0)}
          dataSource={obj?.zyvkaAiEvaluation.must_have_skills || []}
          pagination={false}
          rowKey={(record, index) => `must-have-${index}`}
          scroll={{ x: true }}
          bordered
          size="middle"
        />
      </Card>

      <Card style={styles.section}>
        <Title level={4} style={styles.title}>
          🎯 Good-to-Have Skills (Score: {getScoreForSection('Good-to-Have Skills')})
        </Title>
        <Table
          columns={getColumns(1)}
          dataSource={obj?.zyvkaAiEvaluation.good_to_have_skills || []}
          pagination={false}
          rowKey={(record, index) => `good-to-have-${index}`}
          scroll={{ x: true }}
          bordered
          size="middle"
        />
      </Card>

      <Card style={styles.section}>
        <Title level={4} style={styles.title}>
          📋 Other Requirements (Score: {getScoreForSection('Other Requirements')})
        </Title>
        <Table
          columns={getColumns(2)}
          dataSource={obj?.zyvkaAiEvaluation.other_requirements || []}
          pagination={false}
          rowKey={(record, index) => `other-req-${index}`}
          scroll={{ x: true }}
          bordered
          size="middle"
        />
      </Card>
      <Modal
        title="Exportable Evaluation Report"
        visible={isReportModalVisible}
        onCancel={onReportModalClose}
        width={800}
        footer={null}
      >
        <RichTextEditor
          initialValue={generateReportContent()}
          getValue={() => {}}
          config={{ 
            readonly: true,
            toolbar: false,
            statusbar: false
          }}
        />
      </Modal>
    </div>
      <Card 
        style={{ 
          ...styles.section,
          border: '1px solid #ff4d4f',
          background: '#fff1f0'
        }}
      >
        <Title level={4} style={{ color: '#ff4d4f' }}>
          <CloseCircleFilled /> Rejection Details
        </Title>
        <div style={{ marginBottom: 8, fontSize: '15px' }}>
        <div>
        <Text strong>Rejection Round: </Text>
        {obj.current_status}
        </div>
          <Text strong>Primary Reason: </Text>
          {obj.reject_reason === "Others" ? obj.rejectReasonComment : obj.reject_reason}
        </div>
        {obj.reject_reason !== "Others" && obj.rejectReasonComment && (
          <div style={{ fontSize: '15px' }}>
            <Text strong>Additional Feedback: </Text>
            {obj.rejectReasonComment}
          </div>
        )}
      </Card>
      </>
    );
  }

  const { zyvkaAiEvaluation } = obj;

  return (
    <div style={styles.container}>
      <Card style={styles.section}>
        <Title level={4} style={styles.title}>
          📊 Candidate's Evaluation
        </Title>
        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
          <div>
            <Tag 
              color={zyvkaAiEvaluation.decision === 'Rejected' ? 'red' : 'green'} 
              style={{
                fontSize: '14px',
                padding: '6px 12px',
                borderRadius: '20px'
              }}
            >
              {zyvkaAiEvaluation.decision}
            </Tag>
            <Text style={{ marginLeft: 12, fontSize: '15px' }}>
              {zyvkaAiEvaluation.reasoning}
            </Text>
          </div>
          <Space size="large">
            <div>
              <Text strong>AI Score: </Text>
              <Text style={{ fontSize: '16px' }}>
                {zyvkaAiEvaluation.total_match_score || zyvkaAiEvaluation.match_score}
              </Text>
            </div>
            <div>
              <Text strong>Selection Probability: </Text>
              <Text style={{ fontSize: '16px' }}>
                {zyvkaAiEvaluation.probability_of_selection}
              </Text>
            </div>
          </Space>
        </Space>
      </Card>

      <Card style={styles.section}>
        <Title level={4} style={styles.title}>📈 Match Score Breakdown</Title>
        <Space size="large" wrap>
          {[
            ['Must-Have Skills', zyvkaAiEvaluation.must_have_skills_score],
            ['Good-to-Have Skills', zyvkaAiEvaluation.good_to_have_skill_score],
            ['Other Requirements', zyvkaAiEvaluation.other_requirements_score]
          ].map(([key, value]) => (
            <div key={key} style={{ textAlign: 'center' }}>
              <Text strong style={{ display: 'block', marginBottom: 4 }}>{key}</Text>
              <Text style={{ 
                fontSize: '18px', 
                fontWeight: 600,
                color: value > 50 ? '#52c41a' : '#faad14' 
              }}>
                {value || 'N/A'}
              </Text>
            </div>
          ))}
        </Space>
      </Card>

      <Card style={styles.section}>
        <Title level={4} style={styles.title}>💻 Technical Skills</Title>
        <div style={styles.skillsContainer}>
          {(zyvkaAiEvaluation.candidate_skills?.technical_skills || []).map((skill, index) => (
            <Tag 
              key={index} 
              color="geekblue"
              style={{ padding: '6px 12px', borderRadius: '20px' }}
            >
              {skill}
            </Tag>
          ))}
        </div>

        <Title level={4} style={{ ...styles.title, marginTop: 24 }}>🤝 Soft Skills</Title>
        <div style={styles.skillsContainer}>
          {(zyvkaAiEvaluation.candidate_skills?.soft_skills || []).map((skill, index) => (
            <Tag 
              key={index} 
              color="green"
              style={{ padding: '6px 12px', borderRadius: '20px' }}
            >
              {skill}
            </Tag>
          ))}
        </div>
      </Card>

      <Card style={styles.section}>
        <Title level={4} style={styles.title}>
          🔑 Must-Have Skills (Score: {getScoreForSection('Must-Have Skills')})
        </Title>
        <Table
          columns={getColumns(0)}
          dataSource={zyvkaAiEvaluation.must_have_skills || []}
          pagination={false}
          rowKey={(record, index) => `must-have-${index}`}
          scroll={{ x: true }}
          bordered
          size="middle"
        />
      </Card>

      <Card style={styles.section}>
        <Title level={4} style={styles.title}>
          🎯 Good-to-Have Skills (Score: {getScoreForSection('Good-to-Have Skills')})
        </Title>
        <Table
          columns={getColumns(1)}
          dataSource={zyvkaAiEvaluation.good_to_have_skills || []}
          pagination={false}
          rowKey={(record, index) => `good-to-have-${index}`}
          scroll={{ x: true }}
          bordered
          size="middle"
        />
      </Card>

      <Card style={styles.section}>
        <Title level={4} style={styles.title}>
          📋 Other Requirements (Score: {getScoreForSection('Other Requirements')})
        </Title>
        <Table
          columns={getColumns(2)}
          dataSource={zyvkaAiEvaluation.other_requirements || []}
          pagination={false}
          rowKey={(record, index) => `other-req-${index}`}
          scroll={{ x: true }}
          bordered
          size="middle"
        />
      </Card>
      <Modal
        title="Exportable Evaluation Report"
        visible={isReportModalVisible}
        onCancel={onReportModalClose}
        width={800}
        footer={null}
      >
        <RichTextEditor
          initialValue={generateReportContent()}
          getValue={() => {}}
          config={{ 
            readonly: true,
            toolbar: false,
            statusbar: false
          }}
        />
      </Modal>
    </div>
  );
};

export default EvaluationDisplay;