import React, { useEffect, useState, useContext } from 'react';
import { SceytContext } from "../sceytContext";
import { Avatar } from "sceyt-chat-react-uikit";
import { 
  CheckOutlined, 
  PaperClipOutlined, 
  BellOutlined, 
  PushpinOutlined, 
  EllipsisOutlined 
} from '@ant-design/icons';
import { AdminSceytContext } from '../AdminSceytContext';

// Custom muted bell icon: a bell with a diagonal strike-through.
// The icon uses the same blue (#1890ff) as the unread message bubble
// and includes a small left margin.
const MutedBellIcon = () => (
  <div style={{ position: 'relative', display: 'inline-block', width: 16, height: 16, marginLeft: 4 }}>
    <BellOutlined style={{ fontSize: '16px', color: '#1890ff' }} />
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: 0,
        right: 0,
        height: 1,
        backgroundColor: '#1890ff',
        transform: 'rotate(-45deg)',
        transformOrigin: 'center'
      }}
    />
  </div>
);

function ChannelCustomList({ 
    channels, 
    searchedChannels, 
    loadMoreChannels, 
    setSelectedChannel, 
    selectedChannel, 
    searchValue, 
    activeChannelIsChanged,
    userRole,
    currentUserId,
    superAdminView=false,
}) {

     const ChatContext = superAdminView ? AdminSceytContext : SceytContext;
    const { client:sceytClient, setActiveChannel } = useContext(ChatContext);
  
    const role = (userRole === "partner" || userRole === "partnerAdmin") ? "partner" : "zyvkaUser";
    const [channelListWithGroups, setChannelListWithGroups] = useState([]);
    const [user, setUser] = useState(null);
    const [hoveredChannelId, setHoveredChannelId] = useState(null);
    console.log("here is the list of the channles",channels);


  
    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
            
            maxHeight: '100%'
        },
        channelItem: {
            display: 'flex',
            padding: '10px',
            alignItems: 'center',
            cursor: 'pointer',
            borderRadius:"5px",
            marginBottom:"5px",
            // borderBottom: '1px solid #f0f0f0'
        },
        channelItemActive: {
            backgroundColor: 'rgba(104, 85, 246, 0.20)'
        },
        contentContainer: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            marginLeft: '12px'
        },
        topRow: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '4px'
        },
        bottomRow: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        channelName: {
            fontSize: '14px',
            fontWeight: 500,
            margin: 0,
            padding: 0,
            color: '#333'
        },
        badgeContainer: {
            display: 'flex',
            alignItems: 'center',
            gap: '4px'
        },
        unreadIndicatorDot: {
            width: '8px',
            height: '8px',
            borderRadius: '50%',
            backgroundColor: '#fadb14',
            marginRight: '4px'
        },
        unreadCount: {
            backgroundColor: '#1890ff',
            color: 'white',
            padding: '2px 6px',
            borderRadius: '10px',
            fontSize: '12px'
        },
        mentionBadge: {
            backgroundColor: '#ff4d4f',
            color: 'white',
            padding: '2px 6px',
            borderRadius: '10px',
            fontSize: '12px'
        },
        messageContainer: {
            display: 'flex',
            alignItems: 'center',
            flex: 1,
            overflow: 'hidden'
        },
        lastMessageText: {
            fontSize: '13px',
            color: '#666',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        },
        timestamp: {
            fontSize: '12px',
            color: '#888',
            marginLeft: '8px',
            whiteSpace: 'nowrap'
        },
        tickContainer: {
            position: 'relative',
            display: 'inline-block',
            width: '16px',
            height: '16px',
            marginRight: '4px'
        },
        tickIconFirst: {
            position: 'absolute',
            left: 0,
            top: 0,
            fontSize: '12px',
            color: '#888'
        },
        tickIconSecond: {
            position: 'absolute',
            left: '4px',
            top: 0,
            fontSize: '12px',
            color: '#888'
        },
        tickIconSingle: {
            fontSize: '12px',
            color: '#888',
            marginRight: '4px'
        },
        groupName: {
            padding: '10px',
            fontSize: '14px',
            fontWeight: 'bold',
            // backgroundColor: '#fafafa',
            // borderBottom: '1px solid #eee',
            color: '#555'
        },
        attachmentIcon: {
            fontSize: '12px',
            color: '#999',
            marginRight: '4px'
        },
        typingIndicator: {
            fontSize: '13px',
            fontStyle: 'italic',
            color: '#1890ff',
            animation: 'blink 1s infinite'
        },
        // Pin icon style: outline icon in the same blue as the unread bubble.
        pinIcon: {
            fontSize: '14px',
            color: '#1890ff',
            marginLeft: '4px'
        }
    };

    // Keyframes for typing indicator blinking effect.
    const typingKeyframes = `
      @keyframes blink {
          0% { opacity: 0.2; }
          50% { opacity: 1; }
          100% { opacity: 0.2; }
      }
    `;

    const handleScroll = (e) => {
        if (e.target.scrollTop >= (e.target.scrollHeight - e.target.offsetHeight) - 200) {
            loadMoreChannels();
        }
    };

    const makeUserName = (user) => {
        if (!user) return '';
        return user.firstName ? `${user.firstName} ${user.lastName}` : user.id;
    };

    // Formats a timestamp into a relative time string.
    const formatTimeAgo = (timestamp) => {
        if (!timestamp) return '';
        const time = new Date(timestamp);
        const now = new Date();
        const diffMinutes = Math.floor((now - time) / (1000 * 60));
        
        if (diffMinutes < 1) return 'Just now';
        if (diffMinutes < 60) return `${diffMinutes}m ago`;
        const diffHours = Math.floor(diffMinutes / 60);
        if (diffHours < 24) return `${diffHours}h ago`;
        return time.toLocaleDateString();
    };

    // Renders tick icons only for outgoing (current user's) messages.
    const getMessageStatus = (message) => {
        if (!message) return null;
        if (message.incoming || message.user.id !== currentUserId) return null;
        
        const isDisplayed = message.markerTotals?.some(marker => marker.name === 'displayed');
        if (isDisplayed) {
            return (
                <span style={styles.tickContainer}>
                    <CheckOutlined style={styles.tickIconFirst} />
                    <CheckOutlined style={styles.tickIconSecond} />
                </span>
            );
        }
        return (
            <span style={styles.tickContainer}>
                <CheckOutlined style={styles.tickIconSingle} />
            </span>
        );
    };

    // Truncate the message after 25 characters.
    const truncateMessage = (message) => {
        if (!message) return '';
        return message.length > 25 ? message.substring(0, 25) + '...' : message;
    };

    // Creates channel groups (direct & group) with pinned channels on top.
    const handleCreateChannelsGroupList = () => {
        const channelsGroupList = [];
        const channelGroupMap = { group: [], direct: [] };
        const groups = [];
        const directs = [];

        channels.forEach(channel => {
            const channelGroupType = channel.type === 'direct' ? 'direct' : 'group';
            if (channelGroupType === 'direct') {
                directs.push(channel);
            } else {
                groups.push(channel);
            }
        });

        channelGroupMap.group = groups;
        channelGroupMap.direct = directs;

        Object.keys(channelGroupMap).forEach(key => {
            const groupName = key === 'direct' ? 'DIRECT MESSAGES' : 'GROUPS';
            channelGroupMap[key].sort((a, b) => {
                const aPinned = a.pinnedAt && new Date(a.pinnedAt).getTime() > 0;
                const bPinned = b.pinnedAt && new Date(b.pinnedAt).getTime() > 0;
                if (aPinned !== bPinned) {
                    return aPinned ? -1 : 1;
                }
                return (b.lastMessage ? new Date(b.lastMessage.createdAt) : new Date(b.createdAt)) -
                       (a.lastMessage ? new Date(a.lastMessage.createdAt) : new Date(a.createdAt));
            });
            channelsGroupList.push({ 
                groupName, 
                channelList: channelGroupMap[key]
            });
        });

        setChannelListWithGroups(channelsGroupList);
    };

    // Handle search results separately.
    useEffect(() => {
        if (searchedChannels && searchValue) {
            const channelsGroupList = [];
            const directChannels = [];
            const groupChannels = [];

            for (let key in searchedChannels) {
                if (searchedChannels[key].length) {
                    searchedChannels[key].forEach(channel => {
                        if (channel.type === 'direct') {
                            directChannels.push(channel);
                        } else {
                            groupChannels.push(channel);
                        }
                    });
                }
            }

            if (groupChannels.length > 0) {
                groupChannels.sort((a, b) => {
                    const aPinned = a.pinnedAt && new Date(a.pinnedAt).getTime() > 0;
                    const bPinned = b.pinnedAt && new Date(b.pinnedAt).getTime() > 0;
                    if (aPinned !== bPinned) {
                        return aPinned ? -1 : 1;
                    }
                    return (b.lastMessage ? new Date(b.lastMessage.createdAt) : new Date(b.createdAt)) -
                           (a.lastMessage ? new Date(a.lastMessage.createdAt) : new Date(a.createdAt));
                });
                channelsGroupList.push({
                    groupName: 'GROUPS',
                    channelList: groupChannels
                });
            }

            if (directChannels.length > 0) {
                directChannels.sort((a, b) => {
                    const aPinned = a.pinnedAt && new Date(a.pinnedAt).getTime() > 0;
                    const bPinned = b.pinnedAt && new Date(b.pinnedAt).getTime() > 0;
                    if (aPinned !== bPinned) {
                        return aPinned ? -1 : 1;
                    }
                    return (b.lastMessage ? new Date(b.lastMessage.createdAt) : new Date(b.createdAt)) -
                           (a.lastMessage ? new Date(a.lastMessage.createdAt) : new Date(a.createdAt));
                });
                channelsGroupList.push({
                    groupName: 'DIRECT MESSAGES',
                    channelList: directChannels
                });
            }

            setChannelListWithGroups(channelsGroupList);
        }
    }, [searchedChannels, searchValue]);

    // Re-create the channel group list whenever channels or searchValue changes.
    useEffect(() => {
        if (channels) {
            handleCreateChannelsGroupList();
        }
    }, [channels, searchValue]);

    const handleChangeActiveChannel = (channel) => {
        if (!channel) return;
        setActiveChannel(channel);
        if (setSelectedChannel) setSelectedChannel(channel);
        if (activeChannelIsChanged) {
            setTimeout(() => activeChannelIsChanged(channel), 200);
        }
    };

    const isZyvkaUser = (member) => {
        try {
            const metadata = typeof member.metadata === 'string' ? JSON.parse(member.metadata) : member.metadata;
            return !metadata?.role || (metadata.role !== 'partnerAdmin' && metadata.role !== 'partner');
        } catch (e) {
            return true;
        }
    };

    const getChannelDisplayInfo = (channel, directChannelUser) => {
        const isDirectChannel = channel.type === 'direct';
        if (role === "partner") {
            const zyvkaUsers = channel.members.filter(isZyvkaUser);
            if (zyvkaUsers.length > 0) {
                return {
                    name: isDirectChannel && directChannelUser ? makeUserName(directChannelUser) : `${channel.subject}`,
                    avatarUrl: "https://i.ibb.co/vZs5YmC/logozyvk.png",
                    avatarName: (directChannelUser || {}).id || ''
                };
            }
            return {
                name: isDirectChannel && directChannelUser ? makeUserName(directChannelUser) : `${channel.subject}`,
                avatarUrl: isDirectChannel ? (directChannelUser || {}).avatarUrl : null,
                avatarName: (directChannelUser || {}).id || ''
            };
        }
        return {
            name: isDirectChannel && directChannelUser ? makeUserName(directChannelUser) : `${channel.subject}`,
            avatarUrl: isDirectChannel ? (directChannelUser || {}).avatarUrl : null,
            avatarName: (directChannelUser || {}).id || ''
        };
    };

    // Render an attachment icon if attachments exist in the last message.
    const getAttachmentIndicator = (message) => {
        if (message && message.attachments && message.attachments.length > 0) {
            return <PaperClipOutlined style={styles.attachmentIcon} />;
        }
        return null;
    };

    // Render a typing indicator if the channel has typing users (other than current user)
    const getTypingIndicator = (channel) => {
        if (channel.typing && Array.isArray(channel.typing)) {
            const otherTyping = channel.typing.filter(user => user.id !== currentUserId);
            if (otherTyping.length > 0) {
                return <span style={styles.typingIndicator}><EllipsisOutlined /> typing...</span>;
            }
        }
        return null;
    };

    useEffect(() => {
        if (sceytClient) {
            setUser(sceytClient.user);
        }
    }, [sceytClient]);

    if (!channels || channels.length === 0) {
        return null;
    }

    return (
        <div className={`channels_custom_list ${sceytClient.theme}`} onScroll={handleScroll} style={styles.container}>
            {/* Inject keyframes for typing animation */}
            <style>{typingKeyframes}</style>
            {channelListWithGroups.map(channelGroup => (
                <div key={channelGroup.groupName}>
                    {channelGroup.channelList && channelGroup.channelList.length > 0 && (
                        <div style={styles.groupName}>{channelGroup.groupName}</div>
                    )}
                    {channelGroup.channelList && channelGroup.channelList.map(channel => {
                        const isDirectChannel = channel.type === 'direct';
                        const directChannelUser = isDirectChannel && channel.members.find(member => member.id !== currentUserId);
                        const displayInfo = getChannelDisplayInfo(channel, directChannelUser);
                        const isActive = (selectedChannel && selectedChannel.id === channel.id) || channel.id === sceytClient.activeChannelId;
                        // Determine pinned status from channel data.
                        const isPinned = channel.pinnedAt && new Date(channel.pinnedAt).getTime() > 0;
                        
                        return (
                            <div
                                key={channel.id}
                                onClick={() => handleChangeActiveChannel(channel)}
                                onMouseEnter={() => setHoveredChannelId(channel.id)}
                                onMouseLeave={() => setHoveredChannelId(null)}
                                style={{
                                    ...styles.channelItem,
                                    ...(isActive ? styles.channelItemActive : {}),
                                    ...(hoveredChannelId === channel.id ? { backgroundColor: 'rgba(104, 85, 246, 0.17)' } : {})
                                }}
                            >
                                <Avatar
                                    name={displayInfo.name}
                                    size={48}
                                    image={displayInfo.avatarUrl}
                                />
                                <div style={styles.contentContainer}>
                                    <div style={styles.topRow}>
                                        <span style={styles.channelName}>
                                            {displayInfo.name}
                                            {/* If the channel is pinned, show the outline pin icon */}
                                            {isPinned && <PushpinOutlined style={styles.pinIcon} />}
                                            {/* If the channel is muted, show the custom muted bell icon */}
                                            {channel.muted && <MutedBellIcon />}
                                        </span>
                                        <div style={styles.badgeContainer}>
                                            {channel.unread && <span style={styles.unreadIndicatorDot}></span>}
                                            {channel.newMentionCount > 0 && (
                                                <span style={styles.mentionBadge}>
                                                    @{channel.newMentionCount}
                                                </span>
                                            )}
                                            {channel.newMessageCount > 0 && (
                                                <span style={styles.unreadCount}>
                                                    {channel.newMessageCount < 100 ? channel.newMessageCount : '+99'}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div style={styles.bottomRow}>
                                        <div style={styles.messageContainer}>
                                            {channel.lastMessage && getMessageStatus(channel.lastMessage)}
                                            {channel.lastMessage && getAttachmentIndicator(channel.lastMessage)}
                                            {channel.lastMessage ? (
                                                <span style={styles.lastMessageText}>
                                                    {truncateMessage(channel.lastMessage.body)}
                                                </span>
                                            ) : (
                                                <span style={styles.lastMessageText}></span>
                                            )}
                                            {getTypingIndicator(channel)}
                                        </div>
                                        <div style={{
                                            ...styles.timestamp,
                                            ...(channel.newMessageCount > 0 ? { color: '#1890ff', fontWeight: 'bold' } : {})
                                        }}>
                                            {channel.lastMessage ? formatTimeAgo(channel.lastMessage.createdAt) : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            ))}
        </div>
    );
}

export default ChannelCustomList;
