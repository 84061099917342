import {
    Space, Table, Button, Card, Input,
    Divider,
    Col,
    Row,
    Tag,
    Typography,
    Popconfirm,
    notification,
    Modal,
    Upload, 
    Tooltip, 
    message,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    approveJobBySuperAdmin,
    fetchAllPartnerAdmins,
    getAllUnApprovedJobs,
    getAllZyvkaUsers,
    updateClosedStatusJob,
    updateJob,
} from "../api/ZyvkaMember";
import { ZyvkaMemberState } from "../features/ZyvkaMemberSlice";
import { UserState, selectUser } from "../features/userSlice";
import parse from "html-react-parser";
import { DownloadOutlined, Spin } from "@ant-design/icons";
import axios from "axios";
import RichTextEditor from "../Small-Components/RichTextEditor";
import Currency, { ctcMap, CurrencySymbols } from "../Data/Currency";
import { fetchAllSkills } from "../api/auth";
import { Dropdown, Form, Modal as SemanticModal, TextArea } from 'semantic-ui-react'
import JoditEditor from "jodit-react";
import QuestionsList from "./QuestionsList";
const { Title } = Typography;


const DescriptionItem = ({ title, content }) => (
    <div className="site-description-item-profile-wrapper">
        <p className="site-description-item-profile-p-label">{title}:</p>
        {content}
    </div>
);

const SuperAdminPositionApproval = () => {
    const dispatch = useDispatch();
    const [columndata, setcolumnData] = useState([]);
    const [flag, setFlag] = useState(false);
    const [updateOpen, setUpdateOpen] = useState(false)
    const [pdfloading, setPdfLoading] = useState(false);
    const [currentJob, setCurrentJob] = useState([]);
    const [currentIndex, setCurrentIndex] = useState();
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [skills, setSkillOption] = useState(false)
    const [AllPartnerAdmins, setAllPartnerAdmins] = useState(false);
    const [AllZyvkaUsers, setAllZyvkaUsers] = useState(false)
    const userState = useSelector(UserState);
    const revenueOptions = [{ id: 1, value: "Percentage", text: "Percentage" }, { id: 2, value: "Fixed Price", text: "Fixed Price" }];
    const modelOptions = [{ id: 1, value: "Replacement", text: "Replacement" }, { id: 2, value: "Refund", text: "Refund" }]
    const allowedIds = ['clu8ai1y60085oihmcaksslfs']
    const spinStyle = {
        animation: 'spin 1s linear infinite'
    };
    const [showScriptModal, setShowScriptModal] = useState(false);
    const [uploadingVideo, setUploadingVideo] = useState(false);

    const [experienceKeywordOptions, setExperienceKeywordOptions] = useState([]);
    const [positionalKeywordOptions, setPositionalKeywordOptions] = useState([]);
    const [otherSearchKeywordOptions, setOtherSearchKeywordOptions] = useState([]);
    const [jobBoardKeywordOptions, setJobBoardKeywordOptions] = useState([]);
    const [targetCompaniesOptions, setTargetCompaniesOptions] = useState([]);
    const [alternateJobTitlesOptions, setAlternateJobTitlesOptions] = useState([]);
     const [questions, setQuestions] = useState([]);

  

    const fetchSkills = async () => {
        try {
            await fetchAllSkills(dispatch, setSkillOption);
        } catch (error) {
            console.log(error);
        }
    };
    const GetAllPartnerAdmins = async () => {
        try {
            const res = await fetchAllPartnerAdmins(dispatch, setAllPartnerAdmins);
        } catch (error) {
            console.log(error);
        }
    };

    const fetchZyvkaUsers = async () => {
        await getAllZyvkaUsers(dispatch, setAllZyvkaUsers);
    };
    const [partnerOptions, setPartnerOptions] = useState([])
    useEffect(() => {
        GetAllPartnerAdmins();
        fetchZyvkaUsers();
        fetchSkills();
    }, []);

    const keyframesStyle = `
        @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
        }
    `;
    const user = useSelector(selectUser);
    useEffect(() => {
        const fetchPendingJobs = async () => {
            const res = await getAllUnApprovedJobs();
            console.log(res, "data")
            setcolumnData(res);
            setCurrentJob(res?.[0]);
        };
        fetchPendingJobs();
    }, [dispatch, flag]);
    const [searchValue, setSearchValue] = useState("");
    console.log(userState, "asd")
    const sendNotificationToIds = async (data) => {
        return await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/notification/sendIndividualNotification`, data, {
            withCredentials: true,
        });
    };
    const formatScript = (script) => {
        if (!script) return "";
        // If the script already starts with an HTML tag, assume it's formatted.
        if (script.trim().startsWith("<")) {
          return script;
        }
        // Otherwise, convert plain text to HTML by wrapping in a <p> and replacing newlines.
        return `<p>${script.replace(/\n/g, "<br>")}</p>`;
      };
      // Add this function for video upload
      const handleVideoUpload = async (file) => {

        if (file.size > 15 * 1024 * 1024) {
              message.error("Video size must be less than 15 MB");
              return;
            }
        const formData = new FormData();
        formData.append('file', file);
        setUploadingVideo(true);
        
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVER_URL}/api/video/uploadVideoJd/${currentJob.id}`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    withCredentials: true,
                }
            );
            
            setCurrentJob(prev => ({
                ...prev,
                isJdVideoUploaded: true,
                jdVideo: response.data.fileUrl
            }));
            message.success('Video JD uploaded successfully');
        } catch (error) {
            message.error('Failed to upload Video JD');
        } finally {
            setUploadingVideo(false);
        }
    };
    const onSave = async (e) => {
        e.preventDefault();
      
        // Optional: Validate that the video JD is uploaded
        if (!currentJob?.isJdVideoUploaded) {
          message.error("Please upload Video JD before approving.");
          return;
        }
      
        setConfirmLoading(true);
      
        // Check for new team member and partner additions to send notifications
        const data = columndata.find((obj) => obj.id === currentIndex);
        const zid = data?.zyvka_team_members;
        const newzid = currentJob?.zyvka_team_members;
        const additions_zid =
          Array.isArray(newzid) && newzid.length > 0
            ? newzid.filter((item) => !Array.isArray(zid) || !zid.includes(item))
            : [];
        const pid = data?.assigned_partners;
        const newpid = currentJob?.assigned_partners;
        const additions_pid =
          Array.isArray(newpid) && newpid.length > 0
            ? newpid.filter((item) => !Array.isArray(pid) || !pid.includes(item))
            : [];
        const combinedAdditions = [...additions_zid, ...additions_pid];
      
        if (combinedAdditions?.length > 0) {
          await sendNotificationToIds({
            title: `New Position Approved: ${currentJob?.company_name}, ${currentJob?.job_title}`,
            ids: combinedAdditions,
            body: `Dear Team, we are delighted to inform you that you have been assigned a new position: ${currentJob?.company_name}, ${currentJob?.job_title}. Please view the details from Positions > Pending Assigned Positions to accept or decline the allocation.`,
          });
        }
      
        // First, try to update the job with all new fields
        try {
          await updateJob(
            {
              jobID: currentJob?.id,
              skills_req: currentJob?.skills_req,
              min_CTC: currentJob?.min_CTC,
              max_CTC: currentJob?.max_CTC,
              min_experience_req: currentJob?.min_experience_req,
              max_experience_req: currentJob?.max_experience_req,
              currencyType: currentJob?.currencyType,
              zyvka_team_members: currentJob?.zyvka_team_members,
              assigned_partners: currentJob?.assigned_partners,
              pending_assignment: currentJob?.pending_assignment,
              numberOfPositions: parseInt(currentJob?.number_of_positions),
              internalTeamWorking: currentJob?.internalTeamWorking,
              openToInternalTeamOnly: currentJob?.openToInternalTeamOnly,
              isZyvkaAiEnable: currentJob?.isZyvkaAiEnable,
              hotJob: currentJob?.hotJob,
              job_title: currentJob?.job_title,
              invoicing_term: currentJob?.positionRevenue?.[0]?.InvoicingTerms || currentJob?.client?.revenue?.[0]?.InvoicingTerms,
              payment_term: currentJob?.positionRevenue?.[0]?.PaymentTerms || currentJob?.client?.revenue?.[0]?.PaymentTerms,
              replacement_model: currentJob?.positionRevenue?.[0]?.ReplacementModel || currentJob?.client?.revenue?.[0]?.ReplacementModel,
              replacement_timeline: currentJob?.positionRevenue?.[0]?.ReplacementTimeline || currentJob?.client?.revenue?.[0]?.ReplacementTimeline,
              revenue_model: currentJob?.positionRevenue?.[0]?.RevenueMode || currentJob?.client?.revenue?.[0]?.RevenueMode,
              charge_value: currentJob?.positionRevenue?.[0]?.Value || currentJob?.client?.revenue?.[0]?.Value,
              currency: currentJob?.positionRevenue?.[0]?.Currency || currentJob?.client?.revenue?.[0]?.Currency,

              // NEW FIELDS
              prefered_industry: currentJob?.prefered_industry,
              positional_keyword: currentJob?.positional_keyword,
              experience_keyword: currentJob?.experience_keyword,
              other_search_keyword: currentJob?.other_search_keyword,
              job_board_keyword: currentJob?.job_board_keyword,
              target_companies: currentJob?.target_companies,
              alternate_job_titles: currentJob?.alternate_job_titles,
              recruiter_script: currentJob?.recruiter_script,
              job_description: currentJob?.job_description,
            },
            dispatch
          );
        } catch (error) {
          message.error("Job update failed. Approval not sent.");
          setConfirmLoading(false);
          return;
        }
      
        // If job update succeeds, then approve the job
        try {
          await approveJobBySuperAdmin({ jobId: currentIndex });
        } catch (error) {
          message.error("Job approval failed.");
          setConfirmLoading(false);
          return;
        }
      
        setConfirmLoading(false);
        setFlag((flag) => !flag);
        setUpdateOpen(false);
      };
      
 
    const columns = [
        {
            title: "Job Title",
            key: "id",
            filteredValue: [searchValue],
            onFilter: (value, record) => {
                return (
                    String(record?.company_name)
                        ?.toLowerCase()
                        .includes(value.toLocaleLowerCase()) ||
                    String(record?.job_title)
                        ?.toLowerCase()
                        .includes(value.toLocaleLowerCase()) ||
                    String(record?.city)
                        ?.toLowerCase()
                        .includes(value.toLocaleLowerCase()) ||
                    String(record?.state)
                        ?.toLowerCase()
                        .includes(value.toLocaleLowerCase()) ||
                    String(record?.jobType)
                        ?.toLowerCase()
                        .includes(value.toLocaleLowerCase()) ||
                    String(record?.country)
                        ?.toLowerCase()
                        .includes(value.toLocaleLowerCase())
                );
            },
            render: (record, key) => {
                return <>{record?.job_title}</>;
            },
        },
        {
            title: "Client",
            key: "id",
            render: (record, key) => {
                return <>{record?.company_name}</>;
            },
        },
        {
            title: "CTC",
            key: "ctc",
            render: (record, key) => {
                return (
                    <>
                        {record?.min_CTC}-{record?.max_CTC}{" "}
                        {record?.currencyType === "INR" ? "LPA" : "K"}
                    </>
                );
            },
        },
        {
            title: "Job Type",
            key: "id",
            render: (record, key) => {
                return <>{record?.jobType}</>;
            },
        },
        {
            title: "Actions",
            key: "id",
            render: (value, record) => {
               
                return (
                    <>
                        <Button
                            type="primary"
                            disabled={!(userState?.role === "superAdmin" || allowedIds?.includes(userState?.userInfo?.id))}
                            onClick={() => { showAddNewModal(record?.id, record) }}
                        >
                            View Details
                        </Button>
                    
                        <Modal
                            title="Job Position Approval"
                            open={isAddNewModalOpen === record?.id}
                            visible={isAddNewModalOpen === record?.id}
                            onCancel={handleCancelAddNew}
                            footer={[
                                <Button type="ghost" key="cancel" onClick={handleCancelAddNew}>
                                    Cancel
                                </Button>,
                                
                                
                                <Popconfirm
                                    title="Sure you want to Accept this assigned Job?"
                                    onConfirm={() => {
                                        handleOkAddNew(record?.id, "Accept");
                                    }}
                                    onCancel={() => {
                                        handleCancelAddNew();
                                        message?.error("Cancelled");
                                    }}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button type="primary" key="accept">
                                        Accept
                                    </Button>
                                </Popconfirm>,
                            ]}
                        >
                            <Card
                                title={currentJob?.job_title}
                                headStyle={{ fontSize: "18px", fontWeight: "bold" }}
                                extra={
                                    <div>
                                        <Button
                                            style={{ marginRight: "15px" }}
                                            type="ghost"
                                            key="cancel"
                                            onClick={handleCancelAddNew}
                                        >
                                            Cancel
                                        </Button>
                                        <Popconfirm
                                            title="Sure you want to Accept this assigned Job?"
                                            onConfirm={() => {
                                                handleOkAddNew(currentJob?.id, "Accept");
                                            }}
                                            onCancel={() => {
                                                handleCancelAddNew();
                                                message?.error("Cancelled");
                                            }}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button type="primary" key="accept">
                                                Accept
                                            </Button>
                                        </Popconfirm>
                                    </div>
                                }
                            >
                                <Row>
                                    <Col span={12}>
                                        <DescriptionItem
                                            title="Company Name"
                                            Link={"https://www.google.com"}
                                            content={
                                                <a
                                                    href={currentJob?.client?.url}
                                                    style={{ color: "inherit" }}
                                                >
                                                    {currentJob?.company_name}
                                                </a>
                                            }
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <DescriptionItem
                                            title="Total Rounds"
                                            content={currentJob?.number_of_rounds}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={12}>
                                        <DescriptionItem
                                            title="Experience"
                                            content={
                                                currentJob?.min_experience_req +
                                                " - " +
                                                currentJob?.max_experience_req +
                                                " Years"
                                            }
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <DescriptionItem
                                            title="CTC"
                                            content={`${CurrencySymbols[currentJob?.currencyType]}
            ${currentJob?.min_CTC}${ctcMap[
                                                currentJob?.currencyType
                                                    ? currentJob?.currencyType
                                                    : "INR"
                                                ]
                                                } - ${currentJob?.max_CTC}${ctcMap[
                                                currentJob?.currencyType
                                                    ? currentJob?.currencyType
                                                    : "INR"
                                                ]
                                                } P.A.`}
                                        />
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: "13px" }}>
                                    <Col span={12}>
                                        <DescriptionItem
                                            title="Job Type"
                                            content={currentJob?.jobType}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <DescriptionItem
                                            title="No. of Positions"
                                            content={currentJob?.number_of_positions}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24} style={{ paddingTop: "13px" }}>
                                        <DescriptionItem
                                            title="Job Place"
                                            labelStyle
                                            content={
                                                currentJob?.country +
                                                "  - " +
                                                currentJob?.state +
                                                "  , " +
                                                currentJob?.city
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24} style={{ paddingTop: "13px" }}>
                                        <DescriptionItem
                                            title="Skills Required"
                                            labelStyle
                                            content={
                                                <div
                                                    style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}
                                                >
                                                    {currentJob?.skills_req?.map((data1) => {
                                                        return (
                                                            <>
                                                                <Tag color="blue">{data1.toUpperCase() + "  "}</Tag>
                                                            </>
                                                        );
                                                    })}
                                                </div>
                                            }
                                        />
                                    </Col>
                                </Row>
                            </Card>
                            <Card style={{ marginTop: "30px" }}>
                                <Row>
                                    <Col span={24}>
                                        <DescriptionItem
                                            title={<b> Job Description</b>}
                                            content={
                                                <>
                                                    {currentJob?.job_description?.[0] === "<" ? (
                                                        <div>{parse(currentJob?.job_description)}</div>
                                                    ) : (
                                                        <pre
                                                            style={{
                                                                color: "black",
                                                                whiteSpace: "pre-wrap",
                                                                fontFamily: "monospace",
                                                            }}
                                                        >
                                                            {currentJob?.job_description}
                                                        </pre>
                                                    )}
                                                </>
                                            }
                                        />
                                    </Col>
                                    <Divider />
                                    <Col span={24}>
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href={`${process.env.REACT_APP_SERVER_URL}/api/files/jd/${currentJob?.jd_urn}`}
                                        >
                                            <Button type="primary" icon={pdfloading ? <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                style={spinStyle} class="lucide lucide-loader-circle"><path d="M21 12a9 9 0 1 1-6.219-8.56" /></svg> : <DownloadOutlined />} onClick={handleshowPdf}>
                                                {
                                                    pdfloading ? "Downloading JD" :
                                                        "Download JD"
                                                }
                                            </Button>
                                        </a>
                                    </Col>
                                </Row>
                            </Card>
                            <Card style={{ marginTop: "30px" }}>
                                <Row>
                                    <Col span={24}>
                                        <DescriptionItem
                                            title={<b>Hiring Process / Round Details</b>}
                                            content={currentJob?.hiring_process?.map(
                                                (val, _idx) => {
                                                    //console.log(val);
                                                    return (
                                                        <div>
                                                            <Title level={5}>{JSON.parse(val).name} :</Title>
                                                            <pre
                                                                style={{
                                                                    whiteSpace: "pre-wrap",
                                                                    fontFamily: "inherit",
                                                                }}
                                                            >
                                                                {JSON.parse(val).description}
                                                            </pre>
                                                        </div>
                                                    );
                                                }
                                            )}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        </Modal>
                    </>
                );
            },
        },
    ];
   
    

    const [declinedReason, setDeclinedReason] = useState('');
               
    const [isDeclineModalOpen,setIsDeclineModalOpen]=useState(false);
    const handleDecline = async (id,declinedReason) => { 
        setConfirmLoading(true);
        try{

          
            await approveJobBySuperAdmin(
                {jobId:id,declinedReason:declinedReason})
            notification.open({
                message:(
                    <span style={{fontWeight:"bold",fontSize:"17px"}}>
                        Job Declined 
                    </span>
                ),
            });
            setConfirmLoading(false);
            setFlag((flag) => !flag);
            setUpdateOpen(false);
            window.location.reload();
        }catch (e) {
            message?.error("Some Error Occurred");
        } finally {
            setConfirmLoading(false);
            setIsDeclineModalOpen(false);
            console.log(isAddNewModalOpen, "here")
            setFlag((flag) => !flag);
            console.log(isAddNewModalOpen, "here")
        }
        
    };
   
    const [isAddNewModalOpen, setIsAddNewModalOpen] = useState(false);
const showAddNewModal = (id, value) => {
    console.log(value, "Asdsa");
    console.log(value, id, "value here");
    setCurrentJob(value);
    setExperienceKeywordOptions(value?.experience_keyword);
    setPositionalKeywordOptions(value?.positional_keyword);
    setOtherSearchKeywordOptions(value?.other_search_keyword);
    setJobBoardKeywordOptions(value?.job_board_keyword);
    setTargetCompaniesOptions(value?.target_companies);
    setAlternateJobTitlesOptions(value?.alternate_job_titles);
    setQuestions(value?.job_questions || []);
    setCurrentIndex(id);
    setPartnerOptions(
        Array.isArray(AllPartnerAdmins) 
            ? AllPartnerAdmins.filter(
                (el) => !Array.isArray(currentJob?.pending_assignment) || !currentJob?.pending_assignment.includes(el?.key)
            )
            : [] 
    );
    setUpdateOpen(id);
};

    const handleOkAddNew = async (id, approval) => {
        setConfirmLoading(true)
        try {
            await approveJobBySuperAdmin(
                { jobId: id ,declinedReason:""});
            notification.open({
                message: (
                    <span style={{ fontWeight: "bold", fontSize: "17px" }}>
                        ✅Job Accepted Request Sent
                    </span>
                ),
            });
        } catch (e) {
            message?.error("Some Error Occurred");
        } finally {
            setConfirmLoading(false);
            setIsAddNewModalOpen(null);
            console.log(isAddNewModalOpen, "here")
            setFlag((flag) => !flag);
            console.log(isAddNewModalOpen, "here")
        }
    };
    const handleCancelAddNew = () => {
        setIsAddNewModalOpen(null);
    };

    //For dynammically Jd generate
    const handleshowPdf = async () => {
        setPdfLoading(true)
        try {
            if (currentJob?.isZyvkaJdAiEnable) {
                const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/files/jd/generate/${currentJob.job_title.split(" ").join("-")}`, {
                    jobId: currentJob?.id,
                    name: user?.firstName + " " + user?.lastName,
                    mobile: user?.mobileNum,
                    email: user?.email
                }, {
                    responseType: 'blob',
                    withCredentials: true
                });
                const url = URL.createObjectURL(response.data);
                window.open(url, '_blank');
            } else {
                const url = `${process.env.REACT_APP_SERVER_URL}/api/files/jd/${currentJob?.jd_urn}`
                window.open(url, '_blank')
            }
        } catch (error) {
            console.log(error);
            message.error("Some Error Occurred, please try after some time")
        }
        finally {
            setPdfLoading(false)
        }
    }
    return (
        <>
            <style>{keyframesStyle}</style>
            <div style={{ marginTop: "30px" }}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        flexWrap: "wrap",
                        gap: "10px",
                    }}
                >
                    <Space direction="horizontal" wrap={true}>
                        <Input
                            placeholder="Search Here"
                            style={{
                                border: "0.5px solid gray",
                                borderRadius: "5px",
                                outline: "none",
                                position: "relative",
                                width: 150,
                                padding: "3px",
                                paddingTop: "6px",

                                marginTop: "10px",
                                marginBottom: "10px",
                                lineHeight: "4ex",
                                minWidth: 200,
                                maxWidth: 400,
                                paddingLeft: 10,
                            }}
                            onChange={(e) => {
                                setSearchValue(e.target.value);
                            }}
                        />
                        <Button style={{ width: 80 }}>Search</Button>
                    </Space>
                </div>

                <Table
                    scroll={{ x: true }}
                    bordered={true}
                    columns={columns}
                    dataSource={columndata}
                />
            </div>
            <SemanticModal
                dimmer="inverted"
                size="large"
                zIndex={90}
                onClose={() => {
                    setUpdateOpen(false);
                }}
                open={updateOpen}
            >
                <SemanticModal.Header> Position Approval </SemanticModal.Header>
                <SemanticModal.Content scrolling>
                <SemanticModal
        open={isDeclineModalOpen}
        onClose={() => setIsDeclineModalOpen(false)}
        size="small"
    >
        <SemanticModal.Header>Decline Job</SemanticModal.Header>
        <SemanticModal.Content>
            <Form>
                <Form.Field required>
                    <label>Reason for Declining</label>
                    <TextArea 
                        rows={4}
                        placeholder="Enter the reason for declining this job"
                        onChange={(e) => setDeclinedReason(e.target.value)}
                        required = {true}
                    />
                </Form.Field>
                <Button 
                    style={{background:'red',marginRight:"10px"}}
                    loading={confirmLoading}
                    type="primary"
                    disabled={!declinedReason.trim()}
                    onClick={() => handleDecline(currentJob.id, declinedReason)}
                >
                    Confirm Decline
                </Button>
                <Button 
                    basic 
                    onClick={() => setIsDeclineModalOpen(false)}
                >
                    Cancel
                </Button>
            </Form>
        </SemanticModal.Content>
    </SemanticModal>
                    <Form loading={formLoading}>
                        <Form.Group widths={"16"}>
                            <Form.Input
                                type="text"
                                fluid
                                label="Job Title"
                                name="job_title"
                                required
                                value={currentJob?.job_title}
                                onChange={(e) => {
                                    setCurrentJob({
                                        ...currentJob,
                                        [e.target.name]: e.target.value,
                                    });
                                }}
                                placeholder="Enter Job Title"
                                width={4}
                            />
                            <Form.Select
                                label="currency"
                                name="currencyType"
                                options={Currency}
                                value={currentJob?.currencyType}
                                onChange={(_e, data) => {
                                    setCurrentJob({
                                        ...currentJob,
                                        [data.name]: data.value,
                                    });
                                }}
                                width={4}
                            ></Form.Select>
                            <Form.Input
                                type="number"
                                fluid
                                label="Min-CTC"
                                name="min_CTC"
                                required
                                value={currentJob?.min_CTC}
                                error={currentJob?.min_CTC ? null : "Minimum CTC is Required"}
                                onChange={(e) => {
                                    setCurrentJob({
                                        ...currentJob,
                                        [e.target.name]: e.target.value,
                                    });
                                }}
                                placeholder="Enter Minimum CTC"
                                width={4}
                            />
                            <Form.Input
                                type="number"
                                fluid
                                label="Max-CTC"
                                name="max_CTC"
                                required
                                error={currentJob?.max_CTC ? null : "Maximum CTC is Required"}
                                value={currentJob?.max_CTC}
                                onChange={(e) => {
                                    setCurrentJob({
                                        ...currentJob,
                                        [e.target.name]: e.target.value,
                                    });
                                }}
                                width={4}
                                placeholder="Enter Max CTC"
                            />
                        </Form.Group>
                        <Form.Group widths={"2"}>
                            <Form.Input
                                type="number"
                                fluid
                                label="Min - Exp ( In Years)"
                                name="min_experience_req"
                                required
                                value={currentJob?.min_experience_req}
                                error={
                                    currentJob?.min_experience_req !== null && currentJob?.min_experience_req !== undefined
                                        ? null
                                        : "This Field is Required"
                                }
                                onChange={(e) => {
                                    setCurrentJob({
                                        ...currentJob,
                                        [e.target.name]: e.target.value,
                                    });
                                }}
                                placeholder="Enter Minimum Experience"
                            />
                            <Form.Input
                                type="number"
                                fluid
                                label="Max - Exp ( In Years)"
                                name="max_experience_req"
                                required
                                error={
                                    currentJob?.max_experience_req !== null && currentJob?.max_experience_req !== undefined
                                        ? null
                                        : "This Field is Required"
                                }
                                value={currentJob?.max_experience_req}
                                onChange={(e) => {
                                    setCurrentJob({
                                        ...currentJob,
                                        [e.target.name]: e.target.value,
                                    });
                                }}
                                placeholder="Enter Maximum Experience"
                            />
                        </Form.Group>
                        <Form.Group widths={"2"}>
                            <Form.Input
                                type="number"
                                fluid
                                label="Number of Position"
                                name="number_of_positions"
                                required
                                error={
                                    currentJob?.number_of_positions
                                        ? null
                                        : "Number of Position is Required"
                                }
                                value={currentJob?.number_of_positions}
                                onChange={(e) => {
                                    setCurrentJob({
                                        ...currentJob,
                                        [e.target.name]: e.target.value,
                                    });
                                }}
                                placeholder="Number of Positions"
                            />
                            <Form.Checkbox
                                toggle
                                style={{ marginTop: "30px" }}
                                name="hotJob"
                                label="Hot Job ?"
                                checked={currentJob?.hotJob}
                                onChange={(e, data) => {
                                    setCurrentJob({ ...currentJob, [data.name]: data.checked });
                                }}
                            ></Form.Checkbox>
                            <Form.Checkbox
                                toggle
                                style={{ marginTop: "30px" }}
                                name="openToInternalTeamOnly"
                                label="Open to internalTeam only ?"
                                checked={currentJob?.openToInternalTeamOnly}
                                onChange={(e, data) => {
                                    data?.checked
                                        ? setCurrentJob({
                                            ...currentJob,
                                            [data.name]: data.checked,
                                            internalTeamWorking: true,
                                        })
                                        : setCurrentJob({
                                            ...currentJob,
                                            [data.name]: data.checked,
                                        });
                                }}
                            ></Form.Checkbox>
                            <Form.Checkbox
                                toggle
                                style={{ marginTop: "30px" }}
                                name="internalTeamWorking"
                                label="Internal Team Working ?"
                                checked={currentJob?.internalTeamWorking}
                                onChange={(e, data) => {
                                    setCurrentJob({ ...currentJob, [data.name]: data.checked });
                                }}
                            ></Form.Checkbox>
                            <Form.Checkbox
                                toggle
                                style={{ marginTop: "30px" }}
                                name="isZyvkaAiEnable"
                                label="Enable ZyvkaAI ?"
                                checked={currentJob?.isZyvkaAiEnable}
                                onChange={(e, data) => {
                                    setCurrentJob({ ...currentJob, [data.name]: data.checked });
                                }}
                            ></Form.Checkbox>
                        </Form.Group>{" "}
                        {/* <RichTextEditor
                            style={{ zIndex: "90" }}
                            initialValue={currentJob?.job_description}
                            getValue={(e) => {
                                setCurrentJob({ ...currentJob });
                            }}
                            disabled={true}
                        /> */}
                        {/* <JoditEditor
                            value={currentJob?.job_description}
                            tabIndex={1}
                            disabled={true}
                        /> */}
                        {/* <Form.TextArea value={currentJob?.job_description} disabled={true} /> */}


                        {/* NEW FIELDS SECTION */}
  <Form.Group widths="equal">
  <Form.Field>
    <label>Experience Keywords</label>
    <Dropdown
      multiple
      search
      selection
      allowAdditions
      additionLabel="Add: "
      name="experience_keyword"
      placeholder="Select or add Experience Keywords"
      value={currentJob?.experience_keyword || []}
      options={experienceKeywordOptions.map((item) => ({
        key: item,
        text: item,
        value: item,
      }))}
      onAddItem={(e, { value }) => {
        if (!experienceKeywordOptions.some(opt => opt === value)) {
          setExperienceKeywordOptions(prev => [
            ...prev,
            value
          ]);
        }
      }}
      onChange={(e, { name, value }) =>
        setCurrentJob({ ...currentJob, [name]: value })
      }
    />
  </Form.Field>

  <Form.Field>
    <label>Positional Keywords</label>
    <Dropdown
      multiple
      search
      selection
      allowAdditions
      additionLabel="Add: "
      name="positional_keyword"
      placeholder="Select or add Positional Keywords"
      value={currentJob?.positional_keyword || []}
      options={positionalKeywordOptions.map((item) => ({
        key: item,
        text: item,
        value: item,
      }))}
      onAddItem={(e, { value }) => {
        if (!positionalKeywordOptions.some(opt => opt === value)) {
          setPositionalKeywordOptions(prev => [
            ...prev,
            value
          ]);
        }
      }}
      onChange={(e, { name, value }) =>
        setCurrentJob({ ...currentJob, [name]: value })
      }
    />
  </Form.Field>
</Form.Group>

<Form.Group widths="equal">
  <Form.Field>
    <label>Other Search Keywords</label>
    <Dropdown
      multiple
      search
      selection
      allowAdditions
      additionLabel="Add: "
      name="other_search_keyword"
      placeholder="Select or add Other Search Keywords"
      value={currentJob?.other_search_keyword || []}
      options={otherSearchKeywordOptions.map((item) => ({
        key: item,
        text: item,
        value: item,
      }))}
      onAddItem={(e, { value }) => {
        if (!otherSearchKeywordOptions.some(opt => opt === value)) {
          setOtherSearchKeywordOptions(prev => [
            ...prev,
           value
          ]);
        }
      }}
      onChange={(e, { name, value }) =>
        setCurrentJob({ ...currentJob, [name]: value })
      }
    />
  </Form.Field>

  <Form.Field>
    <label>Job Board Keywords</label>
    <Dropdown
      multiple
      search
      selection
      allowAdditions
      additionLabel="Add: "
      name="job_board_keyword"
      placeholder="Select or add Job Board Keywords"
      value={currentJob?.job_board_keyword || []}
      options={jobBoardKeywordOptions.map((item) => ({
        key: item,
        text: item,
        value: item,
      }))}
      onAddItem={(e, { value }) => {
        if (!jobBoardKeywordOptions.some(opt => opt === value)) {
          setJobBoardKeywordOptions(prev => [
            ...prev,
           value
          ]);
        }
      }}
      onChange={(e, { name, value }) =>
        setCurrentJob({ ...currentJob, [name]: value })
      }
    />
  </Form.Field>
</Form.Group>

<Form.Group widths="equal">
  <Form.Field>
    <label>Target Companies</label>
    <Dropdown
      multiple
      search
      selection
      allowAdditions
      additionLabel="Add: "
      name="target_companies"
      placeholder="Select or add Target Companies"
      value={currentJob?.target_companies || []}
      options={targetCompaniesOptions.map((item) => ({
        key: item,
        text: item,
        value: item,
      }))}
      onAddItem={(e, { value }) => {
        if (!targetCompaniesOptions.some(opt => opt=== value)) {
          setTargetCompaniesOptions(prev => [
            ...prev,
             value
          ]);
        }
      }}
      onChange={(e, { name, value }) =>
        setCurrentJob({ ...currentJob, [name]: value })
      }
    />
  </Form.Field>

  <Form.Field>
    <label>Alternate Job Titles</label>
    <Dropdown
      multiple
      search
      selection
      allowAdditions
      additionLabel="Add: "
      name="alternate_job_titles"
      placeholder="Select or add Alternate Job Titles"
      value={currentJob?.alternate_job_titles || []}
      options={alternateJobTitlesOptions.map((item) => ({
        key: item,
        text: item,
        value: item,
      }))}
      onAddItem={(e, { value }) => {
        if (!alternateJobTitlesOptions.some(opt => opt === value)) {
          setAlternateJobTitlesOptions(prev => [
            ...prev,
            value
          ]);
        }
      }}
      onChange={(e, { name, value }) =>
        setCurrentJob({ ...currentJob, [name]: value })
      }
    />
  </Form.Field>
</Form.Group>

<Form.Group widths="equal">
  <Form.Input
    type="text"
    label="Preferred Industry"
    name="prefered_industry"
    value={currentJob?.prefered_industry}
    onChange={(e) =>
      setCurrentJob({ ...currentJob, prefered_industry: e.target.value })
    }
    placeholder="Preferred Industry"
  />
 
  </Form.Group>
  {/* <Form.Group widths="equal">
  <Form.TextArea
    label="Recruiter Script"
    name="recruiter_script"
    rows={6}
    value={currentJob?.recruiter_script}
    onChange={(e) =>
      setCurrentJob({ ...currentJob, recruiter_script: e.target.value })
    }
    placeholder="Enter recruiter script"
  />
</Form.Group> */}

<Form.Field>
  <label>Recruiter Script</label>
  <RichTextEditor
    // Use the formatted value to ensure HTML format even for older plain text scripts.
    initialValue={formatScript(currentJob?.recruiter_script)}
    getValue={(value) =>
      setCurrentJob({ ...currentJob, recruiter_script: value })
    }
  />
</Form.Field>



{/* Editable Job Description */}
<Form.Field>
  <label>Job Description</label>
  <RichTextEditor
    initialValue={currentJob?.job_description || ""}
    getValue={(value) =>
      setCurrentJob({ ...currentJob, job_description: value })
    }
    config={{ 
        readonly: true,
        toolbar: false,
        statusbar: false
      }}
  />
</Form.Field>

                        {/* <DescriptionItem
                            title={<b> Job Description</b>}
                            content={
                                <>
                                    {currentJob?.job_description?.[0] === "<" ? (
                                        <div>{parse(currentJob?.job_description)}</div>
                                    ) : (
                                        <pre
                                            style={{
                                                color: "black",
                                                whiteSpace: "pre-wrap",
                                                fontFamily: "monospace",
                                            }}
                                        >
                                            {currentJob?.job_description}
                                        </pre>
                                    )}
                                </>
                            }
                        /> */}
                        <Form.Select
                            defaultValue={currentJob?.skills_req}
                            options={skills}
                            search
                            required={true}
                            name="skills_req"
                            value={currentJob?.skills_req}
                            onChange={(_e, data) => {
                                setCurrentJob({ ...currentJob, [data.name]: data.value });
                            }}
                            multiple
                            label="Select skills"
                        ></Form.Select>
                          <Form.Field>

                           <QuestionsList questions={questions} setQuestions={setQuestions} />
                           </Form.Field>
                        <Form.Field>
                            <label>Select Zyvka Member</label>
                            <Dropdown
                                placeholder="Select Zyvka Team"
                                fluid
                                search
                                selection
                                multiple
                                name="zyvka_team_members"
                                defaultValue={currentJob?.zyvka_team_members}
                                onChange={(e, data) => {
                                    setCurrentJob({ ...currentJob, [data.name]: data.value });
                                }}
                                options={AllZyvkaUsers}
                            />
                        </Form.Field>
                        {currentJob?.openToInternalTeamOnly === false && (
                            <>
                                <Form.Field>
                                    <label>Pending Claim/Assigned</label>
                                    <Dropdown
                                        placeholder="Select Partners"
                                        fluid
                                        search
                                        multiple
                                        selection
                                        defaultValue={currentJob?.pending_assignment}
                                        name="pending_assignment"
                                        onChange={(e, data) => {
                                            setCurrentJob({ ...currentJob, [data.name]: data.value });
                                        }}
                                        options={AllPartnerAdmins}
                                        disabled={true}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label>Select Partner Admins</label>
                                    <Dropdown
                                        placeholder="Select Partners"
                                        fluid
                                        search
                                        multiple
                                        selection
                                        defaultValue={currentJob?.assigned_partners}
                                        name="assigned_partners"
                                        onChange={(e, data) => {
                                            setCurrentJob({ ...currentJob, [data.name]: data.value });
                                        }}
                                        options={partnerOptions}
                                    />
                                </Form.Field>
                            </>
                        )}
                        <Form.Field>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <label  >Revenue Model </label>
                                <span style={{ color: "red", fontSize: "12px", marginLeft: "3px" }} >*</span>
                            </div>
                            <Dropdown
                                fluid
                                search
                                placeholder="Enter Revenue Model"
                                selection
                                error={
                                    currentJob?.positionRevenue?.[0]?.RevenueMode
                                        ? null
                                        : "Revenue Model is Required"
                                }
                                required
                                name="revenue_model"
                                defaultValue={currentJob?.positionRevenue?.[0]?.RevenueMode}
                                onChange={(e, data) => {
                                    setCurrentJob({ ...currentJob, [data.name]: data.value });
                                }}
                                options={revenueOptions}
                            />
                        </Form.Field>
                        <Form.Input
                            type="number"
                            fluid
                            required
                            label="Charge Value"
                            name="charge_value"
                            error={
                                currentJob?.positionRevenue?.[0]?.Value
                                    ? null
                                    : "Value  is Required"
                            }
                            value={currentJob?.positionRevenue?.[0]?.Value}
                            onChange={(e) => {
                                setCurrentJob({
                                    ...currentJob,
                                    [e.target.name]: e.target.value,
                                });
                            }}
                            placeholder="Charge Value"
                        />
                        <Form.Field>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <label  >Currency  </label>
                                <span style={{ color: "red", fontSize: "12px", marginLeft: "3px" }} >*</span>
                            </div>
                            <Dropdown
                                fluid
                                search
                                required
                                error={
                                    currentJob?.positionRevenue?.[0]?.Currency
                                        ? null
                                        : "Revenue Model Currency  is Required"
                                }
                                selection
                                name="currency"
                                placeholder="Enter Currency"
                                defaultValue={currentJob?.positionRevenue?.[0]?.Currency}
                                onChange={(e, data) => {
                                    setCurrentJob({ ...currentJob, [data.name]: data.value });
                                }}
                                options={Currency}
                            />
                        </Form.Field>
                        <Form.Input
                            type="number"
                            fluid
                            required
                            label="Payment Term"
                            name="payment_term"
                            error={
                                currentJob?.positionRevenue?.[0]?.PaymentTerms
                                    ? null
                                    : "Payment Term  is Required"
                            }
                            value={currentJob?.positionRevenue?.[0]?.PaymentTerms}
                            onChange={(e) => {
                                setCurrentJob({
                                    ...currentJob,
                                    [e.target.name]: e.target.value,
                                });
                            }}
                            placeholder="Enter Payment Term"
                        />
                        <Form.Input
                            type="number"
                            fluid
                            required
                            label="Invoicing Term"
                            name="invoicing_term"
                            error={
                                currentJob?.positionRevenue?.[0]?.InvoicingTerms
                                    ? null
                                    : "Invoicing Term is Required"
                            }
                            value={ currentJob?.positionRevenue?.[0]?.InvoicingTerms}
                            onChange={(e) => {
                                setCurrentJob({
                                    ...currentJob,
                                    [e.target.name]: e.target.value,
                                });
                            }}
                            placeholder="Invoicing Term"
                        />
                        <Form.Input
                            type="number"
                            required
                            fluid
                            label="Repalcement Timeline"
                            name="replacement_timeline"
                            error={
                                currentJob?.positionRevenue?.[0]?.ReplacementTimeline
                                    ? null
                                    : "Replacement Timeline is Required"
                            }
                            value={currentJob?.positionRevenue?.[0]?.ReplacementTimeline}
                            onChange={(e) => {
                                setCurrentJob({
                                    ...currentJob,
                                    [e.target.name]: e.target.value,
                                });
                            }}
                            placeholder="Replacement Timeline"
                        />
                        <Form.Field>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <label  >Repalcement Model </label>
                                <span style={{ color: "red", fontSize: "12px", marginLeft: "3px" }} >*</span>
                            </div>
                            <Form.Dropdown
                                placeholder="Enter Replacement Model"
                                fluid
                                search
                                selection
                                error={
                                    currentJob?.positionRevenue?.[0]?.ReplacementModel
                                        ? null
                                        : "Replacement Model is Required"
                                }
                                required={true}
                                name="replacement_model"
                                defaultValue={currentJob?.positionRevenue?.[0]?.ReplacementModel}
                                onChange={(e, data) => {
                                    setCurrentJob({ ...currentJob, [data.name]: data.value });
                                }}
                                options={modelOptions}
                                />
                        </Form.Field>

                    </Form>
                </SemanticModal.Content>
                <SemanticModal.Actions>
        <Button negative onClick={() => setUpdateOpen(false)}>
            Cancel
        </Button>
        <Button 
            type="primary" 
            style={{ marginLeft: "10px", backgroundColor: "red" }}  
            onClick={() => setIsDeclineModalOpen(true)}
        >
            Decline
        </Button>
        
        <Tooltip
            title={!currentJob?.isJdVideoUploaded ? "Upload Video JD first" : ""}
        >
            <Button 
                type="primary" 
                style={{ marginLeft: "10px" }} 
                positive 
                onClick={onSave}
                disabled={!currentJob?.isJdVideoUploaded}
            >
                Update and Approve
            </Button>
        </Tooltip>

        <Button
            style={{ marginLeft: "10px" }}
            onClick={() => setShowScriptModal(true)}
        >
            View Partner Script
        </Button>

        <Upload
            customRequest={({ file }) => handleVideoUpload(file)}
            showUploadList={false}
            accept="video/*"
        >
            <Button
                type="primary"
                style={{ marginLeft: "10px" }}
                loading={uploadingVideo}
            >
                {currentJob?.isJdVideoUploaded ? 'Re-upload Video JD' : 'Upload Video JD'}
            </Button>
        </Upload>
    </SemanticModal.Actions>
            </SemanticModal>
            {/* {
                isAddNewModalOpen === currentJob?.id &&
                <Modal
                    width={700}
                    centered={true}
                    style={{
                        top: 40,
                    }}
                    visible={isAddNewModalOpen === currentJob?.id}
                    onCancel={handleCancelAddNew}
                    footer={[
                        <Button type="ghost" key="cancel" onClick={handleCancelAddNew}>
                            Cancel
                        </Button>,
                        <Popconfirm
                            title="Sure you want to Accept this assigned Job?"
                            onConfirm={() => {
                                handleOkAddNew(currentJob?.id, "Accept");
                            }}
                            okButtonProps={{
                                loading: confirmLoading,
                            }}
                            onCancel={() => {
                                handleCancelAddNew();
                                message?.error("Cancelled");
                            }}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="primary" key="accept">
                                Accept
                            </Button>
                        </Popconfirm>,
                    ]}
                >
                    <Card
                        title={currentJob?.job_title}
                        headStyle={{ fontSize: "18px", fontWeight: "bold" }}
                        extra={
                            <div>
                                <Button
                                    style={{ marginRight: "15px" }}
                                    type="ghost"
                                    key="cancel"
                                    onClick={handleCancelAddNew}
                                >
                                    Cancel
                                </Button>
                                <Popconfirm
                                    title="Sure you want to Accept this assigned Job?"
                                    onConfirm={() => {
                                        handleOkAddNew(currentJob?.id, "Accept");
                                    }}
                                    onCancel={() => {
                                        handleCancelAddNew();
                                        message?.error("Cancelled");
                                    }}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button type="primary" key="accept">
                                        Accept
                                    </Button>
                                </Popconfirm>
                            </div>
                        }
                    >
                        <Row>
                            <Col span={12}>
                                <DescriptionItem
                                    title="Company Name"
                                    Link={"https://www.google.com"}
                                    content={
                                        <a
                                            href={currentJob?.client?.url}
                                            style={{ color: "inherit" }}
                                        >
                                            {currentJob?.company_name}
                                        </a>
                                    }
                                />
                            </Col>
                            <Col span={12}>
                                <DescriptionItem
                                    title="Total Rounds"
                                    content={currentJob?.number_of_rounds}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <DescriptionItem
                                    title="Experience"
                                    content={
                                        currentJob?.min_experience_req +
                                        " - " +
                                        currentJob?.max_experience_req +
                                        " Years"
                                    }
                                />
                            </Col>
                            <Col span={12}>
                                <DescriptionItem
                                    title="CTC"
                                    content={`${CurrencySymbols[currentJob?.currencyType]}
            ${currentJob?.min_CTC}${ctcMap[
                                        currentJob?.currencyType
                                            ? currentJob?.currencyType
                                            : "INR"
                                        ]
                                        } - ${currentJob?.max_CTC}${ctcMap[
                                        currentJob?.currencyType
                                            ? currentJob?.currencyType
                                            : "INR"
                                        ]
                                        } P.A.`}
                                />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: "13px" }}>
                            <Col span={12}>
                                <DescriptionItem
                                    title="Job Type"
                                    content={currentJob?.jobType}
                                />
                            </Col>
                            <Col span={12}>
                                <DescriptionItem
                                    title="No. of Positions"
                                    content={currentJob?.number_of_positions}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} style={{ paddingTop: "13px" }}>
                                <DescriptionItem
                                    title="Job Place"
                                    labelStyle
                                    content={
                                        currentJob?.country +
                                        "  - " +
                                        currentJob?.state +
                                        "  , " +
                                        currentJob?.city
                                    }
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} style={{ paddingTop: "13px" }}>
                                <DescriptionItem
                                    title="Skills Required"
                                    labelStyle
                                    content={
                                        <div
                                            style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}
                                        >
                                            {currentJob?.skills_req?.map((data1) => {
                                                return (
                                                    <>
                                                        <Tag color="blue">{data1.toUpperCase() + "  "}</Tag>
                                                    </>
                                                );
                                            })}
                                        </div>
                                    }
                                />
                            </Col>
                        </Row>
                    </Card>
                    <Card style={{ marginTop: "30px" }}>
                        <Row>
                            <Col span={24}>
                                <DescriptionItem
                                    title={<b> Job Description</b>}
                                    content={
                                        <>
                                            {currentJob?.job_description?.[0] === "<" ? (
                                                <div>{parse(currentJob?.job_description)}</div>
                                            ) : (
                                                <pre
                                                    style={{
                                                        color: "black",
                                                        whiteSpace: "pre-wrap",
                                                        fontFamily: "monospace",
                                                    }}
                                                >
                                                    {currentJob?.job_description}
                                                </pre>
                                            )}
                                        </>
                                    }
                                />
                            </Col>
                            <Divider />
                            <Col span={24}>
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={`${process.env.REACT_APP_SERVER_URL}/api/files/jd/${currentJob?.jd_urn}`}
                                >
                                    <Button type="primary" icon={pdfloading ? <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                        style={spinStyle} class="lucide lucide-loader-circle"><path d="M21 12a9 9 0 1 1-6.219-8.56" /></svg> : <DownloadOutlined />} onClick={handleshowPdf}>
                                        {
                                            pdfloading ? "Downloading JD" :
                                                "Download JD"
                                        }
                                    </Button>
                                </a>
                            </Col>
                        </Row>
                    </Card>
                    <Card style={{ marginTop: "30px" }}>
                        <Row>
                            <Col span={24}>
                                <DescriptionItem
                                    title={<b>Hiring Process / Round Details</b>}
                                    content={currentJob?.hiring_process?.map(
                                        (val, _idx) => {
                                            //console.log(val);
                                            return (
                                                <div>
                                                    <Title level={5}>{JSON.parse(val).name} :</Title>
                                                    <pre
                                                        style={{
                                                            whiteSpace: "pre-wrap",
                                                            fontFamily: "inherit",
                                                        }}
                                                    >
                                                        {JSON.parse(val).description}
                                                    </pre>
                                                </div>
                                            );
                                        }
                                    )}
                                />
                            </Col>
                        </Row>
                    </Card>
                </Modal>
            } */}
         <Modal
  title="Partner Script"
  visible={showScriptModal}
  onCancel={() => setShowScriptModal(false)}
  footer={null}
>
  <div
    style={{ maxHeight: "60vh", overflow: "auto" }}
    dangerouslySetInnerHTML={{ __html: formatScript(currentJob?.partner_script) }}
  />
</Modal>


        </>
    );
};

export default SuperAdminPositionApproval;
