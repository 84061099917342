import  React from "react";

const AnimatedLogo= () => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          animation: 'pulse 2s infinite'
        }}
      >
        <rect width="32" height="32" rx="8" fill="url(#gradient)" />
        <path
          d="M8 12C8 10.8954 8.89543 10 10 10H22C23.1046 10 24 10.8954 24 12V20C24 21.1046 23.1046 22 22 22H10C8.89543 22 8 21.1046 8 20V12Z"
          stroke="white"
          strokeWidth="2"
          style={{
            strokeDasharray: 1000,
            strokeDashoffset: 1000,
            animation: 'draw 2s ease-out infinite'
          }}
        />
        <circle
          cx="13"
          cy="16"
          r="2"
          fill="white"
          style={{ animation: 'blink 1.5s infinite' }}
        />
        <circle
          cx="19"
          cy="16"
          r="2"
          fill="white"
          style={{ animation: 'blink 1.5s infinite' }}
        />
        <path
          d="M11 19H21"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          style={{
            animation: 'wave 2s ease-in-out infinite'
          }}
        />
        <defs>
          <linearGradient id="gradient" x1="0" y1="0" x2="32" y2="32" gradientUnits="userSpaceOnUse">
            <stop stopColor="#8C52FF" />
            <stop offset="1" stopColor="#5E17EB" />
          </linearGradient>
        </defs>
      </svg>
      <span style={{ fontSize: '14px', fontWeight: 500, color: '#3A3838' }}>
        Powered by ZyBOT
      </span>

      <style dangerouslySetInnerHTML={{
        __html: `
          @keyframes pulse {
            0%, 100% { transform: scale(1); }
            50% { transform: scale(1.05); }
          }
          @keyframes draw {
            to { stroke-dashoffset: 0; }
          }
          @keyframes blink {
            0%, 100% { opacity: 1; }
            50% { opacity: 0.5; }
          }
          @keyframes wave {
            0%, 100% { transform: translateY(0); }
            50% { transform: translateY(-2px); }
          }
        `
      }} />
    </div>
  )
}

export default AnimatedLogo;