import React, { useContext, useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { Button, ButtonBlock, CloseIcon, colors, CustomInput, Label, Popup, PopupBody, PopupName, Row } from '../components';
import { Avatar } from 'sceyt-chat-react-uikit';
import { PlusOutlined } from '@ant-design/icons';
import { SceytContext } from '../sceytContext';
import {AdminSceytContext} from "../AdminSceytContext"
import axios from 'axios';


const SearchIcon = styled.div`
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: ${colors.textFootnote};
`;

const AddButton = styled.div`
  cursor: pointer;
  display: flex;
  height: 36px;
  width: 36px;
  margin-right: 4px;
  border-radius: 50%; 
  align-items: center;
  justify-content: center;
  border: 1px solid rgb(135, 134, 134);  
  transition: all 0.3s ease;   

  &:hover {
    background-color: rgba(104, 85, 246, 0.06);    
  }
`;

const ResponsiveModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 150;
  
  @media (max-width: 768px) {
    padding: 16px;
  }
`;

const ResponsivePopup = styled(Popup)`
  width: 100%;
  max-width: 520px;
  max-height: 90vh;
  
  @media (max-width: 768px) {
    width: 95%;
    max-height: 95vh;
    margin: 0 auto;
  }
`;

const ResponsiveSearchContainer = styled.div`
  position: relative;
  margin: 16px 0 24px 0;
  width: 100%;
`;

const ResponsiveUserListContainer = styled.div`
  max-height: 35vh;
  overflow-y: auto;
  width: 100%;
  
  @media (max-width: 768px) {
    max-height: 50vh;
  }
`;

const ResponsiveUserListItem = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  margin-bottom: 8px;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;

  &:hover {
    background-color: ${colors.gray1};
  }

  @media (max-width: 768px) {
    gap: 8px;
  }
`;

const UserInfo = styled.div`
  margin-left: 12px;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const UserName = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${colors.textColor1};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const UserRole = styled.div`
  font-size: 12px;
  color: ${colors.textFootnote};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const AddChannelMembers = ({ userId, partnerAdminId, userRole, superAdminView=false }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const ChatContext = superAdminView ? AdminSceytContext : SceytContext;

  const sceytContext = useContext(ChatContext);
  
  const client = sceytContext.client;
  const activeChannel = sceytContext.activeChannel;

  useEffect(() => {
    if (isOpen) {
      fetchUsers();
    }
  }, [isOpen]);

  useEffect(() => {
    const filtered = users.filter(user => {
      const fullName = `${user.first_name} ${user.last_name}`.toLowerCase();
      return fullName.includes(searchQuery.toLowerCase()) ||
        user?.metadata?.role?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user?.id?.toLowerCase().includes(searchQuery.toLowerCase());
    });
    setFilteredUsers(filtered);
  }, [searchQuery, users]);

  // const constructSearchFilters = () => {
  //   const nameParts = searchQuery.trim().split(' ');
  //   const filters = [];
    
  //   if (nameParts.length === 2) {
  //     filters.push(
  //       `first_name:eq:${nameParts[0]}`, 
  //       `last_name:eq:${nameParts[1]}`
  //     );
  //   } else if (nameParts.length === 1) {
  //     filters.push(
  //       `first_name:eq:${nameParts[0]}`
  //     );
  //   }
    
  //   return filters.length > 0 ? filters : null;
  // };

  // const fetchUsers = useCallback(async (loadMore = false, isSearch = false) => {
  //   if (isLoading) return;

  //   setIsLoading(true);
  //   try {
  //     let url = `${process.env.REACT_APP_SERVER_URL}/api/scyetChat/getAlluser`;
  //     let params = new URLSearchParams();

  //     if (searchQuery) {
  //       const searchFilters = constructSearchFilters();
  //       if (searchFilters) {
  //         searchFilters.forEach(filter => {
  //           params.append('filter', filter);
  //         });
  //       }
  //     }

  //     if (nextToken) {
  //       params.append('next', nextToken);
  //     }

  //     const fullUrl = `${url}?${params.toString()}`;

  //     const response = await axios.get(fullUrl, { withCredentials: true });

  //     if (response.statusText === "OK") {
  //       let usersToSet = response.data.user.users;
        
  //       // Filter out current user and existing channel members
  //       usersToSet = usersToSet.filter(user => 
  //         user.id !== userId && 
  //         !activeChannel?.members?.some(member => member.id === user.id)
  //       );

  //       // Apply role-based filtering
  //       if (userRole === "partner") {
  //         usersToSet = usersToSet.filter(user => 
  //           user.metadata?.partnerAdminId === partnerAdminId || 
  //           user.id === partnerAdminId
  //         );
  //       } else if (userRole === "partnerAdmin") {
  //         usersToSet = usersToSet.filter(user =>
  //           user.metadata?.partnerAdminId === userId
  //         );
  //       }

  //       setUsers(prev => loadMore ? [...prev, ...usersToSet] : usersToSet);
  //       setNextToken(response.data.user.next || null);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching users:', error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // }, [
  //   isLoading, 
  //   nextToken, 
  //   userId, 
  //   userRole, 
  //   partnerAdminId, 
  //   searchQuery, 
  //   activeChannel
  // ]);

  // const handleScroll = useCallback((e) => {
  //   const { scrollTop, scrollHeight, clientHeight } = e.target;
  //   const isNearBottom = scrollHeight - (scrollTop + clientHeight) <= 100;
    
  //   if (isNearBottom && nextToken && !isLoading) {
  //     fetchUsers(true);
  //   }
  // }, [nextToken, isLoading, fetchUsers]);

  // const handleSearch = () => {
  //   if (searchQuery.trim()) {
  //     fetchUsers(false, true);
  //   }
  // };

  // const handleKeyPress = (e) => {
  //   if (e.key === 'Enter') {
  //     handleSearch();
  //   }
  // };

  
  const fetchUsers = useCallback(async (loadMore = false) => {
    if (isLoading) return;

    setIsLoading(true);
    try {
     
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/scyetChat/getAlluser?next=${nextToken}`, {
       
        withCredentials: true
      });

      if (response.statusText === "OK") {
        let usersToSet = response.data.user.users;
        usersToSet = usersToSet.filter(user => user.id !== userId);

        if (userRole === "partner") {
          usersToSet = usersToSet.filter(user => 
            user.metadata?.partnerAdminId === partnerAdminId || 
            user.id === partnerAdminId
          );
        } else if (userRole === "partnerAdmin") {
          usersToSet = usersToSet.filter(user =>
            user.metadata?.partnerAdminId === userId
          );
        }

        setUsers(prev => loadMore ? [...prev, ...usersToSet] : usersToSet);
        setNextToken(response.data.user.next || null);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }, [isLoading, nextToken, userId, userRole, partnerAdminId]);



  // useEffect(() => {
  //   fetchUsers();
  // }, []);

  const handleScroll = useCallback((e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    const isNearBottom = scrollHeight - (scrollTop + clientHeight) <= 100;
    
    if (isNearBottom && nextToken && !isLoading) {
      fetchUsers(true);
    }
  }, [nextToken, isLoading, fetchUsers]);

  const handleAddMembers = async () => {
    try {
      if (!activeChannel) {
        console.error('No active channel selected');
        return;
      }

      const payload = {
        user_id: userId,
        members: selectedMembers.map(memberId => ({
          id: memberId,
          role: "participant"
        }))
      };

      const response = await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/scyetChat/addmembers/${activeChannel.id}`,
        payload,
        { withCredentials: true }
      );

      if (response.data) {
        setIsOpen(false);
        setSelectedMembers([]);
        setSearchQuery('');
      }
    } catch (error) {
      console.error('Error adding members:', error);
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchUsers();
    }
  }, [isOpen]);

  if (!client || (!activeChannel && isOpen)) {
    return null;
  }

  return (
    <>
      <AddButton onClick={() => setIsOpen(true)}>
        <PlusOutlined style={{ fontSize: '20px' }} />
      </AddButton>

      {isOpen && (
        <ResponsiveModal>
          <div style={{ position: 'absolute', width: '100%', maxWidth: '520px' }}>
            <ResponsivePopup
              backgroundColor={colors.white}
              padding="0"
              style={{ position: 'relative', zIndex: 200 }}
            >
              <PopupBody paddingH="24px" paddingV="24px">
                <CloseIcon color={colors.textColor1} onClick={() => setIsOpen(false)} />
                <PopupName color={colors.textColor1} marginBottom="20px">
                  Add Channel Members
                </PopupName>

                <ResponsiveSearchContainer>
                <SearchIcon>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <circle cx="11" cy="11" r="8" />
                      <line x1="21" y1="21" x2="16.65" y2="16.65" />
                    </svg>
                  </SearchIcon>
                  <CustomInput
                    type="text"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Search users..."
                    color={colors.textColor1}
                    placeholderColor={colors.textFootnote}
                    backgroundColor={colors.gray1}
                    style={{ paddingLeft: '36px' }}
                  />
                </ResponsiveSearchContainer>

                <ResponsiveUserListContainer onScroll={handleScroll}>
                  {filteredUsers.map(user => (
                    <ResponsiveUserListItem
                      key={user.id}
                      onClick={() => {
                        setSelectedMembers(prev =>
                          prev.includes(user.id)
                            ? prev.filter(id => id !== user.id)
                            : [...prev, user.id]
                        );
                      }}
                    >
                      <Avatar
                        image={user.avatar_url}
                        name={`${user.first_name} ${user.last_name}`}
                        size={35}
                        textSize={16}
                        setDefaultAvatar
                      />
                      <UserInfo>
                        <UserName>{`${user.first_name} ${user.last_name} (${user?.metadata?.role})`}</UserName>
                        <UserRole>{user?.metadata?.email}</UserRole>
                      </UserInfo>
                      <CustomInput
                        type="checkbox"
                        width="18px"
                        height="18px"
                        checked={selectedMembers.includes(user.id)}
                        readOnly
                      />
                    </ResponsiveUserListItem>
                  ))}
                  {isLoading && <div>Loading users...</div>}
                </ResponsiveUserListContainer>

                <ButtonBlock style={{ marginTop: '24px' }}>
                  <Button
                    onClick={handleAddMembers}
                    backgroundColor={colors.primary}
                    color={colors.white}
                    disabled={selectedMembers.length === 0}
                  >
                    Add Members
                  </Button>
                </ButtonBlock>
              </PopupBody>
            </ResponsivePopup>
          </div>
        </ResponsiveModal>
      )}
    </>
  );
};

export default AddChannelMembers;