import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Layout,
  Typography,
  Card,
  Tabs,
  Button,
  List,
  Tag,
  Row,
  Col,
  Spin,
  Descriptions,
  message,
  Tooltip
} from "antd";
import Icon, {
  DollarCircleOutlined,
  TeamOutlined,
  CalendarOutlined,
  BankOutlined,
  FileTextOutlined,
  UserAddOutlined,
  DownloadOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { ArchiveJob, fetchAllPartnerAdmins, fetchJobData, SendEmailToPartners, UnArchiveJob } from "../api/ZyvkaMember";
import { IsZyvkaTeamMember } from "../utils/functions";
import { useDispatch, useSelector } from "react-redux";
import { ZyvkaMemberState } from "../features/ZyvkaMemberSlice";
import { selectUser, UserState } from "../features/userSlice";
import {Modal,Form,Dropdown} from "semantic-ui-react"
import axios from "axios";
import RichTextEditor from "../Small-Components/RichTextEditor";
const API = axios.create({ baseURL: process.env.REACT_APP_SERVER_URL });

const { Title, Text } = Typography;
const { Content } = Layout;
const { TabPane } = Tabs;

// Helper to extract list items as plain text from an HTML snippet.
const extractListItems = (htmlContent) => {
  const liRegex = /<li>(.*?)<\/li>/gi;
  let items = [];
  let match;
  while ((match = liRegex.exec(htmlContent)) !== null) {
    // Remove any nested HTML tags.
    items.push(match[1].replace(/<\/?[^>]+(>|$)/g, "").trim());
  }
  return items;
};

// Helper to extract a section based on keywords (case-insensitive).
const extractSection= (html, keywords) => {
  // This regex matches an H3 header and captures its content until the next H3 or closing div.
  const regex = /<h3[^>]*>(.*?)<\/h3>([\s\S]*?)(?=<h3>|<\/div>)/gi;
  let match;
  while ((match = regex.exec(html)) !== null) {
    const heading = match[1].toLowerCase();
    // Check if any provided keyword is found in the heading.
    const found = keywords.some(kw => heading.includes(kw.toLowerCase()));
    if (found) {
      const content = match[2];
      if (content.includes("<li>")) {
        // If list items are present, extract them.
        return extractListItems(content);
      } else {
        // Otherwise, strip all HTML tags and split the text.
        const stripped = content.replace(/<\/?[^>]+(>|$)/g, "").trim();
        return stripped.split(/[\n,]+/).map(item => item.trim()).filter(item => item);
      }
    }
  }
  return [];
};



const extractRequirements = (html) => {
  const regex = /<h3>Requirements:<\/h3>\s*<ul>(.*?)<\/ul>/is;
  const match = html.match(regex);
  if (match && match[1]) {
    const liRegex = /<li>(.*?)<\/li>/gi;
    let list = [];
    let liMatch;
    while ((liMatch = liRegex.exec(match[1])) !== null) {
      // Remove any HTML tags (like <strong>) from the list item text.
      const text = liMatch[1].replace(/<[^>]+>/g, '').trim();
      list.push(text);
    }
    return list;
  }
  return [];
};

const JobDetailsPage = () => {
  const dispatch=useDispatch();
  const params = useParams();
  const id = params.id;
  // const zyvkaState = useSelector(ZyvkaMemberState);
  const user = useSelector(selectUser);
    const userState = useSelector(UserState);
  const [jobData, setJobData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
   const [emailModal, setEmailModal] = useState(false);
    const [isAddNewModalOpen, setIsAddNewModalOpen] = useState(false);
     const [AllPartnerAdmins, setAllPartnerAdmins] = useState([]);
     const [activeTab, setActiveTab] = useState("mustHave");
     const revenueData = jobData?.positionRevenue?.[0] || {};
     const clientRevenueData = jobData?.client?.revenue?.[0] || {};

     const tabItems = [
       { key: "mustHave", label: "Must-Have Skills" },
       { key: "goodToHave", label: "Good-to-Have Skills" },
       { key: "academic", label: "Academic Qualifications" },
       { key: "otherReq", label: "Other Requirements" },
       { key: "kpis", label: "KPIs" },
      //  { key: "jd", label: "JD" }, 
     ];
      const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
       const [notiForm, setNotiForm] = useState({
          title: "",
          body: "",
        });
         const [messageForm, setMessageForm] = useState({
            body: "",
          });
          const [emailForm, setEmailForm] = useState({
              subject: "",
              body: "",
            });
            const getValue = (value) => {
              setEmailForm({ ...emailForm, body: value });
            };
  const success = () => {
    message.success("notification sent successfully ");
  };
  const error = () => {
    message.error("some error occurred while sending the notification");
  };
  const info = () => {
    message?.info(
      "this job doesnt have any assigned partners, please add them first"
    );
  };
  useEffect(() => {
    const loadJobDetails = async () => {
      try {
        const response = await fetchJobData(id);
        setJobData(response);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching job details:", error);
        setLoading(false);
      }
    };
      const GetAllPartnerAdmins = async () => {
        try {
          await fetchAllPartnerAdmins(dispatch, setAllPartnerAdmins);
        } catch (error) {
          console.log(error);
        }
      };

    GetAllPartnerAdmins();
    loadJobDetails();
  }, [id]);
  const archived=jobData?.active_status===3 || false;
  const closed=jobData?.active_status===4 || false;

  const sendNotificationToIdsGroup = async (data) => {
    await API.post(`api/notification/sendIndividualNotification`, data, {
      withCredentials: true,
    });
  };
  const sendNotificationToJobIds = async () => {
    const idArray = jobData?.assigned_partners?.filter((el) => el !== "");

    try {
      if (idArray?.length === 0) {
        info();
      } else {
        await sendNotificationToIdsGroup({
          title: notiForm?.title,
          ids: idArray,
          body: notiForm?.body,
        });
        setIsAddNewModalOpen(false);
        success();
      }
    } catch (e) {
      error();
      setIsAddNewModalOpen(false);
    }
  };

  const formatScript = (script) => {
    if (!script) return "";
    // If the script already starts with an HTML tag, assume it's formatted.
    if (script.trim().startsWith("<")) {
      return script;
    }
    // Otherwise, convert plain text to HTML by wrapping in a <p> and replacing newlines.
    return `<p>${script.replace(/\n/g, "<br>")}</p>`;
  };

  const handleShowScript = () => setIsModalVisible(true);
  const handleCloseScript = () => setIsModalVisible(false);
  const handleCancelNotificationModal = () => {
    setIsAddNewModalOpen(false);
  };
  const handleSubmit = async () => {
    const isTitleValid = notiForm?.title?.trim() !== "";
    const isBodyValid = notiForm?.body?.trim() !== "";
    if (!isBodyValid || !isTitleValid) {
      message.error("Please fill out all required fields");
    } else {
      await sendNotificationToJobIds();
    }
  };
  
  const sendMessageToUsers = async (messageData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/scyetChat/sendChatToPartner`,
        messageData,
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error in sendMessageToUsers:", error);
      throw error;
    }
  };
  const handleEmailSubmit = async () => {
      const isTitleValid = emailForm?.subject?.trim() !== "";
      const isBodyValid = emailForm?.body?.trim() !== "";
      if (!isBodyValid || !isTitleValid) {
        message.error("Please fill out all required fields");
      } else {
        const idArray = jobData?.assigned_partners?.filter((el) => el !== "");
        try {
          if (idArray?.length === 0) {
            info();
          } else {
            const res = await SendEmailToPartners({
              emailBody: emailForm?.body,
              partnerAdminIds: idArray,
              emailSubject: emailForm?.subject,
            });
            setEmailModal(false);
            message.success("Email sent successfully ");
          }
        } catch (e) {
          message.error("some error occurred while sending the emails");
          setEmailModal(false);
        }
      }
    };
  
    const handleMessageSubmit = async () => {
      if (!messageForm.body.trim()) {
        message.error("Please enter a message");
        return;
      }
      setLoading(true);
  
      try {
        const idArray = jobData?.assigned_partners?.filter((el) => el !== "");
        if (idArray?.length === 0) {
          message.info(
            "This job doesn't have any assigned partners, please add them first"
          );
          return;
        }
  
        await sendMessageToUsers({
          body: messageForm.body,
          partners: idArray,
          userId: userState.userInfo.id,
        });
  
        setIsMessageModalOpen(false);
        message.success("Message sent successfully");
      } catch (error) {
        console.error("Error sending message:", error);
        message.error("Some error occurred while sending the message");
      } finally {
        setLoading(false);
      }
    };

   const archiveJobHandler = async (id) => {
      if (
        window.confirm("Are you sure you want to archive this job ?") === true
      ) {
        await ArchiveJob(
          {
            id: id,
          },
          dispatch
        );
      }
    };
    
      const unarchiveJobHandler = async (id) => {
        if (
          window.confirm("Are you sure you want to unarchive this job ?") === true
        ) {
          await UnArchiveJob(
            {
              id: id,
            },
            dispatch
          );
        }
      };
      
  const handleshowPdf = async () => {
    try {
      if (jobData?.isZyvkaJdAiEnable || jobData?.jd_urn === "AiGenerated") {
     
        const response = await axios.post(
          `${
            process.env.REACT_APP_SERVER_URL
          }/api/files/jd/generate/${jobData.job_title.split(" ").join("-")}`,
          {
            jobId: jobData?.id,
            name: user?.firstName + " " + user?.lastName,
            mobile: user?.mobileNum,
            email: user?.email,
          },
          {
            responseType: "blob",
            withCredentials: true,
          }
        );

        const url = URL.createObjectURL(response.data);
        window.open(url, "_blank");
        // }
        // else {
        //   console.error("Failed to generate PDF: ", response.statusText);
        // }
      } else {
        const url = `${process.env.REACT_APP_SERVER_URL}/api/files/jd/${jobData.jd_urn}`;
        window.open(url, "_blank");
      }
    } catch (error) {
      console.error("Error generating PDF: ", error);
    } finally {
      setLoading(false); // End loading indicator
    }
  };
  const handleshowPdfResume = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${
          process.env.REACT_APP_SERVER_URL
        }/api/files/resume/generate/${jobData.job_title.split(" ").join("-")}`,
        {
          params: {
            jobID: jobData?.id,
          },
          responseType: "blob",
          withCredentials: true,
        }
      );
      const url = URL.createObjectURL(response.data);
      window.open(url, "_blank");
    } catch (error) {
      console.log(error);
    } finally{
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Content style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spin size="large" tip="Loading job details..." />
      </Content>
    );
  }

  const parseGptOutput = (output) => {
    try {
      return output ? JSON.parse(output) : null;
    } catch (error) {
      console.error('Error parsing GPT output:', error);
      return null;
    }
  };
  
  const parsedGptOutput = parseGptOutput(jobData?.gpt_output);

  const hasPrequalifyingQuestions = parsedGptOutput?.["Pre-Qualifying Questions"]?.length > 0;

  return (
    <Content style={{ maxWidth: 1200, margin: "0 auto", padding: 24 }}>
       {closed === false &&
        archived === false &&
        jobData.status !== "on hold" &&
        jobData.status !== "no more submissions" &&
        jobData.status !== "drafted" && (
          <div style={{
            position: 'fixed',
            right: '36px',
            bottom: '36px',
            zIndex: 1000,
          }}>
          <Tooltip title="Add New Candidate" placement="left">
            <Button
              icon={<UserAddOutlined />}
              type="primary"
              shape="circle"
              size="large"
              style={{
                right: 0,
                bottom: 64,
              }}
              onClick={() => {
                window.open(
                  `/dashboard/referNewApplicant/${jobData.id}/?job_title=${jobData.job_title}`,
                  '_blank'
                );
              }}
            />
          </Tooltip>
          </div>
      )}

    {/* Title Section */}
<Title 
  level={2} 
  style={{ 
    display: "flex", 
    justifyContent: "space-between",
    alignItems: "center", 
    color: "#8C52FF", 
    marginBottom: 24 
  }}
>
  {jobData?.job_title}
  {archived === false ? (
    <Button type="primary" style={{ background: '#8C52FF', border: 'none' }}>
      <Link 
        to={`/job-details/${jobData?.id}`}
        style={jobData?.active_status === 4 ? 
          { color: 'white', background: "red", border: "solid 1px white" } : 
          { color: 'white' }
        }
      >
        Update
      </Link>
    </Button>
  ) : (
    <Button
      style={{ color: "green", border: "solid 1px green" }}
      onClick={() => unarchiveJobHandler(jobData?.id)}
    >
      Unarchive
    </Button>
  )}
</Title>
{jobData?.jdVideo && (
            
              <Card
              style={{ 
                marginBottom: 24, 
                borderRadius: 8, 
                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)', 
                background: 'white' 
              }}
            >
              <div style={{ 
                background: '#8C52FF', 
                margin: '-24px -24px 8px  -24px', 
                padding: '16px 24px', 
                borderRadius: '8px 8px 0 0'
              }}>
                <Text style={{ color: 'white', fontSize: 18, fontWeight: 'bold' }}>
      Video Job Description
    </Text>
    </div>
                <video 
                  controls 
                  style={{ 
                    width: '100%',
                    borderRadius: 8,
                    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)'
                  }}
                >
                  <source
                    src={`${process.env.REACT_APP_SERVER_URL}/api/files/jd/${jobData.jdVideo}`}
                    type=""
                  />
                  Your browser does not support the video tag.
                </video>
               
              </Card>
            
          )}

{/* Key Information Card */}
<Card 
  style={{ 
    marginBottom: 24, 
    borderRadius: 8, 
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)', 
    background: 'white' 
  }}
>
  <div style={{ 
    background: '#8C52FF', 
    margin: '-24px -24px 24px -24px', 
    padding: '16px 24px', 
    borderRadius: '8px 8px 0 0'
  }}>
    <Text style={{ color: 'white', fontSize: 18, fontWeight: 'bold' }}>
      Key Information
    </Text>
  </div>
  <Row gutter={[16, 16]}>
    <Col span={12}>
      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <BankOutlined style={{ color: "#8C52FF" }} />
        <Text strong>Company:</Text> {jobData?.company_name}
      </div>
    </Col>
    <Col span={12}>
      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <TeamOutlined style={{ color: "#8C52FF" }} />
        <Text strong>Total Rounds:</Text> {jobData?.number_of_rounds}
      </div>
    </Col>
    <Col span={12}>
      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <CalendarOutlined style={{ color: "#8C52FF" }} />
        <Text strong>Experience:</Text> {jobData?.min_experience_req} - {jobData?.max_experience_req} Years
      </div>
    </Col>
    <Col span={12}>
      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <DollarCircleOutlined style={{ color: "#8C52FF" }} />
        <Text strong>CTC:</Text> {jobData?.min_CTC} - {jobData?.max_CTC} {jobData?.currencyType}
      </div>
    </Col>
  </Row>
</Card>

{/* Revenue Card */}  {/* Updated Revenue Card using actual data */}
      <Card style={{ marginBottom: 24, borderRadius: 8, boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)' }}>
        <div style={{ background: '#FFD700', margin: '-24px -24px 24px -24px', padding: '16px 24px', borderRadius: '8px 8px 0 0' }}>
          <Text style={{ color: 'white', fontSize: 18, fontWeight: 'bold' }}>Revenue</Text>
        </div>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Text strong>Revenue Model:</Text> {revenueData.RevenueMode || clientRevenueData.RevenueMode}
          </Col>
          <Col span={12}>
            <Text strong>Invoicing Terms:</Text> {revenueData.InvoicingTerms || clientRevenueData.InvoicingTerms}
          </Col>
          <Col span={12}>
            <Text strong>Charge Value:</Text> {revenueData.Value || clientRevenueData.Value}
          </Col>
          <Col span={12}>
            <Text strong>Replacement Timeline:</Text> {revenueData.ReplacementTimeline || clientRevenueData.ReplacementTimeline}
          </Col>
          <Col span={12}>
            <Text strong>Currency:</Text> {revenueData.Currency || clientRevenueData.Currency}
          </Col>
          <Col span={12}>
            <Text strong>Replacement Model:</Text> {revenueData.ReplacementModel || clientRevenueData.ReplacementModel}
          </Col>
          <Col span={12}>
            <Text strong>Payment Terms:</Text> {revenueData.PaymentTerms || clientRevenueData.PaymentTerms}
          </Col>
        </Row>
      </Card>

      {hasPrequalifyingQuestions ? (
        <>
      <div style={{ marginBottom: 24 }}>
        <div style={{ display: 'flex', borderBottom: '1px solid #f0f0f0', marginBottom: 16 }}>
          {tabItems.map((tab) => (
            <div
              key={tab.key}
              style={{
                padding: '12px 24px',
                cursor: 'pointer',
                borderBottom: activeTab === tab.key ? '2px solid #8C52FF' : 'none',
                color: activeTab === tab.key ? '#8C52FF' : '#666',
                fontWeight: activeTab === tab.key ? 'bold' : 'normal'
              }}
              onClick={() => setActiveTab(tab.key)}
            >
              {tab.label}
            </div>
          ))}
        </div>

        <Tabs activeKey={activeTab} renderTabBar={() => <></>}>
          {/* Must-Have Skills */}
          <TabPane key="mustHave">
            <Card style={{ borderRadius: 8, boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)' }}>
              <List
                dataSource={
                  jobData?.job_description 
                    ? extractSection(jobData?.job_description, ["must", "required", "essential", "mandatory"])
                    : []
                }
                renderItem={item => (
                  <List.Item style={{ padding: '12px' }}>{item}</List.Item>
                )}
              />
            </Card>
          </TabPane>

          {/* Good-to-Have Skills */}
          <TabPane key="goodToHave">
            <Card style={{ borderRadius: 8, boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)' }}>
              <List
                dataSource={
                  jobData?.job_description 
                    ? extractSection(jobData?.job_description, ["good", "optional", "bonus", "preferred"])
                    : []
                }
                renderItem={item => (
                  <List.Item style={{ padding: '12px' }}>{item}</List.Item>
                )}
              />
            </Card>
          </TabPane>

          {/* Academic Qualifications */}
          <TabPane key="academic">
  <Card style={{ borderRadius: 8, boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)' }}>
    <Descriptions>
      <Descriptions.Item>
        { jobData.job_description 
            ? extractSection(jobData.job_description, ["academic", "qualification", "degree", "education"]

            ).join(', ')
            : ""
        }
      </Descriptions.Item>
    </Descriptions>
  </Card>
</TabPane>

          {/* Other Requirements Tab */}
          <TabPane key="otherReq">
            <Card style={{ borderRadius: 8, boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)' }}>
              <List
                dataSource={
                  jobData.job_description 
                    ? extractRequirements(jobData.job_description)
                    : []
                }
                renderItem={item => (
                  <List.Item style={{ padding: '12px' }}>{item}</List.Item>
                )}
              />
            </Card>
          </TabPane>


          {/* KPIs */}
          <TabPane key="kpis">
            <Card style={{ borderRadius: 8, boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)' }}>
              <List
                dataSource={
                  jobData.job_description 
                    ? extractSection(jobData.job_description, ["key", "kpi", "performance", "metrics"])
                    : []
                }
                renderItem={item => (
                  <List.Item style={{ padding: '12px' }}>{item}</List.Item>
                )}
              />
            </Card>
          </TabPane>

          {/* JD Tab */}
          {/* <TabPane key="jd">
            <Card style={{ borderRadius: 8, boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)' }}>
              <div dangerouslySetInnerHTML={{ __html: jobData.job_description }} />
            </Card>
          </TabPane> */}
        </Tabs>
      </div>
      <Card style={{ marginBottom: 24, borderRadius: 8, boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)' }}>
        <Title level={4} style={{ marginBottom: 16,color: '#6855f6' }}>Search Keywords</Title>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
          <div>
            <Title level={5}>Position/Role-Specific Keywords:</Title>
            <div style={{ marginTop: '8px' }}>
              {jobData.positional_keyword?.map((keyword, index) => (
                <Tag key={index} color="#8C52FF" style={{ marginBottom: 8, borderRadius: '16px' }}>
                  {keyword.trim()}
                </Tag>
              ))}
            </div>
          </div>
          
          <div>
            <Title level={5}>Experience-Related Keywords:</Title>
            <div style={{ marginTop: '8px' }}>
              {jobData.experience_keyword?.map((keyword, index) => (
                <Tag key={index} color="#FFDE59" style={{ marginBottom: 8, borderRadius: '16px' }}>
                  {keyword.trim()}
                </Tag>
              ))}
            </div>
          </div>
          
          <div>
            <Title level={5}>Other Search Keywords:</Title>
            <div style={{ marginTop: '8px' }}>
              {jobData.other_search_keyword?.map((keyword, index) => (
                <Tag key={index} color="#BD9DFD" style={{ marginBottom: 8, borderRadius: '16px' }}>
                  {keyword.trim()}
                </Tag>
              ))}
            </div>
          </div>
        </div>
      </Card>

      {/* Updated Job Board Keywords */}
      <Card style={{ marginBottom: 24, borderRadius: 8, boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)' }}>
        <Title level={4} style={{ marginBottom: 16,color: '#6855f6' }}>Job Board-Specific Keywords</Title>
        <List
          dataSource={jobData.job_board_keyword || []}
          renderItem={item => (
            <List.Item style={{ padding: '12px' }}>
              <FileTextOutlined style={{ marginRight: 8 }} />
              {item.trim()}
            </List.Item>
          )}
        />
      </Card>
{/* Alternative Job Titles */}
<Card 
  style={{ 
    marginBottom: 24, 
    borderRadius: 8, 
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)', 
    background: 'white' 
  }}
>
  <Title level={4} style={{ marginBottom: 16 ,color: '#6855f6'}}>Alternative Job Titles</Title>
  <List
    dataSource={jobData?.alternate_job_titles || []}
    renderItem={item => (
      <List.Item style={{ padding: '8px 0' }}>
        {item}
      </List.Item>
    )}
  />
</Card>

{/* Target Companies */}
<Card 
  style={{ 
    marginBottom: 24, 
    borderRadius: 8, 
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)', 
    background: 'white' 
  }}
>
  <Title level={4} style={{ marginBottom: 16 ,color: '#6855f6'}}>Target Companies</Title>
  <Row gutter={[16, 16]}>
    {jobData?.target_companies?.map((company, index) => (
      <Col span={6} key={index}>
        <div style={{
          background: '#E1E4EA',
          padding: '16px',
          borderRadius: '8px',
          textAlign: 'center',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          {company}
        </div>
      </Col>
    ))}
  </Row>
</Card>



      {/* Pre-Qualifying Questions Card */}
      <Card 
  title={<span style={{ color: '#6855f6', fontWeight: 'bold', fontSize:"20px" }}>Job Questions</span>}
  style={{ marginBottom: 24, borderRadius: 8, boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)' }}
>
  <List
    dataSource={jobData?.job_questions || []}
    renderItem={item => <List.Item>{item.question}</List.Item>}
  />
</Card>

        </>
      ) : (
        /* Render raw job description for old jobs */
        <Card style={{ marginBottom: 24 }}>
          <div dangerouslySetInnerHTML={{ __html: jobData.job_description }} />
        </Card>
      )}


      {/* Job Contact Information */}
      {(IsZyvkaTeamMember(jobData, userState) ||
  userState?.role === "superAdmin") && (
  <Card  
    title={<span style={{ color: '#6855f6',fontSize:"20px" , fontWeight: 'bold' }}>Job Contact Information</span>}
    style={{ marginBottom: 24, borderRadius: 8, boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)' }}
  >
    <Descriptions column={2}>
      <Descriptions.Item label="Contact Person">
        {jobData.job_incharge.name}
      </Descriptions.Item>
      <Descriptions.Item label="Email">
        {jobData.job_incharge.email}
      </Descriptions.Item>
      <Descriptions.Item label="Phone">
        {jobData.job_incharge.country_code} {jobData.job_incharge.mobileNum}
      </Descriptions.Item>
    </Descriptions>
  </Card>
)}

     <Card style={{ marginTop: "30px" }}>
            <p className="site-description-item-profile-p">Actions</p>
            <Row gutter={[16, 16]}>
              {closed === false &&
                archived === false &&
                jobData.status !== "on hold" &&
                jobData.status !== "no more submissions" &&
                jobData.status !== "drafted"
                 && (
                  <>
                    {/* <Col span={8}>
                      {" "}
                      <Link
                        target="_blank"
                        to={`/dashboard/referNewApplicant/${jobData.id}/?job_title=${jobData.job_title}`}
                      >
                        <Button type="primary" style={{ width: "170px" }}>
                          New Candidate
                        </Button>
                      </Link>
                    </Col> */}
                     <Col span={8}>
              <a
                target="_blank"
                rel="noreferrer"
                // href={`${process.env.REACT_APP_SERVER_URL}/api/files/jd/${data.jd_urn}`}
              >
                <Button
                  type="primary"
                  icon={<DownloadOutlined />}
                  style={{ width: "170px" }}
                  onClick={handleshowPdf}
                >
                  Download JD
                </Button>
              </a>
            </Col>
            {/* {
              data?.jdVideo_urn != "" &&
              <Col span={8}>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`${data?.jdVideo_urn}`}
                >
                  <Button type="primary" icon={<DownloadOutlined />}>
                  Watch JD explanation here:
                  </Button>
                </a>
              </Col>

            } */}
            {(jobData?.isZyvkaJdAiEnable || jobData?.jd_urn === "AiGenerated") && (
              <Col span={8}>
                <a
                  target="_blank"
                  rel="noreferrer"
                  // href={`${process.env.REACT_APP_SERVER_URL}/api/files/jd/${data.jd_urn}`}
                >
                  <Button
                    type="primary"
                    icon={<DownloadOutlined />}
                    style={{ width: "170px" }}
                    onClick={handleshowPdfResume}
                  >
                     Ideal Resume
                  </Button>
                </a>
              </Col>
            )}
                    <Col span={8}>
                      <Link
                        target="_blank"
                        to={`/dashboard/existingApplicant/${jobData.id}/?company_name=${jobData.company_name}&job_title=${jobData.job_title}`}
                      >
                        <Button type="primary" style={{ width: "170px" }}>
                          Refer Existing Candidate
                        </Button>
                      </Link>
                    </Col>
                  </>
                )}
  
              {(IsZyvkaTeamMember(jobData, userState) ||
                userState?.role === "superAdmin") && (
                <>
                  <Col span={8}>
                    <Link to={`/dashboard/myjobs1/${jobData.id}`} target="_blank">
                      <Button type="primary" style={{ width: "170px" }}>
                        View All Candidates
                      </Button>
                    </Link>
                  </Col>
                  <Col span={8}>
                    <Link to={`/clone/${jobData.id}`} target="_blank">
                      <Button type="primary" style={{ width: "170px" }}>
                        Clone Job
                      </Button>
                    </Link>
                  </Col>
                  <Col span={8}>
                    <Button
                      type="primary"
                      style={{ width: "170px" }}
                      onClick={() => {
                        setIsAddNewModalOpen(true);
                      }}
                    >
                      Send Notification
                    </Button>
                  </Col>
                  <Col span={8}>
                    <Button
                      type="primary"
                      loading={loading}
                      style={{ width: "170px" }}
                      onClick={() => setIsMessageModalOpen(!isMessageModalOpen)}
                    >
                      Chat With Partner
                    </Button>
                  </Col>
                  <Col span={8}>
                    <Button
                      type="primary"
                      style={{ width: "170px" }}
                      onClick={() => {
                        setEmailModal(true);
                      }}
                    >
                      Send Email To Partners
                    </Button>
                  </Col>
                  <Col span={8}>
                  <Button 
            type="primary" 
            onClick={handleShowScript}
            style={{ width: "170px" }}
          >
            View Calling Script
          </Button>
                  </Col>
                  {
                  archived === false &&
                   (
                    <Col span={8}>
                      <Button
                        type="primary"
                        style={{ width: "170px" }}
                        onClick={() => archiveJobHandler(jobData?.id)}
                      >
                        Archive
                      </Button>
                    </Col>
                  )}
                </>
              )}
            </Row>
          </Card>
      

      {/* Recruiter Script Modal */}
      {isAddNewModalOpen ? (
        <Modal
          width={700}
          centered
          visible={isAddNewModalOpen}
          onCancel={handleCancelNotificationModal}
          size={jobData?.isZyvkaJdAiEnable ? "fullscreen" : "mini"}
          onClose={() => {
            setIsAddNewModalOpen(false);
          }}
          onOk={handleSubmit}
          open={isAddNewModalOpen}
        >
          <Modal.Header>
            Send Group Notification for {jobData?.job_title}
          </Modal.Header>
          <Modal.Content scrolling>
            <Form onSubmit={handleSubmit}>
              <Form.Input
                type="text"
                fluid
                label="Notification Title"
                name="title"
                required={true}
                value={notiForm?.title}
                onChange={(e) => {
                  setNotiForm({
                    ...notiForm,
                    [e.target.name]: e.target.value,
                  });
                }}
                placeholder="Title"
                width={16}
              />
              <Form.TextArea
                type="text"
                fluid
                label="Notification Text"
                name="body"
                required={true}
                value={notiForm?.body}
                onChange={(e) => {
                  setNotiForm({
                    ...notiForm,
                    [e.target.name]: e.target.value,
                  });
                }}
                placeholder="Enter Notification Message here"
                width={16}
              />
              <Form.Field>
                <label>Notification will be sent to</label>
                <Dropdown
                  placeholder="Select Partners"
                  fluid
                  search
                  multiple
                  selection
                  defaultValue={jobData?.assigned_partners}
                  options={AllPartnerAdmins}
                  name="pending_assignment"
                  disabled={true}
                />
              </Form.Field>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button negative onClick={() => setIsAddNewModalOpen(false)}>
              Cancel
            </Button>
            <Button
              style={{ marginLeft: "30px" }}
              type="primary"
              onClick={handleSubmit}
            >
              Send
            </Button>
          </Modal.Actions>
        </Modal>
      ) : null}
      {isMessageModalOpen && (
        <Modal
          width={700}
          centered
          visible={isMessageModalOpen}
          onCancel={() => setIsMessageModalOpen(false)}
          onOk={handleMessageSubmit}
          open={isMessageModalOpen}
        >
          <Modal.Header>Send Group Message for {jobData?.job_title}</Modal.Header>
          <Modal.Content scrolling>
            <Form onSubmit={handleMessageSubmit}>
              <Form.TextArea
                fluid
                label="Message Text"
                name="body"
                required={true}
                value={messageForm.body}
                onChange={(e) => {
                    setMessageForm({
                    ...messageForm,
                    [e.target.name]: e.target.value,
                    });
                  }}
                  placeholder="Enter Message here"
                  width={16}
                  />
                  <Form.Field>
                  <label>Message will be sent to</label>
                  <Dropdown
                    placeholder="Select Partners"
                    fluid
                    search
                    multiple
                    selection
                    defaultValue={jobData?.assigned_partners}
                    options={AllPartnerAdmins}
                    name="pending_assignment"
                    disabled={true}
                  />
                  </Form.Field>
                </Form>
                </Modal.Content>
                <Modal.Actions>
                <Button negative onClick={() => setIsMessageModalOpen(false)}>
                  Cancel
                </Button>
                <Button
                  style={{ marginLeft: "30px" }}
                  type="primary" 
                  onClick={handleMessageSubmit}
                >
                  Send
                </Button>
                </Modal.Actions>
              </Modal>
              )}
                <Modal 
      closeIcon={false}
      open={isModalVisible} 
      onClose={handleCloseScript} 
      size={window.innerWidth <= 800 ? 'fullscreen' : 'small'}
    >
      <Modal.Header style={{ position: 'relative' }}>
        <div style={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center', 
          width: '100%' 
        }}>
          <div>Recruiter Calling Script</div>
          <CloseCircleOutlined
            name="close" 
            onClick={handleCloseScript} 
            style={{ 
              position: 'absolute', 
              top: 4, 
              fontSize:24,
              right: 4, 
              cursor: 'pointer' 
            }} 
          />
        </div>
      </Modal.Header>
      <Modal.Content>
        <pre style={{ 
          whiteSpace: 'pre-wrap', 
          maxHeight: '60vh', 
          overflow: 'auto' 
        }}>
           <div
    style={{ maxHeight: "60vh", overflow: "auto" }}
    dangerouslySetInnerHTML={{ __html: formatScript(jobData?.recruiter_script) }}
  />
  
        </pre>
      </Modal.Content>
    </Modal>
              
              {emailModal ? (
              <Modal
                closeIcon ={false}
                open={emailModal}
                onClose={() => setEmailModal(false)}
                size="small"
              >
                <Modal.Header>
                Send Group Email to Partners for {jobData?.job_title}
                <CloseCircleOutlined
            name="close" 
            onClick={() => setEmailModal(false)} 
            style={{ 
              position: 'absolute', 
              top: 4, 
              fontSize:24,
              right: 4, 
              cursor: 'pointer' 
            }} 
          />
                </Modal.Header>
                <Modal.Content scrolling>
                <Form onSubmit={handleEmailSubmit}>
                  <Form.Input
                  type="text"
                  fluid
                  label="Email Subject"
                  name="subject"
                  required={true}
                  value={emailForm?.subject}
                  onChange={(e) => {
                  setEmailForm({
                    ...emailForm,
                    [e.target.name]: e.target.value,
                  });
                }}
                placeholder="Title"
                width={16}
              />
              <label style={{ fontWeight: "bolder" }}>Job Description</label>
              <RichTextEditor initialValue="" getValue={getValue} />
              <br />
              <br />
              <br />

              {/* <Form.TextArea
                  type="text"
                  fluid
                  label="Notification Text"
                  name="body"
                  required={true}
                  value={notiForm?.body}
                  onChange={(e) => {
                    setNotiForm({
                      ...notiForm,
                      [e.target.name]: e.target.value,
                    });
                  }}
                  placeholder="Enter Notification Message here"
                  width={16}
                /> */}
              {/* <Form.Field>
                  <label>Notification will be sent to</label>
                  <Dropdown
                    placeholder="Select Partners"
                    fluid
                    search
                    multiple
                    selection
                    defaultValue={jobDetails?.assigned_partners}
                    options={AllPartnerAdmins}
                    name="pending_assignment"
                    disabled={true}
                  />
                </Form.Field> */}
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button negative onClick={() => setEmailModal(false)}>
              Cancel
            </Button>
            <Button
              style={{ marginLeft: "30px", marginBottom: "15px" }}
              type="primary"
              onClick={handleEmailSubmit}
            >
              Send
            </Button>
          </Modal.Actions>
        </Modal>
      ) : null}
    </Content>
  );
};

export default JobDetailsPage;