import React, { useState } from "react";
import { Container, Header, Item, Button, Icon, Modal, Form, Confirm, Message } from "semantic-ui-react";
import axios from "axios";

const QuestionsList = ({ questions, setQuestions }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [questionToDelete, setQuestionToDelete] = useState(null);

  // Function to start editing a question
  const startEdit = (e, id) => {
    e.preventDefault();
    setQuestions(prev =>
      prev.map(q => (q.id === id ? { ...q, editing: true } : q))
    );
  };

  // Function to cancel editing
  const cancelEdit = (e, id) => {
    e.preventDefault();
    setQuestions(prev =>
      prev.map(q => (q.id === id ? { ...q, editing: false } : q))
    );
  };

  // Function to handle question changes
  const handleQuestionChange = (e, id, changes) => {
    e.preventDefault();
    setQuestions(prev =>
      prev.map(q => (q.id === id ? { ...q, ...changes } : q))
    );
  };

  // Function to save a question with API call
  const saveQuestion = async (e, id) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    
    const questionToUpdate = questions.find(q => q.id === id);
    
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/jobs/updateQuestion/${id}`,
        {
          question: questionToUpdate.question,
          questionType: questionToUpdate.questionType
        },
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      
      // Update the questions state with the response data
      setQuestions(prev =>
        prev.map(q => (q.id === id ? { ...response.data, editing: false } : q))
      );
      
    } catch (err) {
      setError(err.response?.data?.message || "Failed to update question");
      console.error("Error updating question:", err);
    } finally {
      setIsLoading(false);
    }
  };

  // Function to initiate question deletion (show confirmation)
  const confirmDelete = (e, id) => {
    e.preventDefault();
    const questionToRemove = questions.find(q => q.id === id);
    setQuestionToDelete(questionToRemove);
    setShowDeleteConfirm(true);
  };
  
  // Function to cancel question deletion
  const cancelDelete = () => {
    setShowDeleteConfirm(false);
    setQuestionToDelete(null);
  };

  // Function to delete a question with API call after confirmation
  const deleteQuestion = async () => {
    if (!questionToDelete) return;
    
    setIsLoading(true);
    setError(null);
    
    try {
      await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/jobs/deleteQuestion/${questionToDelete.id}`,
        {},
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      
      // Remove the question from the state
      setQuestions(prev => prev.filter(q => q.id !== questionToDelete.id));
      setShowDeleteConfirm(false);
      setQuestionToDelete(null);
      
    } catch (err) {
      setError(err.response?.data?.message || "Failed to delete question");
      console.error("Error deleting question:", err);
    } finally {
      setIsLoading(false);
    }
  };

  // Identify the question currently in editing mode.
  const editingQuestion = questions.find(q => q.editing);

  return (
    <div style={{ padding: "0px" }} className="questions-container">
      <Header as="h2" style={{ fontSize: "24px", marginBottom: "20px" }}>
        Questions
      </Header>
      
      {error && (
        <Message negative>
          <p>{error}</p>
        </Message>
      )}
      
      <Item.Group style={{padding:"1rem"}}divided>
        {questions.map((q) => (
          <Item key={q.id} style={{ marginBottom: "15px" }}>
            <Item.Content>
              <Item.Header className="question-header">
                <span className="question-text">{q.question}</span>
                <div className="question-actions">
                  <Button
                    style={{
                      backgroundColor: "blue",
                      color: "white",
                      padding: "5px",
                      marginRight: "5px",
                    }}
                    onClick={(e) => startEdit(e, q.id)}
                    disabled={isLoading}
                    size="small"
                  >
                    <Icon name="edit" style={{ margin: "0px" }} />
                  </Button>
                  <Button
                    style={{
                      backgroundColor: "red",
                      color: "white",
                      padding: "5px",
                    }}
                    onClick={(e) => confirmDelete(e, q.id)}
                    disabled={isLoading}
                    size="small"
                  >
                    <Icon name="trash" style={{ margin: "0px" }} />
                  </Button>
                </div>
              </Item.Header>
            </Item.Content>
          </Item>
        ))}
      </Item.Group>
      
      {/* Edit Modal */}
      {editingQuestion && (
        <Modal
          open={true}
          onClose={(e) => cancelEdit(e, editingQuestion.id)}
          size="small"
        >
          <Modal.Header>Edit Question</Modal.Header>
          <Modal.Content>
            <Form>
              <Form.Input
                label="Question"
                value={editingQuestion.question}
                onChange={(e) =>
                  handleQuestionChange(e, editingQuestion.id, {
                    question: e.target.value,
                  })
                }
                fluid
              />
              <Form.Select
                label="Question Type"
                options={[
                  { key: 0, text: "Numeric", value: 0 },
                  { key: 1, text: "Yes/No", value: 1 },
                  { key: 2, text: "Open text", value: 2 },
                ]}
                value={editingQuestion.questionType}
                onChange={(e, { value }) =>
                  handleQuestionChange(e, editingQuestion.id, {
                    questionType: value,
                  })
                }
                fluid
              />
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button 
              negative 
              onClick={(e) => cancelEdit(e, editingQuestion.id)}
              disabled={isLoading}
            >
              Cancel
            </Button>
            <Button 
              positive 
              onClick={(e) => saveQuestion(e, editingQuestion.id)}
              loading={isLoading}
              disabled={isLoading}
            >
              Save
            </Button>
          </Modal.Actions>
        </Modal>
      )}
      
      {/* Delete Confirmation Dialog */}
      <Confirm
        open={showDeleteConfirm}
        onCancel={cancelDelete}
        onConfirm={deleteQuestion}
        header="Confirm Delete"
        content={questionToDelete ? `Are you sure you want to delete this question: "${questionToDelete.question}"?` : ''}
        confirmButton={<Button negative loading={isLoading} disabled={isLoading}>Delete</Button>}
        cancelButton={<Button disabled={isLoading}>Cancel</Button>}
      />
      
      {/* CSS for responsiveness */}
      <style>
        {`
          .question-header {
            display: flex !important;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            gap: 10px;
          }
          
          .question-text {
            font-size: 18px;
            word-break: break-word;
            flex: 1;
            min-width: 0;
          }
          
          .question-actions {
            display: flex;
            gap: 5px;
          }
          
          /* Responsive styles */
          @media (max-width: 767px) {
            .question-header {
              flex-direction: column;
              align-items: flex-start;
            }
            
            .question-text {
              margin-bottom: 10px;
              width: 100%;
            }
            
            .question-actions {
              align-self: flex-end;
            }
          }
        `}
      </style>
    </div>
  );
};

export default QuestionsList;