import notificationSound from "../assets/chat_notification_sound.mp3";
import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import SceytChatClient from 'sceyt-chat';
import { v4 as uuidv4 } from 'uuid';
import useDidUpdate from '../components/ChatApp/hooks/useDidUpdate';
import { setUnreadCount } from '../features/chatSlice';
import { useDispatch } from 'react-redux';
import { debounce } from 'lodash';

const useChatClient = (userId) => {
  const [chatToken, setChatToken] = useState(null);
  const [client, setClient] = useState(null);
  const [clientState, setClientState] = useState('');
  const prevUnreadCount = useRef(0);
  const dispatch = useDispatch();

  const debouncedDispatch = useRef(
    debounce((count) => {
      dispatch(setUnreadCount(count));
    }, 500)
  ).current;

  const playNotificationSound = () => {
    try {
      // Create a new Audio instance using the imported notification sound file
      const audio = new Audio(notificationSound);
      audio.play();
    } catch (err) {
      console.error('Error playing notification sound:', err);
    }
  };

  const getToken = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/scyetChat/chatToken/${userId}`,
        { withCredentials: true }
      );
      setChatToken(data.chat_token);
    } catch (error) {
      console.error('Error fetching chat token:', error);
    }
  };

  const handleUnreadCountUpdate = (totalUnreadMessageCount) => {
    if (totalUnreadMessageCount > prevUnreadCount.current) {
      playNotificationSound();
    }
    prevUnreadCount.current = totalUnreadMessageCount;
    // Debounced dispatch to prevent rapid state updates
    debouncedDispatch(totalUnreadMessageCount);
  };

  const connectClient = async (token) => {
    const sceytClient = new SceytChatClient(
      'https://us-ohio-api.sceyt.com',
      '93k2e1v42l',
      uuidv4()
    );
    sceytClient.setLogLevel('trace');

    const listener = new sceytClient.ConnectionListener();
    const channelListener = new sceytClient.ChannelListener();

    listener.onConnectionStateChanged = async (status) => {
      setClientState(status);
      if (status === 'Failed') await getToken();
      if (status === 'Connected') {
        try {
          sceytClient.setPresence('online');
        } catch (e) {
          console.error(e);
        }
      }
    };

    listener.onTokenWillExpire = async () => {
      getToken();
    };

    listener.onTokenExpired = async () => {
      if (clientState === 'Connected') {
        getToken();
      } else {
        await getToken();
      }
    };

    channelListener.onTotalUnreadCountUpdated = (
      totalUnreadChannelCount,
      totalUnreadMessageCount,
      channel,
      channelUnreadCount,
      channelUnreadMentions,
      channelUnreadReactions
    ) => {
      handleUnreadCountUpdate(totalUnreadMessageCount);
    };

    sceytClient.addChannelListener('channel_listener_id', channelListener);
    sceytClient.addConnectionListener('listener_id', listener);

    sceytClient.connect(token)
      .then(async () => {
        setClient(sceytClient);
        const channelQueryBuilder = new sceytClient.ChannelListQueryBuilder();
        channelQueryBuilder.order('lastMessage');
        channelQueryBuilder.limit(20);
        const buildChannelQuery = await channelQueryBuilder.build();
        await buildChannelQuery.loadNextPage();

        if (typeof window !== 'undefined' && window.addEventListener) {
          window.addEventListener('offline', (e) => onlineStatusChanged(e, sceytClient));
          window.addEventListener('online', (e) => onlineStatusChanged(e, sceytClient));
        }
      })
      .catch((e) => {
        // If connection fails, try to fetch a new token
        getToken();
      });
  };

  const onlineStatusChanged = (event, clientInstance) => {
    if (event.type === 'online') {
      setTimeout(() => {
        if (clientInstance && (!clientInstance.accessToken || clientInstance.connectionState === 'Disconnected')) {
          getToken();
        }
      }, 1000);
    }
  };

  // Fetch token initially when a userId is available
  useEffect(() => {
    if (!chatToken && userId) getToken();
  }, [userId]);

  // Update or create the client when a new token is available
  useDidUpdate(() => {
    if (chatToken) {
      if (client && clientState === 'Connected') {
        client.updateToken(chatToken);
      } else {
        if (client && chatToken) {
          client.connect(chatToken)
            .then(() => {
              setClientState('Connected');
            })
            .catch((e) => {
              if (e.code === 10005 && client && client.connectionState === 'Connected') {
                setClientState('Connected');
              } else {
                getToken();
              }
            });
        } else {
          connectClient(chatToken);
        }
      }
    }
  }, [chatToken]);

  // Cleanup: cancel the debounced dispatch on unmount
  useEffect(() => {
    return () => {
      if (debouncedDispatch && debouncedDispatch.cancel) {
        debouncedDispatch.cancel();
      }
    };
  }, [debouncedDispatch]);

  return { client, clientState, chatToken };
};

export default useChatClient;
