import { Form, Button, Input } from "semantic-ui-react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { UserState } from "../features/userSlice";
import { ZyvkaMemberState } from "../features/ZyvkaMemberSlice";
import { fetchPartnerList, getAllJobsForCompany } from "../api/partner";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { filterApplicants, getAllClients } from "../api/ZyvkaMember";
import { fetchALlPartnerCompanies, fetchAllSkills, fetchAllCurrentComp } from "../api/auth";
import {
    Table,
    Space,
    Tag,
    Typography,
    DatePicker
} from "antd";
import { ApplicantInJob } from "./ApplicantInJob";
import JobListDrawer from "./JobListDrawer";
import axios from "axios";

const pageSize = 10;

// console.log(userState)
const MyJobList = () => {
    const dispatch = useDispatch();
    const [flag, setFlag] = useState(false);
    const [filterOpen, setFilterOpen] = useState(false);
    const [calls, setCalls] = useState(false);
    const userState = useSelector(UserState);
    const AllSkills = userState.gSkills;
    const AllCompany = userState.gCompany;
    const zyvkaState = useSelector(ZyvkaMemberState);
    const AllClients = zyvkaState.AllClients;
    const [keySkills, setKeySkills] = useState();
    const [current_company, set_current_company] = useState();
    const [not_current_company, set_not_current_company] = useState();
    const [clientName, setClientName] = useState();
    const [compName, setCompName] = useState();
    const [filterData, setFilterData] = useState();
    const [filterFlag, setFilterFlag] = useState(false);
    const [filterState, setFilterState] = useState({
        min_total_experience: undefined,
        max_total_experience: undefined,
        salary_type: undefined,
        max_current_salary: undefined,
        max_expected_salary: undefined,
        max_notice_period: undefined,
        partner_company_name: undefined,
        applicant_date_creation: undefined,
        current_job_status: undefined,
        //current_company: undefined,
        location: undefined,
    })
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(10);
    const [opendrawer, setOpenDrawer] = useState(false);
    const [partnerList, setPartnerList] = useState(null);
    const [searchValue, setSearchValue] = useState(undefined);

    

    const fetchApplicantsByPage = async (page) => {
        setCurrentPage(page);
        try {
            const res = await getAllJobsForCompany(
                {
                    // min_total_experience: filterState?.min_total_experience,
                    // max_total_experience: filterState?.max_total_experience,
                    // max_current_salary: filterState?.max_current_salary,
                    // max_expected_salary: filterState?.max_expected_salary,
                    // max_notice_period: filterState?.max_notice_period,
                    // key_skills: keySkills || [],
                    // current_companies: current_company || [],
                    // notIn_current_companies: not_current_company || [],
                    // client_name: clientName || [],
                    // applicant_date_creation: formatDate(filterState?.applicant_date_creation?._d),
                    // salary_type: filterState?.salary_type,
                    // location: filterState?.location,
                    searchValue: searchValue?.toString().trim(),
                    page: currentPage,
                    companyName: userState?.role === 'partner' ? userState?.userInfo?.companyName : userState?.userInfo?.company_name,
                });
            // setOpenDrawer(false);
            setFilterData(res.data);
            setTotalItems(res?.count || 0);

        } catch (error) {
            console.log(error);
        }
    };
    const fetchAllPartner = async () => {
        try {
            const res = await fetchPartnerList(
                {
                    companyName: userState?.role === 'partner' ? userState?.userInfo?.companyName : userState?.userInfo?.company_name,
                });
            setPartnerList(res)
            console.log(res)

        } catch (error) {
            console.log(error);
        }
    };
    const fetchApplicantsInitial = async () => {
        setCurrentPage(1);
        try {
            const res = await getAllJobsForCompany(
                {
                    page: 1,
                    companyName: userState?.role === 'partner' ? userState?.userInfo?.companyName : userState?.userInfo?.company_name,
                });
            // setOpenDrawer(false);
            setFilterData(res.data);
            setTotalItems(res?.count || 0);
        } catch (error) {
            console.log(error);
        }
    };

    const openJobPage = (jobId) => {
        
        window.open(`/dashboard/jobdetailsPage/${jobId}`, '_blank');
      };
    const fetchAll = async () => {
        try {
            await fetchAllSkills(dispatch);
            await fetchAllCurrentComp(dispatch);
            await getAllClients(dispatch);
        } catch {
            return
        }
    }
    useEffect(() => {
        fetchApplicantsByPage(currentPage);
    }, [filterFlag, calls, flag]);
    useEffect(() => {
        fetchAll();
        fetchApplicantsInitial();
        fetchAllPartner();
    }, []);
    const findAddedBy = (id) => {
        console.log(partnerList?.map((el) => el?.id === id))
        return partnerList?.map((el) => el?.id === id);
    }
    //Table Columns
    const columns = [
        {
            title: "Job Title",
            dataIndex: "job_title",
            key: "id",
            render: (value, record, key) => {
                return (
                    <p >
                        <span style={{
                            fontSize: "15px",
                            fontWeight: "bolder",
                            color: "black"
                        }} >
                            {record?.job_title}
                        </span>
                        <span style={{
                            border: record?.status === "closed" ? "0.15px solid #BC3F33" : (record?.status === "on hold" ? "0.15px solid orange" : (record?.status === "candidate accepted" ? "0.15px solid green" : "0.15px solid #6855f5")),
                            background: record?.status === "closed" ? "#BC3F33" : (record?.status === "on hold" ? "orange" : (record?.status === "candidate accepted" ? " green" : " #6855f5")),
                            color: record?.status === "on hold" ? "black" : "white",
                            borderRadius: "4px", marginLeft: "10px", paddingLeft: "3px", paddingRight: "3px", fontSize: "11px", fontWeight: "medium"
                        }} > {record?.status}
                        </span>
                    </p >
                );
            },
        },
        {
            title: "Client",
            dataIndex: "lastName",
            key: "id",
            render: (value, record, key) => {
                return (
                    <p>{record?.company_name}</p>

                );
            },
        },
        {
            title: "Details",
            key: "id",
            render: (value, record, key) => {
                return (
                    <p>
                        <span style={{
                            fontSize: "15px",
                            fontWeight: "medium",
                            color: "#050505"
                        }}> {record?.country}</span> - <span> {record?.city}</span>
                        <span style={{

                            marginLeft: "10px",
                            border: "0.15px solid #a1045a",
                            background: "#a1045a",
                            color: "white",
                            borderRadius: "4px", paddingLeft: "3px", paddingRight: "3px", fontSize: "11px", fontWeight: "bold"
                        }} >{record?.min_CTC} - {record?.max_CTC}  {record?.currencyType === "INR" ? "LPA ₹" : "K $"}</span>
                        {
                            record?.isRemote ?
                                <span style={{
                                    border: "0.15px solid #333",
                                    background: "#333",
                                    color: "white",
                                    borderRadius: "4px", marginLeft: "10px", paddingLeft: "3px", paddingRight: "3px", fontSize: "10px", fontWeight: "bold"
                                }}>REMOTE</span> : null
                        }

                    </p>

                );
            },
        },
        {
            title: "Skills",
            key: "id",
            render: (value, record, key) => {
                return (
                    <p style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        justifyContent: "start",
                        alignItems: "center"
                    }} >
                        {
                            record?.skills_req?.length > 0 ?
                                (
                                    record?.skills_req?.map((skill, idx) => (
                                        <Tag
                                            key={idx}
                                            color={'#6855F5'}
                                            style={{
                                                padding: "0px 8px 0px 8px",
                                                margin: "2px",
                                                fontSize: "11px",
                                                borderRadius: "5px",
                                                fontWeight: "bold"
                                            }} >{skill}</Tag>
                                    ))
                                )
                                : "-"
                        }
                    </p>

                );
            },
        },
        {
            title: "Job Info",
            dataIndex: "jd_urn",
            key: "id",
            render: (value, record, key) => {
                return (
                    <>
                        <Button onClick={()=>openJobPage(record?.id)} > See Info </Button>
                        {/* <JobListDrawer key={key} opendrawer={opendrawer} id={record?.id} setOpenDrawer={setOpenDrawer} onClose={() => setOpenDrawer(false)} data={record} userState={userState} /> */}
                    </>
                );
            },
        },
    ];

    //Date Formatter
    const formatDate = (date) => {
        if (!date) return null;
        const inputDate = new Date(date);
        const year = inputDate.getUTCFullYear();
        const month = String(inputDate.getUTCMonth() + 1).padStart(2, '0'); // Month is 0-indexed
        const day = String(inputDate.getUTCDate()).padStart(2, '0');
        return `${year}-${month}-${day}T00:00:00.000Z`;
    };
    const onFinish = async (values) => {
        try {
            setCurrentPage(1);
            const res = await getAllJobsForCompany(
                {
                    // min_total_experience: filterState?.min_total_experience,
                    // max_total_experience: filterState?.max_total_experience,
                    // max_current_salary: filterState?.max_current_salary,
                    // max_expected_salary: filterState?.max_expected_salary,
                    // max_notice_period: filterState?.max_notice_period,
                    // key_skills: keySkills || [],
                    // current_companies: current_company || [],
                    // notIn_current_companies: not_current_company || [],
                    // client_name: clientName || [],
                    // applicant_date_creation: formatDate(filterState?.applicant_date_creation?._d),
                    // salary_type: filterState?.salary_type,
                    // location: filterState?.location,
                    searchValue: searchValue?.toString().trim(),
                    page: 1,
                    companyName: userState?.role === 'partner' ? userState?.userInfo?.companyName : userState?.userInfo?.company_name,
                });
            // console.log(res, "result");
            // setOpenDrawer(false);
            setFilterData(res?.data);
            setTotalItems(res?.count || 0);
        }
        catch (e) {
            setOpenDrawer(false);
            console.log(e);
        }
    };
    console.log(filterData, "data")

    const resetFilters = () => {
        setFilterState({
            min_total_experience: undefined,
            max_total_experience: undefined,
            salary_type: undefined,
            max_current_salary: undefined,
            max_expected_salary: undefined,
            max_notice_period: undefined,
            partner_company_name: undefined,
            applicant_date_creation: undefined,
            current_job_status: undefined,
            location: undefined,
            min_score: undefined,
            max_score: undefined,
        });
        setKeySkills([]);
        set_current_company([]);
        set_not_current_company([]);
        setClientName([]);
        // setFilterOpen(false);
        setFilterData(null);
        setSearchValue("");
        setFilterFlag((filterFlag) => !filterFlag); // To refetch data without filters
    };
    return (
        <>
            <div className="">
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        flexWrap: "wrap",
                        gap: "10px",
                        paddingTop: "40px"
                    }}
                >
                    <Space direction="horizontal" wrap={true} style={{ marginBottom: "40px" }} >
                        <Button onClick={() => { setSearchValue(""); fetchApplicantsByPage(1) }}>Reset</Button>
                        <Input
                            placeholder="Search Here"

                            style={{
                                minWidth: 200,
                                maxWidth: 400,
                                paddingLeft: 10,
                            }}
                            onChange={(e) => {
                                setSearchValue(e.target.value);
                            }}
                            value={searchValue}
                        />
                        <Button onClick={() => { fetchApplicantsByPage(1) }} style={{ width: 80, background: "#6855F6", color: "white", fontWeight: "600" }}>Search</Button>
                    </Space>
                </div >



                {/* {
                    filterOpen && (
                        <div style={{ width: "100%", padding: "10px", paddingBottom: "40px" }}>
                            <Form
                                labelCol={{
                                    span: 4,
                                }}
                                layout="horizontal"
                                onSubmit={onFinish}
                            >
                                <Form.Group widths='equal'>
                                    <Form.Input fluid name="min_experience" placeholder="min experience (in years)" type="number" value={filterState?.min_total_experience} onChange={(e) => {
                                        setFilterState({
                                            ...filterState,
                                            min_total_experience: e.target.value,
                                        });
                                    }} style={{ minWidth: "150px", minHeight: "30px", height: "40px" }} />
                                    <Form.Input fluid name="max_experience" placeholder="max experience(in years)" type="number" value={filterState?.max_total_experience} onChange={(e) => {
                                        setFilterState({
                                            ...filterState,
                                            max_total_experience: e.target.value,
                                        });
                                    }} style={{ minWidth: "150px", minHeight: "30px", height: "40px" }} />
                                    <Form.Input name="max_notice_period" placeholder="notice period(in days)" type="number" value={filterState?.max_notice_period} onChange={(e) => {
                                        setFilterState({
                                            ...filterState,
                                            [e.target.name]: e.target.value,
                                        });
                                    }} style={{ minWidth: "150px", minHeight: "30px", height: "40px" }} />
                                    <Form.Select
                                        name="salary_type"
                                        placeholder="salary currency"
                                        value={filterState?.salary_type}
                                        onChange={(e, { value }) => {
                                            setFilterState({
                                                ...filterState,
                                                salary_type: value,
                                            });
                                        }}
                                        selection
                                        options={[
                                            { key: 1, text: "$ USD", value: "USD" },
                                            { key: 2, text: "₹ INR", value: "INR" },  // Corrected value to "INR"
                                            { key: 3, text: "€ EURO", value: "EURO" }, // Corrected value to "EURO"
                                        ]}
                                    />

                                </Form.Group>
                                <Form.Group widths={"equal"} >
                                    <Form.Input name="current_ctc" placeholder="current ctc" type="number" value={filterState?.max_current_salary} onChange={(e) => {
                                        setFilterState({
                                            ...filterState,
                                            max_current_salary: e.target.value,
                                        });
                                    }} style={{ minWidth: "150px", minHeight: "30px", height: "40px" }} />
                                    <Form.Input name="expected_salary" placeholder="expected salary" type="number" value={filterState?.max_expected_salary} onChange={(e) => {
                                        setFilterState({
                                            ...filterState,
                                            max_expected_salary: e.target.value,
                                        });
                                    }} style={{ minWidth: "150px", minHeight: "30px", height: "40px" }} />
                                    <Form.Input
                                        name="location"
                                        value={filterState?.location}
                                        onChange={(e, { value }) => {
                                            setFilterState({
                                                ...filterState,
                                                location: value
                                            })
                                        }}
                                        type="text"
                                        placeholder="City, Country"
                                        fluid
                                    />
                                    <Form.Select
                                        additionLabel={null}
                                        selection
                                        fluid
                                        search
                                        name="key_skills"
                                        placeholder="key skills"
                                        multiple
                                        value={keySkills || []}
                                        onChange={(event, { value }) => { setKeySkills(value) }}
                                        options={
                                            AllSkills?.map((data, idx) => {
                                                return {
                                                    key: idx,
                                                    text: data?.skill,
                                                    value: data?.skill
                                                }
                                            })
                                        }
                                    />
                                </Form.Group>
                                <Form.Group widths={"equal"} >
                                    {
                                        (userState.role !== 'partnerAdmin' && userState.role !== 'partner') ? (
                                            <Form.Select
                                                name="partner_company_name"
                                                placeholder="partner company name"
                                                search
                                                style={{ minWidth: "150px", minHeight: "30px", height: "40px" }}
                                                value={filterState?.partner_company_name}
                                                onChange={(e, { value }) => {
                                                    setFilterState({
                                                        ...filterState,
                                                        partner_company_name: value,
                                                    });
                                                }}
                                                options={
                                                    compName?.map((data, idx) => {
                                                        return { key: idx, value: data?.company_name, text: data?.company_name }
                                                    })
                                                }
                                            />
                                        ) : null
                                    }
                                    <Form.Select
                                        additionLabel={null}
                                        selection
                                        fluid
                                        search
                                        name="current_comapny"
                                        placeholder="companies"
                                        multiple
                                        value={current_company || []}
                                        onChange={(event, { value }) => { set_current_company(value) }}
                                        options={
                                            AllCompany?.map((data, idx) => {
                                                return {
                                                    key: idx,
                                                    text: data?.current_company,
                                                    value: data?.current_company
                                                }
                                            })
                                        }
                                    />
                                    <Form.Select
                                        additionLabel={null}
                                        selection
                                        fluid
                                        search
                                        name="not_current_comapny"
                                        placeholder="exclude these companies"
                                        multiple
                                        value={not_current_company || []}
                                        onChange={(event, { value }) => { set_not_current_company(value) }}
                                        options={
                                            AllCompany?.map((data, idx) => {
                                                return {
                                                    key: idx,
                                                    text: data?.current_company,
                                                    value: data?.current_company
                                                }
                                            })
                                        }
                                    />
                                    <Form.Select
                                        additionLabel={null}
                                        selection
                                        fluid
                                        search
                                        name="clientName"
                                        placeholder="clientName"
                                        multiple
                                        value={clientName || []}
                                        onChange={(event, { value }) => { setClientName(value) }}
                                        options={
                                            AllClients?.map((data, idx) => {
                                                return {
                                                    key: idx,
                                                    text: data?.name,
                                                    value: data?.name
                                                }
                                            })
                                        }
                                    />
                                    <Form.Field>
                                        <DatePicker
                                            fluid
                                            placeholder="created At"
                                            name="created_at"
                                            value={filterState?.applicant_date_creation}
                                            onChange={(e) => {
                                                setFilterState({
                                                    ...filterState,
                                                    applicant_date_creation: e,
                                                });
                                            }}
                                            format="YYYY-MM-DD"
                                            style={{ width: "100%", border: "none", background: "transparent", minHeight: "30px", height: "40px" }}
                                        />
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group widths={"equal"}>
                                </Form.Group>
                                <Form.Group widths={"equal"} style={{ display: "flex", justifyContent: "space-between", }} >
                                    <div style={{ width: "100%", display: "flex", justifyItems: "flex-end", justifyContent: "end", marginRight: "0px", maxHeight: "40px" }}>
                                        <Button type="primary" htmlType="submit" style={{ minWidth: "12%", maxWidth: "200px", marginLeft: "20px", background: "#6855F6", color: "white" }}>
                                            Filter
                                        </Button>
                                        <Button
                                            style={{ minWidth: "12%", maxWidth: "200px", marginLeft: "20px" }}
                                            dashed
                                            type="button"
                                            onClick={() => {
                                                resetFilters();
                                                setFlag((flag) => !flag);
                                                setFilterOpen(false);
                                            }}
                                        >
                                            Reset All Filters
                                        </Button>
                                        <Button
                                            type="button"
                                            style={{ minWidth: "12%", maxWidth: "200px", marginLeft: "20px" }}
                                            onClick={() => {
                                                setOpenDrawer(false);
                                            }}
                                        >
                                            Hide Filters
                                        </Button>
                                    </div>
                                </Form.Group>
                            </Form>
                        </div >
                    )
                } */}

                <Table
                    scroll={{ x: true }}
                    bordered={true}
                    columns={columns}
                    // pagination={false} // Disable default pagination since virtual scrolling is used
                    pagination={
                        {
                            current: currentPage,
                            pageSize: pageSize,
                            total: totalItems,
                            onChange: (page) => {
                                setFlag(!flag);
                                setCurrentPage(page);
                                fetchApplicantsByPage(page);
                            },
                        }
                    }
                    dataSource={filterData}
                    expandable={{
                        expandedRowRender: (record) => (
                            <div key={record?.id} style={{ width: "100%" }}>
                                <ApplicantInJob partnerList={partnerList} setFilterFlag={setFilterFlag} setFlag={setFlag} id={record?.id} data={record?.applications} jobStatus={record?.status} />
                            </div>
                        ),
                    }}
                />
            </div >

        </>
    );
};

export default MyJobList;