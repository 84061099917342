import React, { useState } from 'react'
import { Button, Header, Form, Modal } from 'semantic-ui-react'
const QuestionsForJob = ({ 
  Open, 
  setFormData, 
  formData, 
  onClose,
  isEditing,
  currentQuestion,
  currentQuestionType,
  editingIndex
}) => {
  const [open, setOpen] = React.useState(Open);
  const [quest, setQuest] = useState(isEditing ? currentQuestion : '');
  const [quesType, setQuestType] = useState(isEditing ? currentQuestionType : '');

  const options = [
    { key: 'num', text: 'Numeric', value: '0' },
    { key: 'yn', text: 'Yes/No', value: '1' },
    { key: 'ot', text: 'Open Text', value: '2' },
  ];

  const submitHandler = () => {
    setFormData((prevFormData) => {
      const newQuestions = [...prevFormData.questions];
      const newQuestionType = [...prevFormData.questionType];
      
      if (isEditing) {
        newQuestions[editingIndex] = quest;
        newQuestionType[editingIndex] = quesType;
      } else {
        newQuestions.push(quest);
        newQuestionType.push(quesType);
      }
      
      return {
        ...prevFormData,
        questions: newQuestions,
        questionType: newQuestionType
      };
    });
    onClose();
  };

  return (
    <Modal
      onClose={() => {
        onClose();
        setOpen(false);
      }}
      open={open}
      size="small"
    >
      <Modal.Header>
        {isEditing ? "Edit Question" : "Add Mandatory Question"}
      </Modal.Header>
      <Modal.Content>
        <Form onSubmit={submitHandler}>
          <Form.Select
            fluid
            label='Answer Type'
            options={options}
            required={true}
            placeholder='Type of question'
            value={quesType}
            onChange={(e, { value }) => setQuestType(value)}
          />
          <Form.TextArea 
            required={true} 
            value={quest} 
            onChange={(e, { value }) => setQuest(value)} 
            label='Question' 
            placeholder='Add your question here....' 
          />
          <Button
            style={{
              backgroundColor: "#6855f6",
              color: "#ffffff",
              marginTop: "10px",
            }}
            type="submit"
          >
            {isEditing ? "Update Question" : "Add Question"}
          </Button>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button color='red' onClick={() => {
          onClose();
          setOpen(false);
        }}>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
export default QuestionsForJob