import React, { useEffect, useState } from 'react';
import SceytChatClient from "sceyt-chat";
import { v4 as uuidv4 } from 'uuid';
import {
  ChannelDetails,
  ChannelList,
  Chat,
  ChatHeader,
  MessageList,
  MessagesScrollToBottomButton,
  SceytChat,
  SendMessage,
} from 'sceyt-chat-react-uikit';
import lightModeIcon from './assets/svg/lightModeIcon.svg';
import darkModeIcon from './assets/svg/darkModeIcon.svg';
import sceytIcon from './assets/img/sceyt_rounded.png';
import './ChatApp.css';
import { genToken } from "./api";
import { SceytContext, SceytProvider } from './sceytContext';
import ChannelCustomList from "./ChannelCustomList";
import CreateChannelButton from "./CreateChannel";
import AddNewMembers from "./AddNewMember";
import useDidUpdate from "./hooks/useDidUpdate";
import useMobileView from "./hooks/useMobileView";
import CustomMessageItem from './CustomMessageItem';
import { UserState } from "../../features/userSlice"
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { selectClient, setUnreadCount } from '../../features/chatSlice';

const MOBILE_ACTIVE_VIEW = {
  CHANNELS: 'channels',
  CHAT: 'chat'
};

function ChatComponent({client,token}) {
  const user = useSelector(UserState);
  // const [client, setClient] = useState(null);
   const dispatch =useDispatch();
  const [clientState, setClientState] = useState('');
  // const [chatToken, setChatToken] = useState(null);
  const [theme, setTheme] = useState('light');
  const id = user?.userInfo?.id;
  const [userId, setUserId] = useState(id || '');
  const [rolesMap, setRolesMap] = useState(null);
  const isMobile = useMobileView();
  const [mobileActiveView, setMobileActiveView] = useState(MOBILE_ACTIVE_VIEW.CHANNELS);
  const [activeChannel, setActiveChannel] = useState(null);
  const [users, setUsers] = useState([]);
  const partnerAdminId = user?.userInfo?.partnerAdminId;
  // const client=useSelector(selectClient);
  // const token=useSelector(selectClient);
// console.log("here are we getting or not the token",token,client)


  const activeChannelIsChanged = (channel) => {
    if (channel && channel.id) {
      setActiveChannel(channel);
      setMobileActiveView(MOBILE_ACTIVE_VIEW.CHAT);
    }
  };
  const handleBackToChannels = () => {
    setMobileActiveView(MOBILE_ACTIVE_VIEW.CHANNELS);
  };

 
 
  // const getToken = async() => {
  //   const data = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/scyetChat/chatToken/${userId}`, {withCredentials: true});
  //   setChatToken(data.data.chat_token);
  // };

  // const connectClient = (token) => {
  //   // const sceytClient = new SceytChatClient('https://us-ohio-api.sceyt.com', '93k2e1v42l', uuidv4());
  //   const sceytClient=client;
  //   sceytClient.setLogLevel('trace');
    
    // const listener = new sceytClient.ConnectionListener();
    
    // listener.onConnectionStateChanged = async (status) => {
    //   setClientState(status);
    //   if (status === 'Failed') {
    //     await getToken();
    //   } else if (status === 'Connected') {
    //     try {
    //       sceytClient.setPresence('online');
    //     } catch (e) {
    //       // Handle error
    //     }
    //   }
    // };

    // listener.onTokenWillExpire = async () => {
    //   getToken();
    // };

    // listener.onTokenExpired = async () => {
    //   if (clientState === 'Connected') {
    //     getToken();
    //   } else {
    //     await getToken();
    //   }
    // };

    

     // Create a new ChannelListener to watch channel updates
  //   const channelListener = new sceytClient.ChannelListener();
    
  //   channelListener.onTotalUnreadCountUpdated = (
  //       totalUnreadChannelCount,
  //       totalUnreadMessageCount,
  //       channel,
  //       channelUnreadCount,
  //       channelUnreadMentions,
  //       channelUnreadReactions
  //   ) => {
  //       // Log the total unread count for all channels
  //       console.log('Total Unread Channels:', totalUnreadChannelCount);
  //       console.log('Total Unread Messages:', totalUnreadMessageCount);

  //       // Log unread count for the currently selected channel
  //       console.log('Active Channel:', channel);
  //       console.log('Unread Messages in Channel:', channelUnreadCount);
  //       console.log('Unread Mentions:', channelUnreadMentions);
  //       console.log('Unread Reactions:', channelUnreadReactions);
  //   };

  //   // Register the listener to get real-time unread count updates
  //   sceytClient.addChannelListener('channel_listener_id', channelListener);


 

  //   sceytClient.addConnectionListener('listener_id', listener);
    
  //   sceytClient.connect(token)
  //     .then(async () => {
  //       setClient(sceytClient);
  //       const channelQueryBuilder = new sceytClient.ChannelListQueryBuilder();
  //       channelQueryBuilder.order('lastMessage');
  //       channelQueryBuilder.limit(20); // Fetch latest 20 channels, max 50
  //       const buildChannelQuery = await channelQueryBuilder.build();
  //       await buildChannelQuery.loadNextPage();
        
  //       if (typeof window !== 'undefined' && window != null && window.addEventListener != null) {
  //         window.addEventListener('offline', (e) => onlineStatusChanged(e, sceytClient));
  //         window.addEventListener('online', (e) => onlineStatusChanged(e, sceytClient));
  //       }
  //     })
  //     .catch((e) => {
  //       const date = new Date();
  //       console.error(`${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()} : Error on connect ... `, e);
  //       getToken();
  //     });
  // };
   
  // const onlineStatusChanged = (event, client) => {
  //   const date = new Date();
  //   if (event.type === 'online') {
  //     setTimeout(() => {
  //       if (client && (!client.accessToken || client.connectionState === 'Disconnected')) {
  //         getToken();
  //       }
  //     }, 1000);
  //   }
  // };
  

  // useDidUpdate(() => {
  //   if (chatToken) {
  //     if (client && clientState === 'Connected') {
  //       client.updateToken(chatToken);
  //     } else {
  //       if (client && chatToken) {
  //         client.connect(chatToken)
  //           .then(() => {
  //             setClientState('Connected');
  //           })
  //           .catch((e) => {
  //             if (e.code === 10005 && client && client.connectionState === 'Connected') {
  //               setClientState('Connected');
  //             } else {
  //               getToken();
  //             }
  //           });
  //       } else {
  //         connectClient(chatToken);
  //       }
  //     }
  //   }
  // }, [chatToken]);
  
     

  // useEffect(() => {
  //   if (!chatToken && userId) {
  //     getToken();
  //   }
  // }, [userId]);

  // useEffect(() => {
  //   if (client && clientState === 'Connected') {
  //     client
  //       .getRoles()
  //       .then((roles) => {
  //         const rolesMap = {};
  //         roles.forEach((role) => {
  //           rolesMap[role.name] = role.permissions;
  //         });
  //         setRolesMap(rolesMap);
  //       })
  //       .catch((e) => {
  //         console.log('error on get roles', e);
  //       });
  //   }
  // }, [client]);

  useEffect(() => {
    const applyCustomStyles = () => {
      const chatContainer = document.querySelector('#sceyt_chat_container');
      
      const jvLaETElement = document.querySelector('.sc-ktPPKK.kgcpxH');
      const jvLaETElementlocal = document.querySelector('.sc-ktPPKK.jvLaET');

      const HnrNqElement = document.querySelector('.HnrNq');
      const bOjauxElement = document.querySelector('.sc-jMakVo.fTFExG');
      const bOjauxElementlocal = document.querySelector('.sc-jMakVo.bOjaux');
      
      if (chatContainer) {
        chatContainer.style.minWidth = window.innerWidth > 760 ? '1000px' : '100%';
      }
      
      if (jvLaETElement) {
        jvLaETElement.style.backgroundColor = 'rgba(104, 85, 246, 0.05)';
      }
      if (jvLaETElementlocal) {
        jvLaETElementlocal.style.backgroundColor = 'rgba(104, 85, 246, 0.05)';
      }
      
      if (HnrNqElement) {
        HnrNqElement.style.color = 'black';
      }
      
      if (bOjauxElement) {
        bOjauxElement.style.backgroundColor = 'rgba(104, 85, 246, 0.23)';
        bOjauxElement.style.color = 'black';

      }
      if (bOjauxElementlocal) {
        bOjauxElementlocal.style.backgroundColor = 'rgba(104, 85, 246, 0.23)';
        bOjauxElementlocal.style.color = 'black';
      }
    };

    const styleCheckInterval = setInterval(applyCustomStyles, 100);
    window.addEventListener('resize', applyCustomStyles);

    return () => {
      clearInterval(styleCheckInterval);
      window.removeEventListener('resize', applyCustomStyles);
    };
  }, []);

  useEffect(() => {
    const hideModal = () => {
      const addMemberModals = document.querySelectorAll('.sc-guJBdh.kFGYve');
       const addMemberModalslocal = document.querySelectorAll('.sc-guJBdh.fBEcCy'); 
      addMemberModals.forEach(modal => {
        const heading = modal.querySelector('h3');
        if (heading && heading.textContent === 'Members') {
          modal.style.display = 'none';
        }
      });

      addMemberModalslocal.forEach(modal => {
        const heading = modal.querySelector('h3');
        if (heading && heading.textContent === 'Members') {
          modal.style.display = 'none';
        }
      });
    };
   
  
    const modalCheckInterval = setInterval(hideModal, 100);
    const observer = new MutationObserver(hideModal);
    
    observer.observe(document.body, {
      childList: true,
      subtree: true
    });
  
    return () => {
      clearInterval(modalCheckInterval);
      observer.disconnect();
    };
  }, []);

  const contextValue = {
    client,
    theme,
    activeChannel,
    setActiveChannel,
    users,
    setUsers
  };

  return (
    <div className="main">
      <SceytContext.Provider value={contextValue}>
        <div className='messenger_demo_wrapper'>
          <div className='sceyt_chat_wrapper'>
            {client ? (
              <SceytChat
                themeMode={theme}
                theme={{
                  colors: {
                    accent: {
                      dark: '#6B72FF',
                      light: '#5159F6'
                    },
                    textPrimary: {
                      dark: '#ffffffcc',
                      light: '#111539'
                    }
                  },
                }}
                showNotifications={false}
                customColors={{ primaryColor: '#5159F6' }}
                client={client}
              >
                {(!isMobile || mobileActiveView === MOBILE_ACTIVE_VIEW.CHANNELS) && (
                  <ChannelList
                    List={(props) => <ChannelCustomList {...props} currentUserId={id} userRole={user?.role} activeChannelIsChanged={activeChannelIsChanged} />}
                    CreateChannel={<CreateChannelButton partnerAdminId={partnerAdminId} userRole={user?.role} userId={user?.userInfo?.id} />}
                    backgroundColor={'#1B1C25'}
                    searchInputBackgroundColor={'#25262E'}
                    selectedChannelBackground={'#25262E'}
                    searchInputTextColor={'#ffffffcc'}
                    ChannelsTitle={<div className={`channels_title ${theme} dark`}> Chats</div>}
                  />
                )}
                {(!isMobile || mobileActiveView === MOBILE_ACTIVE_VIEW.CHAT) && (
                  <Chat className='custom_chat'>
                    <ChatHeader mobileBackButtonClicked={handleBackToChannels} />
                    <MessageList
                      reactionsContainerBackground={'inherit'}
                      reactionsContainerBoxShadow={'inherit'}
                      reactionsContainerPadding={'0 0 4px'}
                      reactionItemPadding={'5px 10px'}
                      ownMessageOnRightSide={true}
                      showSenderNameOnOwnMessages
                      showSenderNameOnDirectChannel
                      showOwnAvatar
                      incomingMessageBackground='inherit'
                      ownMessageBackground='inherit'
                      showMessageTimeAndStatusOnlyOnHover
                      reportMessage={false}
                      replyMessageInThread={false}
                      CustomMessageItem={(props) => (
                        <CustomMessageItem {...props} client={client} rolesMap={rolesMap} />
                      )}
                      fileAttachmentsBoxWidth={isMobile ? 220 : undefined}
                      imageAttachmentMaxWidth={isMobile ? 220 : undefined}
                      imageAttachmentMaxHeight={isMobile ? 200 : undefined}
                      videoAttachmentMaxWidth={isMobile ? 220 : undefined}
                      videoAttachmentMaxHeight={isMobile ? 200 : undefined}
                    />
                    <MessagesScrollToBottomButton bottomPosition={65} rightPosition={4} />
                    <SendMessage
                      margin='30px 0 10px -1px'
                      inputPaddings='6px 0'
                      backgroundColor='inherit'
                      emojiIcoOrder={1}
                      inputCustomClassname='sceyt_send_message_input'
                    />
                  </Chat>
                )}
                <ChannelDetails 
                  size='small' 
                  avatarAndNameDirection='column' 
                  showDeleteChannel 
                  showMuteUnmuteNotifications={false}
                  addMemberIcon={
                    <AddNewMembers 
                      partnerAdminId={partnerAdminId} 
                      userRole={user?.role} 
                      userId={user?.userInfo?.id} 
                    />
                  } 
                />
              </SceytChat>
            ) : (
              <div className='messenger_loading'>
                <img src={sceytIcon} alt="sceyt logo" />
              </div>
            )}
          </div>
        </div>
      </SceytContext.Provider>
    </div>
  );
}

export default ChatComponent;