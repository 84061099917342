import axios from "axios";

import React, { useState, useEffect } from "react";
import {
    Card,
    Avatar,
    Typography,
    Button,
    Descriptions,
    Tag,
    Space,
    message,
    Tooltip,
    Popconfirm
} from "antd";
import countryCode, { defaultcCode } from "../Data/CountryCode";

import {
    UserOutlined,
    LinkedinFilled,
    GlobalOutlined,
    EditOutlined,
    DownloadOutlined
} from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { Modal, Form, Icon } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import {
    fetchAllSkills,
    getAllJobProfiles,
    getupdateByID,
    updateCanidateDetails,
} from "../api/auth";
import { gProfileOptions, sourcingVerticalOptions, UserState } from "../features/userSlice";
import { countryCodeSemantic } from "../Data/CountryCode";
import Currency, {
    ctcMap,
    CurrencySymbols,
    defaultCurrency,
} from "../Data/Currency";
import ErrorMessage from "../Error/ErrorMessage";
import SuccessMessage from "../Small-Components/SuccessMessage";
import { Menu, Table } from 'semantic-ui-react';
import { EyeOutlined } from "@ant-design/icons";
import { email } from "../features/clientSlice";
import { getAllClients, getClientFromId, getGeogrpahyData, getIndustryData, getPartnerFromId, getPrimarySkillsData, getVerticalSourcingData, updateClient, updateClientContactPerson, updatePartnerContractFromDetailsPage, updatePartnerFromDetailsPage } from "../api/ZyvkaMember";
import { ZyvkaMemberState } from "../features/ZyvkaMemberSlice";
import { useLocation } from 'react-router-dom';

const { Title, Paragraph } = Typography;
const { Column } = Table;
const API = axios.create({ baseURL: process.env.REACT_APP_SERVER_URL });


const PartnerDetails = () => {
    const dispatch = useDispatch();
    const userState = useSelector(UserState);
    const zyvkaState = useSelector(ZyvkaMemberState);
    const { id } = useParams();
    const [flag, setFlag] = useState(false);
    const [partnerUpdateModalOpen, setPartnerUpdateModalOpen] = useState(false);
    const [update, setUpdate] = useState({});
    const [countryOption, setCountryOption] = useState([]);
    const [stateOption, setStateOption] = useState([]);
    const [cityOption, setCityOption] = useState([]);
    const [state, setState] = useState(null);
    const [city, setCity] = useState();
    const [country, setCountry] = useState("");
    const [prevstate, setPrevstate] = useState();
    const [prevcity, setPrevity] = useState();
    const [prevcountry, setPrevcountry] = useState("");
    const [updateUser, setUpdateUser] = useState({});
    const [geoOptions, setGeoOptions] = useState()
    const [industryOptions, setIndustryOptions] = useState([])
    const [primarySkillsOptions, setPrimarySkillsOptions] = useState([])
    const fetchPrimarySkills = async () => {
        try {
            const res = await getPrimarySkillsData();
            setPrimarySkillsOptions(res?.primarySkills);
        } catch (e) {
            console.error(e)
        }
    }
    const fetchIndustryData = async () => {
        try {
            const res = await getIndustryData();
            setIndustryOptions(res?.industry);
        } catch (e) {
            console.error(e)
        }
    }
    const salaryOptions = [
        { id: 1, text: "0-10 Lac", value: "0-10" },
        { id: 2, text: "10-20 Lac", value: "10-20" },
        { id: 3, text: "20-30 Lac", value: "20-30" },
        { id: 4, text: "40+ Lac", value: "40+" },
    ];
    const location = useLocation();
    const [openContractModal, setOpenContractModal] = useState(false)
    const changeHandler = () => {

    }
    const [partnerUpdate, setPartnerUpdate] = useState({
        firstName: update?.firstName,
        lastName: update?.lastName,
        profile_info: update?.profile_info,
        company_name: update?.company_name,
        country_code: update?.country_code,
        mobileNum: update?.mobileNum,
        email: update?.email,
        password: update?.password,
        address: update?.address,
        type: update?.type,
        Skill: update?.Skill,
        incorp_date: update?.incorp_date,
        partner_cat: update?.partner_cat,
        global_op: update?.global_op,
        linkedIn_URL: update?.linkedIn_URL,
        disabled: update?.disabled,
        pfp_urn: update?.pfp_urn,
        isFreelancer: update?.isFreelancer,
        isAssociatePartnerAdmin: update?.isAssociatePartnerAdmin,
        typeOfHiring: update?.typeOfHiring,
        sourcingVertical: update?.sourcingVertical,
        remarks: update?.remarks,
        lastConnectDate: update?.lastConnectDate,
        geography: update?.geography,
        remakrs: update?.remarks,
        industry: update?.industry,
        primary_roles: update?.primaryRoles,
        salary: update?.salaryBrackets
    })
    const [fileObj, setFileObj] = useState()
    const verticlalOptions = useSelector(sourcingVerticalOptions)
    const rolesOptions = useSelector(gProfileOptions)

    const Positions_available = [
        { id: 1, text: "Yes", value: true },
        { id: 2, text: "No", value: false }
    ];
    const hiringOptions = [
        { id: 1, text: "Tech", value: "Tech" },
        { id: 2, text: "Non-Tech", value: "Non-Tech" },
        { id: 2, text: "Both", value: "Both" }
    ];
    function formatDateToYYYYMMDD(originalDateString) {
        const originalDate = new Date(originalDateString);
        // Get the individual components
        const year = originalDate.getFullYear();
        const month = (originalDate.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed
        const day = originalDate.getDate().toString().padStart(2, '0');

        // Format the date as "yyyy-mm-dd"
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
    }
    const contractUpdateHandler = async () => {
        try {
            if (!fileObj) {
                message.error('Please upload contract')
            } else {
                const formdata = new FormData()
                formdata.append("file", fileObj)
                formdata.append("oldFile", update?.clientContract)
                const formvalue = { id };
                formdata.append("formvalue", JSON.stringify(formvalue))
                const res = await updatePartnerContractFromDetailsPage(formdata, dispatch)
                setFlag((flag) => !flag);
                setOpenContractModal(false);
            }
        } catch (e) {
            console.log(e)
        }
    }
    const fetchCountry = async () => {
        try {
            const result = await axios.get(
                "https://api.countrystatecity.in/v1/countries",
                {
                    headers: {
                        "X-CSCAPI-KEY":
                            "eVNMbzF6NEVPNFFld1IyMXlvVlN5OVpMaExVWFN1RG82elc2SFBscg==",
                    },
                }
            );
            setCountryOption(result.data);
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        const getVerticalData = async () => {
            await getVerticalSourcingData(dispatch)
            await getAllJobProfiles(dispatch);
            const res = await getGeogrpahyData();
            setGeoOptions(res?.geography);
        }
        const fetchClient = async () => {
            const res = await getPartnerFromId(dispatch, { id: id });
            setUpdate(res);
            setPartnerUpdate(res)
            // setPrevcountry(res?.country);
            // setPrevity(res?.city);
            // setPrevstate(res?.state);
            // if (res?.revenue?.length === 0) {
            //     setIsDefaultRevenue(true);
            // }
        };
        fetchClient();
        getVerticalData()

    }, [dispatch, flag]);
    useEffect(() => {
        fetchCountry();
        fetchPrimarySkills()
        fetchIndustryData()
    }, []);
    useEffect(() => {
        console.log(location, "data here")
        if (location.state && location.state.showModal) {
            setPartnerUpdateModalOpen(true);
            console.log("partner udpte is truw")
        } else {
            console.log("working")
        }
    }, [location.state]);


    const handleOkClientUpdate = async () => {
        try {
            const formvalue = {
                id: partnerUpdate?.id,
                firstName: partnerUpdate?.firstName,
                lastName: partnerUpdate?.lastName,
                profile_info: partnerUpdate?.profile_info,
                company_name: partnerUpdate?.company_name,
                country_code: partnerUpdate?.country_code,
                mobileNum: partnerUpdate?.mobileNum,
                email: partnerUpdate?.email,
                address: partnerUpdate?.address,
                linkedIn_URL: partnerUpdate?.linkedIn_URL,
                isFreelancer: partnerUpdate?.isFreelancer,
                isAssociatePartnerAdmin: partnerUpdate?.isAssociatePartnerAdmin,
                typeOfHiring: partnerUpdate?.typeOfHiring,
                sourcingVertical: partnerUpdate?.sourcingVertical,
                remarks: partnerUpdate?.remarks,
                lastConnectDate: partnerUpdate?.lastConnectDate,
                geography: partnerUpdate?.geography,
                rolesFulfilled: partnerUpdate?.rolesFulfilled,
                primary_roles: partnerUpdate?.primary_roles,
                salary: partnerUpdate?.salary,
                industry: partnerUpdate?.industry
            }
            await updatePartnerFromDetailsPage(
                formvalue,
                dispatch,
            )
            setFlag((flag) => !flag)
        } catch (E) {
            console.log(E)
        }
        setPartnerUpdateModalOpen(false)
    }
    const clientType = [
        { id: 1, text: "Hot", value: "Hot" },
        { id: 2, text: "Warm", value: "Warm" },
        { id: 3, text: "Cold", value: "Cold" },
    ]
    const formattedUrl = (url) => url && !url?.includes('://') ? `https://${url}` : url
    return (
        <>
            <br></br>
            <div>
                <Card
                    loading={userState?.isPending}
                    style={{
                        width: "100%",
                    }}
                    title={
                        <div className="applicant-header">
                            <Avatar
                                size={{ xs: 50, sm: 50, md: 50, lg: 50, xl: 50, xxl: 60 }}
                                style={{ backgroundColor: "#6855f6" }}
                                icon={<UserOutlined />}
                            />

                            <Title style={{ display: "flex", justifyContent: "center", alignItems: "center" }} level={2}>
                                {update?.firstName + "  " + update?.lastName}
                                {
                                    update?.partners ?
                                        <span style={{
                                            border: "0.15px solid #6855f5",
                                            background: "#6855f5",
                                            color: "white",
                                            borderRadius: "4px",
                                            marginLeft: "10px",
                                            paddingLeft: "10px",
                                            paddingRight: "10px",
                                            paddingTop: "3px",
                                            paddingBottom: "3px",
                                            fontSize: "13px",
                                            fontWeight: "bold"
                                        }} >Partner Admin</span>
                                        :
                                        <span style={{
                                            border: "0.15px solid orange",
                                            background: "orange",
                                            color: "white",
                                            borderRadius: "4px",
                                            marginLeft: "10px",
                                            paddingLeft: "10px",
                                            paddingRight: "10px",
                                            paddingTop: "3px",
                                            paddingBottom: "3px",
                                            fontSize: "13px",
                                            fontWeight: "bold"
                                        }} >Partner</span>
                                }
                                {
                                    update?.isFreelancer &&
                                    <span style={{
                                        border: "0.15px solid #BC3F33",
                                        background: "#BC3F33",
                                        color: "white",
                                        borderRadius: "4px",
                                        marginLeft: "10px",
                                        paddingLeft: "10px",
                                        paddingRight: "10px",
                                        paddingTop: "3px",
                                        paddingBottom: "3px",
                                        fontSize: "13px",
                                        fontWeight: "bold"
                                    }} >Freelancer</span>
                                }
                            </Title>
                        </div>
                    }
                    extra={
                        <div className="applicant-header-extra">
                            <Icon
                                name="edit outline"
                                onClick={() => {
                                    if (update?.partners) {
                                        setPartnerUpdateModalOpen(true)
                                    } else {
                                        message.info("Editing Partners are Disabled")
                                    }
                                }}
                                style={{
                                    cursor: "pointer", fontSize: "20px"
                                }}
                            ></Icon>
                            <a href={formattedUrl(update?.linkedIn_URL)} target="_blank" rel="noreferrer">
                                <LinkedinFilled
                                    style={{
                                        fontSize: "40px",
                                        color: "rgb(75,79,181)",
                                        cursor: "pointer",
                                    }}
                                />
                            </a>
                            <a href={update?.partnerContract ? `${update?.partnerContract?.split(" ").length > 1
                                ? update?.resume_urn?.split(" ")[1] : `${process.env.REACT_APP_SERVER_URL}/api/files/jd/${update?.partnerContract}`}` : "#"
                            }
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Tooltip title={!update?.partnerContract ? "No contract available.Please upload one." : ""} >
                                    <Button
                                        type="primary"
                                        disabled={!update?.partnerContract}
                                    >
                                        <Icon
                                            name="download"
                                            onClick={() => setOpenContractModal(true)}
                                            style={{
                                                cursor: "pointer", fontSize: "12px"
                                            }}
                                        ></Icon>

                                        Download Contract
                                    </Button>
                                </Tooltip>
                            </a>
                            {/* <Tooltip title={!update?.clientContract ? "No contract available.Please upload one first." : ""} >
                                <Button
                                    // onClick={openContractModal}
                                    disabled={!update?.clientContract}
                                >
                                    <Icon
                                        name="edit outline"
                                        onClick={() => setOpenContractModal(true)}
                                        style={{
                                            cursor: "pointer", fontSize: "20px"
                                        }}
                                    ></Icon>
                                    <span>
                                        Edit Contract
                                    </span>
                                </Button>
                            </Tooltip> */}
                            <Tooltip
                                title={!update?.partnerContract ? "No contract available.Upload new contract." : "Contract already exists.Edit contract"}
                            >
                                <Button
                                    primary
                                    type="primary"
                                    onClick={() => setOpenContractModal(true)}
                                // disabled={!update?.partnerContract}
                                >
                                    {
                                        update?.partnerContract ?
                                            <Icon
                                                name="edit"
                                                onClick={() => setOpenContractModal(true)}
                                                style={{
                                                    cursor: "pointer", fontSize: "12px"
                                                }}
                                            ></Icon> :
                                            <Icon name="upload"
                                                onClick={() => setOpenContractModal(true)}
                                                style={{
                                                    cursor: "pointer", fontSize: "12px"
                                                }} ></Icon>
                                    }

                                    <span>
                                        {
                                            !update?.partnerContract ? "Upload " : "Edit "
                                        }
                                        Contract
                                    </span>
                                </Button>
                            </Tooltip>
                        </div>
                    }
                >
                    <div className="applicant-details-content">
                        <Descriptions title="" layout="vertical" bordered>
                            <Descriptions.Item label="Email">
                                {update?.email || "—"}
                            </Descriptions.Item>
                            <Descriptions.Item label="Mobile" >
                                {update?.country_code} {update?.mobileNum}
                            </Descriptions.Item>
                            <Descriptions.Item label="Comany Name" >
                                {update?.company_name || "—"}
                            </Descriptions.Item>
                            <Descriptions.Item label="Type of Hiring" span={2} >
                                {update?.typeOfHiring || "—"}
                            </Descriptions.Item>
                            <Descriptions.Item label="Freelancer ?" span={1} >
                                {update?.isFreelancer === true ? "Yes" : "No"}
                            </Descriptions.Item>
                            <Descriptions.Item label="ShowOnlyAssignedPosition" span={1} >
                                {update?.isAssociatePartnerAdmin === true ? "Yes" : "No"}
                            </Descriptions.Item>
                            <Descriptions.Item label="Sourcing Verticals" span={3}>
                                {update?.sourcingVertical?.map((data, idx) => {
                                    return <Tag key={idx}>{data}</Tag>;
                                })}
                            </Descriptions.Item>
                            <Descriptions.Item label="Roles Fulfilled" span={3}>
                                {update?.rolesFulfilled?.map((data, idx) => {
                                    return <Tag key={idx}>{data}</Tag>;
                                })}
                            </Descriptions.Item>
                            <Descriptions.Item label="Geography" span={3}>
                                {update?.geography?.map((data, idx) => {
                                    return <Tag key={idx}>{data}</Tag>;
                                })}
                            </Descriptions.Item>
                            <Descriptions.Item label="Primary Roles" span={3}>
                                {update?.primaryRoles?.map((data, idx) => {
                                    return <Tag key={idx}>{data}</Tag>;
                                })}
                            </Descriptions.Item>
                            <Descriptions.Item label="Client Industry" span={3}>
                                {update?.industry?.map((data, idx) => {
                                    return <Tag key={idx}>{data}</Tag>;
                                })}
                            </Descriptions.Item>
                            <Descriptions.Item label="Salary Bracket" span={3}>
                                {update?.salaryBrackets?.map((data, idx) => {
                                    return <Tag key={idx}>{data}</Tag>;
                                })}
                            </Descriptions.Item>
                            <Descriptions.Item label="Profile Info" span={3}>
                                {update?.profile_info || "—"}
                            </Descriptions.Item>
                            <Descriptions.Item label="Remarks" span={2}>
                                {update?.remarks || "—"}
                            </Descriptions.Item>
                            <Descriptions.Item label="Last Connect Date" span={1}>
                                {formatDateToYYYYMMDD(update?.lastConnectDate)}
                            </Descriptions.Item>
                            {
                                update?.partners &&
                                <Descriptions.Item label="Partner List" span={4}>
                                    <Table celled  >
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell>Name</Table.HeaderCell>
                                                <Table.HeaderCell>Email</Table.HeaderCell>
                                                <Table.HeaderCell>Mobile</Table.HeaderCell>
                                                <Table.HeaderCell>Linkedin</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            {
                                                update?.partners?.map((obj, idx) => {
                                                    return (
                                                        <>
                                                            <Table.Row key={idx} >
                                                                <Table.Cell>
                                                                    {obj?.firstName + "  " + obj?.lastName || "-"}
                                                                </Table.Cell>
                                                                <Table.Cell>{obj?.email || "—"}</Table.Cell>
                                                                <Table.Cell>{obj?.mobileNum || "—"}</Table.Cell>
                                                                <Table.Cell>{obj?.linkedIn_URL || "—"}</Table.Cell>
                                                            </Table.Row>
                                                        </>
                                                    )
                                                }
                                                )
                                            }
                                        </Table.Body >
                                        <Table.Footer >
                                            <Table.Row>
                                                <Table.HeaderCell colSpan='5'>
                                                    <Menu floated='right' pagination>
                                                        <Menu.Item as='a' icon>
                                                            <Icon name='chevron left' />
                                                        </Menu.Item>
                                                        <Menu.Item as='a'>1</Menu.Item>
                                                        <Menu.Item as='a'>2</Menu.Item>
                                                        <Menu.Item as='a'>3</Menu.Item>
                                                        <Menu.Item as='a'>4</Menu.Item>
                                                        <Menu.Item as='a' icon>
                                                            <Icon name='chevron right' />
                                                        </Menu.Item>
                                                    </Menu>
                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Footer>
                                    </Table>
                                </Descriptions.Item>
                            }
                        </Descriptions>
                    </div>
                </Card >

                <Modal
                    dimmer="inverted"
                    size="large"
                    centered
                    zIndex={90}
                    onClose={() => {
                        setPartnerUpdateModalOpen(false);
                    }}
                    open={partnerUpdateModalOpen}
                    footer={null}

                >
                    <Modal.Header>Update {update?.firstName} {update?.lastName}'s Info</Modal.Header>
                    <Modal.Content scrolling>
                        <Form onSubmit={handleOkClientUpdate} loading={userState?.isPending} >
                            <Form.Group fluid width={16} >
                                <Form.Input
                                    value={partnerUpdate?.firstName}
                                    label="First Name"
                                    name="firstName"
                                    onChange={(_e, data) => {
                                        setPartnerUpdate({
                                            ...partnerUpdate,
                                            [data.name]: data.value,
                                        });
                                    }}
                                    width={8}
                                    required={true}
                                    error={
                                        partnerUpdate?.firstName === null ?
                                            true : false
                                    }
                                ></Form.Input>
                                <Form.Input
                                    value={partnerUpdate?.lastName}
                                    label="Last Name"
                                    name="lastName"
                                    onChange={(_e, data) => {
                                        setPartnerUpdate({
                                            ...partnerUpdate,
                                            [data.name]: data.value,
                                        });
                                    }}
                                    width={8}
                                    required={true}
                                    error={
                                        partnerUpdate?.lastName === null ?
                                            true : false
                                    }

                                ></Form.Input>
                            </Form.Group>
                            <Form.Group fluid width={16} >
                                <Form.Input
                                    value={partnerUpdate?.email}
                                    label="Email"
                                    name="email"
                                    onChange={(e) => {
                                        setPartnerUpdate({
                                            ...partnerUpdate,
                                            [e.target.name]: e.target.value,
                                        });
                                    }}
                                    width={8}
                                    required={true}
                                    error={
                                        partnerUpdate?.email === null ?
                                            true : false
                                    }
                                ></Form.Input>
                                <Form.Select
                                    fluid
                                    label="Country Code"
                                    name="country_code"
                                    value={partnerUpdate?.country_code}
                                    options={countryCodeSemantic}
                                    placeholder="C Code"
                                    onChange={(_e, data) => {
                                        setPartnerUpdate({
                                            ...partnerUpdate,
                                            [data.name]: data.value,
                                        });
                                    }}
                                    width={3}
                                    required={true}
                                    error={
                                        partnerUpdate?.country_code === null ?
                                            true : false
                                    }
                                />
                                <Form.Input
                                    type="number"
                                    name="mobileNum"
                                    label="Mobile Number"
                                    value={parseInt(partnerUpdate?.mobileNum)}
                                    fluid
                                    error={
                                        partnerUpdate?.mobileNum
                                            ? partnerUpdate?.mobileNum.length > 10 &&
                                            "Mobile Number should be 10 digits only"
                                            : false
                                    }
                                    onChange={(e) => {
                                        setPartnerUpdate({ ...partnerUpdate, [e.target.name]: e.target.value })
                                    }}
                                    placeholder="mobile number"
                                    width={5}
                                    required={true}
                                />
                            </Form.Group>
                            <Form.Group fluid width={18} >
                                <Form.Select
                                    fluid
                                    search
                                    label="Type of Hiring"
                                    name="typeOfHiring"
                                    width={4}
                                    value={partnerUpdate?.typeOfHiring}
                                    onChange={(e, data) => {
                                        setPartnerUpdate({ ...partnerUpdate, [data.name]: data?.value });
                                    }}
                                    options={hiringOptions}
                                    placeholder="Select type of hiring"
                                    required={true}
                                    error={
                                        partnerUpdate?.typeOfHiring === null ?
                                            true : false
                                    }
                                />
                                <Form.Checkbox
                                    style={{ marginTop: "30px" }}
                                    toggle
                                    name="isFreelancer"
                                    label="Freelancer ?"
                                    width={4}
                                    checked={partnerUpdate?.isFreelancer}
                                    onChange={(e, data) => {
                                        setPartnerUpdate({
                                            ...partnerUpdate, [data.name]: data.checked,
                                            company_name: data.checked ? `${partnerUpdate?.firstName} ${partnerUpdate?.lastName}` : ''
                                        });
                                    }}
                                    required={true}
                                    error={
                                        partnerUpdate?.isFreelancer === null ?
                                            true : false
                                    }
                                ></Form.Checkbox>
                                <Form.Input
                                    value={partnerUpdate?.company_name}
                                    label="Company Name"
                                    name="company_name"
                                    onChange={(_e, data) => {
                                        setPartnerUpdate({
                                            ...partnerUpdate,
                                            [data.name]: data.value,
                                        });
                                    }}
                                    width={4}
                                    placeholder="Company name"
                                    required={true}
                                    error={
                                        partnerUpdate?.company_name === null ?
                                            true : false
                                    }
                                ></Form.Input>
                                <Form.Checkbox
                                    style={{ marginTop: "30px" }}
                                    toggle
                                    name="isAssociatePartnerAdmin"
                                    label="ShowOnlyAssignedPosition ?"
                                    width={6}
                                    checked={partnerUpdate?.isAssociatePartnerAdmin}
                                    onChange={(e, data) => {
                                        setPartnerUpdate({
                                            ...partnerUpdate, [data.name]: data.checked});
                                    }}
                                    required={true}
                                    error={
                                        partnerUpdate?.isAssociatePartnerAdmin === null ?
                                            true : false
                                    }
                                ></Form.Checkbox>
                            </Form.Group>
                            <Form.Group widths={"equal"}>
                                <Form.Select
                                    fluid
                                    search
                                    label="Sourcing Verticals"
                                    multiple
                                    name="sourcingVertical"
                                    value={partnerUpdate?.sourcingVertical}
                                    onChange={(e, data) => {
                                        setPartnerUpdate({ ...partnerUpdate, [data.name]: data.value });
                                    }}
                                    options={verticlalOptions?.map((id, el) => {
                                        return {
                                            text: id?.sourcingVertical,
                                            value: id?.sourcingVertical,
                                            id: id?.id
                                        }
                                    })}
                                    placeholder="Select sourcing verticals"
                                    required={true}
                                    error={
                                        partnerUpdate?.sourcingVertical?.length <= 0 ?
                                            true : false
                                    }
                                />
                                <Form.Select
                                    fluid
                                    multiple
                                    search
                                    label="Roles Fulfilled"
                                    name="rolesFulfilled"
                                    value={partnerUpdate?.rolesFulfilled}
                                    // onChange={(e, data) => {
                                    //   console.log(data.value[0])
                                    //   setPartnerUpdate({ ...partnerUpdate, [data.name]: data.value[0] });
                                    // }}
                                    onChange={(e, data) => {
                                        setPartnerUpdate({
                                            ...partnerUpdate,
                                            [data.name]: data.value,
                                        });
                                    }}
                                    options={rolesOptions?.map((el) => {
                                        return {
                                            id: el?.id,
                                            text: el?.profile,
                                            value: el?.profile
                                        }
                                    })}
                                    placeholder="Select roles fulfilled"
                                    required={true}
                                    error={
                                        partnerUpdate?.rolesFulfilled?.length <= 0 ?
                                            true : false
                                    }
                                />

                                {/* <Form.Select
                                    fluid
                                    search
                                    multiple
                                    label="Geography"
                                    name="geography"
                                    value={partnerUpdate?.geography}
                                    onChange={(e, data) => {
                                        setPartnerUpdate({ ...partnerUpdate, [data.name]: data.value });
                                    }}
                                    options={rolesOptions?.map((el) => {
                                        return {
                                            id: el?.id,
                                            text: el?.profile,
                                            value: el?.profile
                                        }
                                    })}
                                    required={true}
                                    width={6}
                                /> */}
                            </Form.Group>
                            <Form.Group fluid width={16}>
                                <Form.Select
                                    fluid
                                    search
                                    multiple
                                    value={partnerUpdate?.geography}
                                    label="Geography"
                                    name="geography"
                                    placeholder="Select geography"
                                    onChange={(_e, data) => {
                                        setPartnerUpdate({
                                            ...partnerUpdate,
                                            [data.name]: data.value,
                                        });
                                    }}
                                    options={geoOptions?.map((el) => {
                                        return {
                                            id: el?.id,
                                            text: el?.location,
                                            value: el?.location
                                        }
                                    })}
                                    width={8}
                                    required={true}
                                    error={
                                        partnerUpdate?.geography?.length <= 0 ?
                                            true : false
                                    }
                                ></Form.Select>
                                <Form.Input
                                    type="file"
                                    label="Update Contract"
                                    accept="application/pdf,application/msword,
  application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                    name="file"
                                    onChange={(e) => {
                                        setFileObj(
                                            e.target.files[0],
                                        )
                                    }}
                                    width={8}
                                ></Form.Input>

                            </Form.Group >
                            <Form.Group widths={"18"}>
                                <Form.Select
                                    width={6}
                                    search
                                    multiple
                                    options={primarySkillsOptions?.map((data, idx) => {
                                        return { key: idx, value: data.name, text: data.name };
                                    })}
                                    placeholder="Select Primary Roles"
                                    name="primary_roles"
                                    value={partnerUpdate?.primaryRoles}
                                    onChange={(e, data) => {
                                        setPartnerUpdate({ ...partnerUpdate, [data.name]: data.value });
                                    }}
                                    label="Primary Roles"
                                ></Form.Select>
                                <Form.Select
                                    width={6}
                                    search
                                    multiple
                                    options={industryOptions?.map((data, idx) => {
                                        return { key: idx, value: data.name, text: data.name };
                                    })}
                                    placeholder="Select Client Industry"
                                    name="industry"
                                    value={partnerUpdate?.industry}
                                    onChange={(e, data) => {
                                        setPartnerUpdate({ ...partnerUpdate, [data.name]: data.value });
                                    }}
                                    label="Client Industry"
                                ></Form.Select>
                                <Form.Select
                                    width={6}
                                    search
                                    multiple
                                    //options={salaryOptions}
                                    options={salaryOptions?.map((data) => {
                                        return { key: data.id, value: data.value, text: data.text };
                                    })}
                                    placeholder="Select Salary Bracket(2 Options)"
                                    name="salary"
                                    value={partnerUpdate?.salaryBrackets}
                                    onChange={(e, data) => {
                                        const selectedValues = data.value;
                                        if (selectedValues.length > 2) {
                                            message.info("Only 2 salary brackets can be selected");
                                            return;}
                                            setPartnerUpdate({ ...partnerUpdate, [data.name]: selectedValues });
                                            }}
                                    label="Salary Bracket"
                                ></Form.Select>
                            </Form.Group>
                            <Form.Group fluid width={16}>
                                <Form.TextArea
                                    name="profile_info"
                                    label="Profile Info"
                                    value={partnerUpdate?.profile_info}
                                    onChange={(e) =>
                                        setPartnerUpdate({ ...partnerUpdate, [e.target.name]: e.target.value })
                                    }
                                    placeholder="Enter any remarks,if required (100 words max)"
                                    width={6}
                                ></Form.TextArea>
                                <Form.TextArea
                                    name="remarks"
                                    label="Remarks"
                                    value={partnerUpdate?.remakrs}
                                    onChange={
                                        (e) =>
                                            setPartnerUpdate({ ...partnerUpdate, [e.target.name]: e.target.value })
                                    }
                                    placeholder="Enter any remarks,if required (100 words max)"
                                    width={6}
                                ></Form.TextArea>
                                <Form.Input
                                    type="date"
                                    name="lastConnectDate"
                                    label="last Connected Date"
                                    value={formatDateToYYYYMMDD(partnerUpdate?.lastConnectDate)}
                                    onChange={(e) => {
                                        setPartnerUpdate({
                                            ...partnerUpdate,
                                            [e.target.name]: e.target.value,
                                        });
                                    }}
                                    max={new Date().toISOString().split('T')[0]}
                                    width={4}
                                ></Form.Input>
                            </Form.Group>
                            {/* <Form.Group fluid width={16} >
                                <Form.Input
                                    label="Linkedin Url"
                                    name="linkedIn_URL"
                                    value={partnerUpdate?.linkedIn_URL}
                                    onChange={(e) => {
                                        setPartnerUpdate({
                                            ...partnerUpdate,
                                            [e.target.name]: e.target.value,
                                        });
                                    }}
                                    width={8}
                                    type="text"
                                ></Form.Input>
                                 <Form.Select
                                    fluid
                                    search
                                    label="Freelancer ? "
                                    name="isFreelancer"
                                    width={8}
                                    value={partnerUpdate?.isFreelancer}
                                    onChange={(e, data) => {
                                        setPartnerUpdate({ ...partnerUpdate, [data.name]: data?.value });
                                    }}
                                    options={Positions_available}
                                /> 
                            </Form.Group>*/}
                            {/* <Form.Group fluid width={16} >
                                <Form.Select
                                    search
                                    fluid
                                    label="Location"
                                    name="country"
                                    width={5}
                                    placeholder="Select country"
                                    value={country}
                                    onChange={(e, data) => {
                                        setCountry(data?.value);
                                        setState();
                                        setCity();
                                        setPartnerUpdate({
                                            ...partnerUpdate,
                                            [data.name]: data?.value,
                                        });
                                    }}
                                    options={countryOption?.map((data, idx) => {
                                        return {
                                            text: `${data.name + "  (" + data.iso2 + ")"}`,
                                            value: `${data.iso2}`,
                                            key: `${idx}`,
                                        };
                                    })}

                                />
                                <Form.Select
                                    search
                                    fluid
                                    label="‎ "
                                    width={5}
                                    name="state"
                                    placeholder="Select State"
                                    value={state || null}
                                    onChange={(e, data) => {
                                        console.log(data);
                                        setState(data.value);
                                        setCity();
                                        setPartnerUpdate({
                                            ...partnerUpdate,
                                            [data.name]: data?.value,
                                        });
                                    }}
                                    options={stateOption?.map((data, idx) => {
                                        return {
                                            text: `${data.name + "  (" + data.iso2 + ")"}`,
                                            value: `${data.iso2}`,
                                            key: `${idx}`,
                                        };
                                    })}
                                />
                                <Form.Select
                                    fluid
                                    search
                                    label="‎" name="city"
                                    width={5}
                                    value={city || null}
                                    onChange={(e, data) => {
                                        setPartnerUpdate({ ...partnerUpdate, [data.name]: data?.value });
                                        setCity(data?.value);
                                    }}
                                    placeholder="Select City"
                                    options={cityOption?.map((data, idx) => {
                                        return {
                                            text: `${data.name}`,
                                            value: `${data.name}`,
                                            key: `${idx}`,
                                        };
                                    })}
                                />
                                <Form.Input
                                    search
                                    fluid
                                    width={5}
                                    label="Previous Location"
                                    name="country"
                                    value={prevcountry}

                                />
                                <Form.Input
                                    search
                                    fluid
                                    width={5}
                                    label="‎ "
                                    name="state"
                                    value={prevstate}
                                />
                                <Form.Input
                                    fluid
                                    search
                                    width={5}
                                    label="‎" name="city"
                                    value={prevcity}
                                />

                            </Form.Group> 
                            <Form.Group fluid width={16} >
                                <Form.Input
                                    fluid
                                    type="text"
                                    name="address"
                                    label="Address"
                                    value={partnerUpdate?.address}
                                    onChange={(e) => {
                                        setPartnerUpdate({
                                            ...partnerUpdate,
                                            [e.target.name]: e.target.value,
                                        })
                                    }}
                                    placeholder="Enter Street Address (max limit : 120 words)"
                                    required={false}
                                    width={16}
                                />
                            </Form.Group> */}
                            <Button
                                style={{
                                    background: "#6855f6",
                                    color: "#ffffff",
                                }}
                                htmlType="submit"
                                disabled={zyvkaState.isPending === true}
                            >
                                Save
                            </Button>
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button negative onClick={() => setPartnerUpdateModalOpen(false)}>
                            Cancel
                        </Button>
                    </Modal.Actions>
                </Modal >

                {/* contract modal */}
                <Modal
                    dimmer="inverted"
                    size="mini"
                    centered
                    zIndex={80}
                    onClose={() => {
                        setOpenContractModal(false);
                    }}
                    open={openContractModal}
                    footer={null}
                    closeOnDimmerClick={true}
                    closeIcon
                >
                    <Modal.Header>
                        Update/Replace your Client Contract {update?.name}
                    </Modal.Header>
                    <Modal.Content>
                        <Form loading={userState?.isPending} onSubmit={contractUpdateHandler} >
                            <Form.Input
                                type="file"
                                accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                name="file"
                                onChange={(e) => {
                                    setFileObj(
                                        e.target.files[0],
                                    )
                                }}
                            >
                            </Form.Input>
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button negative onClick={() => setOpenContractModal(false)}>
                            Cancel
                        </Button>
                        <Popconfirm
                            primary
                            title="Are you sure you want to update the client contract?"
                            onConfirm={contractUpdateHandler}
                            onCancel={() => setOpenContractModal(false)}
                            okText="Yes"
                            cancelText="No"

                        >
                            <Button type="primary" style={{ marginLeft: "30px" }}
                                icon={<EditOutlined />}
                            >
                                update
                            </Button>
                        </Popconfirm>
                    </Modal.Actions>
                </Modal>


            </div >
        </>
    );
};

export default PartnerDetails;