import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, ButtonBlock, CloseIcon, colors, CustomInput, Label, Popup, PopupBody, PopupName, Row } from '../components';
import { Avatar } from 'sceyt-chat-react-uikit';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

const ErrorText = styled.div`
  color: ${colors.errorColor};
  font-size: 12px;
  margin-top: 4px;
`;

const MessageInput = styled(CustomInput)`
  margin-bottom: 16px;
`;

const SearchIcon = styled.div`
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: ${colors.textFootnote};
`;


const ResponsiveModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 150;
  
  @media (max-width: 768px) {
    padding: 16px;
  }
`;

const ResponsivePopup = styled(Popup)`
  width: 100%;
  max-width: 520px;
  max-height: 90vh;
  
  @media (max-width: 768px) {
    width: 95%;
    max-height: 95vh;
    margin: 0 auto;
  }
`;

const ResponsiveSearchContainer = styled.div`
  position: relative;
  margin: 16px 0 24px 0;
  width: 100%;
`;

const ResponsiveUserListContainer = styled.div`
  max-height: 35vh;
  overflow-y: auto;
  width: 100%;
  
  @media (max-width: 768px) {
    max-height: 50vh;
  }
`;

const ResponsiveUserListItem = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  margin-bottom: 8px;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;

  &:hover {
    background-color: ${colors.gray1};
  }

  @media (max-width: 768px) {
    gap: 8px;
  }
`;

const UserInfo = styled.div`
  margin-left: 12px;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const UserName = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${colors.textColor1};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const UserRole = styled.div`
  font-size: 12px;
  color: ${colors.textFootnote};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;


const CustomCreateChannel = ({ type, onClose, role, userId, partnerAdminId }) => {
  const [nextToken, setNextToken] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(type === 'group' ? 'details' : 'members');
  const [groupDetails, setGroupDetails] = useState({
    name: '',
    description: ''
  });
  const [selectedMembers, setSelectedMembers] = useState([]);  
  const [searchQuery, setSearchQuery] = useState('');
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState({
    name: '',
    description: '',
    message: ''
  });

  useEffect(() => {
    const temp = users.filter(user => {
      const fullName = `${user.first_name} ${user.last_name}`?.toLowerCase() || '';
      return fullName.includes(searchQuery.toLowerCase()) ||
        user?.metadata?.role?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user?.id?.toLowerCase().includes(searchQuery.toLowerCase());
    });
    setFilteredUsers(temp);
  }, [searchQuery, users]);

  useEffect(() => {
    fetchUsers();
  }, []);

  // const constructSearchFilters = () => {
  //   const nameParts = searchQuery.trim().split(' ');
  //   const filters = [];
    
  //   // Handle different search scenarios
  //   if (nameParts.length === 2) {
  //     // Two-word search: assume first word is first name, second is last name
  //     filters.push(
  //       `first_name:contains:${nameParts[0]}`, 
  //       `last_name:contains:${nameParts[1]}`
  //     );
  //   } else if (nameParts.length === 1) {
  //     // Single word search across multiple fields
  //     filters.push(
  //       `first_name:contains:${nameParts[0]}`,
  //     );
  //   }
    
  //   return filters.length > 0 ? filters : null;
  // };
  
  const fetchUsers = useCallback(async (loadMore = false) => {
    if (isLoading) return;

    setIsLoading(true);
    try {
     
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/scyetChat/getAlluser?next=${nextToken}`, {
       
        withCredentials: true
      });

      if (response.statusText === "OK") {
        let usersToSet = response.data.user.users;
        usersToSet = usersToSet.filter(user => user.id !== userId);

        if (role === "partner") {
          usersToSet = usersToSet.filter(user => 
            user.metadata?.partnerAdminId === partnerAdminId || 
            user.id === partnerAdminId
          );
        } else if (role === "partnerAdmin") {
          usersToSet = usersToSet.filter(user =>
            user.metadata?.partnerAdminId === userId
          );
        }

        setUsers(prev => loadMore ? [...prev, ...usersToSet] : usersToSet);
        setNextToken(response.data.user.next || null);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }, [isLoading, nextToken, userId, role, partnerAdminId]);



  // useEffect(() => {
  //   fetchUsers();
  // }, []);

  const handleScroll = useCallback((e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    const isNearBottom = scrollHeight - (scrollTop + clientHeight) <= 100;
    
    if (isNearBottom && nextToken && !isLoading) {
      fetchUsers(true);
    }
  }, [nextToken, isLoading, fetchUsers]);

  // const handleSearch = () => {
  //   if (searchQuery.trim()) {
  //     fetchUsers(false, true);
  //   }
  // };

  // const handleKeyPress = (e) => {
  //   if (e.key === 'Enter') {
  //     handleSearch();
  //   }
  // };

  const validateForm = () => {
    const newErrors = {
      name: '',
      description: '',
      message: ''
    };

    if (type === 'group') {
      if (!groupDetails.name.trim()) {
        newErrors.name = 'Group name is required';
      }
      if (!groupDetails.description.trim()) {
        newErrors.description = 'Description is required';
      }
    }

    if (type === 'bulkMessage' && !message.trim()) {
      newErrors.message = 'Message is required';
    }

    setErrors(newErrors);
    return !Object.values(newErrors).some(error => error);
  };

  const handleNext = () => {
    if (validateForm()) {
      setStep('members');
    }
  };

  const handleSubmit = async () => {
    try {
      if (!validateForm()) {
        return;
      }

      if (type === 'bulkMessage') {
        const payload = {
          members: selectedMembers,
          message: message,
          userId: userId
        };

        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/api/scyetChat/sendBulkMessage`,
          payload,
          {
            headers: {
              'Content-Type': 'application/json'
            },
            withCredentials: true
          }
        );

        if (response.data) {
          onClose();
        }
        return;
      }

      let formattedMembers = [];
      if (type === 'group') {
        formattedMembers = selectedMembers.map(memberId => ({
          id: memberId,
          role: "participant"
        }));
        formattedMembers.push({ id: userId, role: "owner" });
      } else {
        formattedMembers = selectedMembers.map(memberId => ({
          id: memberId,
          role: "owner"
        }));
        formattedMembers.push({ id: userId, role: "owner" });
      }

      const payload = {
        type,
        members: formattedMembers,
        uri: type === 'group' ? `group-${uuidv4()}` : `direct-${uuidv4()}`,
        ...(type === 'group' && {
          data: {
            subject: groupDetails.name,
            metadata: groupDetails.description
          }
        })
      };

      const url = type === 'direct'
        ? `${process.env.REACT_APP_SERVER_URL}/api/scyetChat/createChat/${userId}`
        : `${process.env.REACT_APP_SERVER_URL}/api/scyetChat/createChat`;

      const response = await axios.post(url, payload, {
        headers: {
          'Content-Type': 'application/json'
        },
        withCredentials: true
      });

      if (response.data.channel) {
        onClose();
      }
    } catch (error) {
      console.error('Error:', error);
      if (error.response) {
        console.error('Server error:', error.response.data);
      }
    }
  };

  return (
    <ResponsiveModal>
    <div style={{ position: 'absolute', width: '100%', maxWidth: '520px' }}>
      <ResponsivePopup
        backgroundColor={colors.white}
        padding="0"
        style={{ position: 'relative', zIndex: 200 }}
      >
        <PopupBody paddingH="24px" paddingV="24px">
          <CloseIcon color={colors.textColor1} onClick={onClose} />
          <PopupName color={colors.textColor1} marginBottom="20px">
            {type === 'group' ? 'Create Group' : type === 'bulkMessage' ? 'Send Bulk Message' : 'New Chat'}
          </PopupName>

            {type === 'group' && step === 'details' && (
              <>
                <Label color={colors.textColor1}>Group name</Label>
                <CustomInput
                  type="text"
                  value={groupDetails.name}
                  onChange={(e) => {
                    setGroupDetails({ ...groupDetails, name: e.target.value });
                    setErrors({ ...errors, name: '' });
                  }}
                  placeholder="Enter group name"
                  color={colors.textColor1}
                  errorColor={colors.errorColor}
                  placeholderColor={colors.textFootnote}
                  backgroundColor={colors.white}
                  borderColor={errors.name ? colors.errorColor : colors.gray1}
                />
                {errors.name && <ErrorText>{errors.name}</ErrorText>}

                <Label color={colors.textColor1} style={{ marginTop: '16px' }}>Description</Label>
                <CustomInput
                  type="text"
                  value={groupDetails.description}
                  onChange={(e) => {
                    setGroupDetails({ ...groupDetails, description: e.target.value });
                    setErrors({ ...errors, description: '' });
                  }}
                  placeholder="Enter group description"
                  color={colors.textColor1}
                  errorColor={colors.errorColor}
                  placeholderColor={colors.textFootnote}
                  backgroundColor={colors.white}
                  borderColor={errors.description ? colors.errorColor : colors.gray1}
                />
                {errors.description && <ErrorText>{errors.description}</ErrorText>}

                <ButtonBlock>
                  <Button
                    onClick={handleNext}
                    backgroundColor={colors.primary}
                    color={colors.white}
                  >
                    Next
                  </Button>
                </ButtonBlock>
              </>
            )}

            {((type === 'direct' || type === 'bulkMessage') || (type === 'group' && step === 'members')) && (
              <>
                {type === 'bulkMessage' && (
                  <>
                    <Label color={colors.textColor1}>Message</Label>
                    <MessageInput
                      type="text"
                      value={message}
                      onChange={(e) => {
                        setMessage(e.target.value);
                        setErrors({ ...errors, message: '' });
                      }}
                      placeholder="Enter your message"
                      color={colors.textColor1}
                      errorColor={colors.errorColor}
                      placeholderColor={colors.textFootnote}
                      backgroundColor={colors.white}
                      borderColor={errors.message ? colors.errorColor : colors.gray1}
                    />
                    {errors.message && <ErrorText>{errors.message}</ErrorText>}
                  </>
                )}

             
                   <ResponsiveSearchContainer>
                   <SearchIcon>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <circle cx="11" cy="11" r="8" />
                    <line x1="21" y1="21" x2="16.65" y2="16.65" />
                  </svg>
                </SearchIcon>
                <CustomInput
                  type="text"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder="Search users..."
                  color={colors.textColor1}
                  placeholderColor={colors.textFootnote}
                  backgroundColor={colors.gray1}
                  style={{ paddingLeft: '36px' }}
                />
            

                </ResponsiveSearchContainer>

               <ResponsiveUserListContainer onScroll={handleScroll}>
                  {filteredUsers.map(user => (
                    <ResponsiveUserListItem
                      key={user.id}
                      onClick={() => {
                        if (type === 'direct') {
                          setSelectedMembers(prev =>
                            prev.includes(user.id) ? [] : [user.id]
                          );
                        } else {
                          setSelectedMembers(prev =>
                            prev.includes(user.id)
                              ? prev.filter(id => id !== user.id)
                              : [...prev, user.id]
                          );
                        }
                      }}
                    >
                       <Avatar
                        image={user.avatar_url}
                        name={`${user.first_name} ${user.last_name}`}
                        size={35}
                        textSize={16}
                        setDefaultAvatar
                      />
                      <UserInfo>
                        <UserName>{`${user.first_name} ${user.last_name} (${user?.metadata?.role})`}</UserName>
                        <UserRole>{user?.metadata?.email}</UserRole>
                      </UserInfo>
                      <CustomInput
                        type="checkbox"
                        width="18px"
                        height="18px"
                        checked={selectedMembers.includes(user.id)}
                        readOnly
                      />
                    </ResponsiveUserListItem>
                  ))}
                  {isLoading && <div>Loading users...</div>}
                </ResponsiveUserListContainer>

                <ButtonBlock style={{ marginTop: '24px' }}>
                  {type === 'group' && step === 'members' && (
                    <Button
                      onClick={() => setStep('details')}
                      backgroundColor={colors.white}
                      color={colors.textColor1}
                      style={{ marginRight: '8px' }}
                    >
                      Back
                    </Button>
                  )}
                  <Button
                    onClick={handleSubmit}
                    backgroundColor={colors.primary}
                    color={colors.white}
                    disabled={type === 'bulkMessage' ? !message.trim() || selectedMembers.length === 0 : selectedMembers.length === 0}
                  >
                    {type === 'bulkMessage' ? 'Send Message' : 'Create'}
                  </Button>
                </ButtonBlock>
              </>
            )}
          </PopupBody>
        </ResponsivePopup>
      </div>
    </ResponsiveModal>
  );
};

export default CustomCreateChannel;