import { useEffect, useState } from "react";
import { message, Switch, Table, Input } from "antd";
import axios from "axios";
import { Link } from "react-router-dom";
import { ClipLoader } from "react-spinners";

const GetAlluserChats = () => {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoader] = useState(false);
    const [isLoading, setisLoading] = useState(false);
    const [id, setId] = useState(null);

    const handleChange = async (checked, userData) => {
        setisLoading(true);
        setId(userData.userID);
        try {
            let response;
            
            if (checked) {
                // Activate user
                const payload = {
                    first_name: userData?.first_name || userData?.name?.split(" ")[0] || "",
                    last_name: userData?.last_name || userData?.name?.split(" ")[1] || "",
                    role: userData?.type,
                    pfp_urn: userData?.avatar_url || "",
                    email: userData?.email,
                    mobileNum: userData?.mobileNum || "",
                    ...(userData?.type === "partner" && { partnerAdminId: userData?.partnerAdminId })
                };

                response = await axios.put(
                    `${process.env.REACT_APP_SERVER_URL}/api/scyetChat/users/${userData.userID}/activate`,
                    payload,
                    { withCredentials: true }
                );
            } else {
                // Deactivate user
                response = await axios.put(
                    `${process.env.REACT_APP_SERVER_URL}/api/scyetChat/users/${userData.userID}/deactivate`,
                    {},
                    { withCredentials: true }
                );
            }
            
            if (response.status === 200 || response.status === 201) {
                if(checked){
                    message.success(`${userData?.name} is added to Chat`);
                } else {
                    message.info(`${userData?.name} is now removed from Chat`);
                }
                
                const updateUserData = (prevData) => 
                    prevData.map(user => 
                        user.userID === userData.userID 
                            ? {...user, isAddedToChat: checked} 
                            : user
                    );
                
                setData(updateUserData);
                setFilteredData(updateUserData);
            } else {
                throw new Error("Response status is not 200 or 201");
            }
        } catch (error) {
            message.error("Some error occurred");
            console.log(error);
            
            const revertUserData = (prevData) => 
                prevData.map(user => 
                    user.userID === userData.userID 
                        ? {...user, isAddedToChat: !checked} 
                        : user
                );
            
            setData(revertUserData);
            setFilteredData(revertUserData);
        }
        setisLoading(false);
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Role',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Company',
            dataIndex: 'companyName',
            key: 'companyName',
            render: (_, record) => (
                <p>{record?.companyName || "N/A"}</p>
            ),
        },
        {
            title: 'EnableChat',
            dataIndex: 'EnableChat',
            render: (_, record) => (
                <Switch 
                    checked={record.isAddedToChat} 
                    loading={id === record?.userID && isLoading} 
                    onChange={(checked) => handleChange(checked, record)} 
                />
            ),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Link 
                to={`/seeChat/${record.userID}?user=${encodeURIComponent(JSON.stringify(record))}`}
                style={{
                    padding: "10px 10px",
                    backgroundColor: "#6855f6",
                    color: "white",
                    borderRadius: "10px"
                }}
              >
                View Chat
              </Link>
              
            ),
        },
    ];

    const fetchUsers = async () => {
        try {
            setLoader(true);
            const res = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/api/scyetChat/getAllUserAdmin`, 
                { withCredentials: true }
            );

            if(res.statusText === "OK") {
                const sortedData = res.data.sort((a, b) => {
                    const order = ["superAdmin", "zyvkaAdmin", "zyvkaUser", "partnerAdmin", "partner"];
                    return order.indexOf(a.type) - order.indexOf(b.type);
                });

                setData(sortedData);
                setFilteredData(sortedData);
            }
            setLoader(false);
        } catch (error) {
            console.log(error);
            setLoader(false);
        }
    };

    const onSearch = (event) => {
        const value = event.target.value.toLowerCase();
        const filtered = data.filter(user => 
            (user.name ? user.name.toLowerCase().includes(value) : user.email.toLowerCase().includes(value))
        );
        setFilteredData(filtered);
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    if (loading) {
        return (
            <div
                style={{
                    display: "flex",
                    height: "80vh",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <ClipLoader color="#6855f6" />
            </div>
        );
    }

    return (
        <div style={{ marginTop: "40px" }}>
            <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "20px" }}>
                <Input
                    placeholder="Search User"
                    onChange={onSearch}
                    style={{ width: 300 }}
                />
            </div>
            <Table 
                scroll={{ x: true, y: true }} 
                columns={columns} 
                dataSource={filteredData} 
                bordered 
            />
        </div>
    );
};

export default GetAlluserChats;